<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'infoModel'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"> </h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close"
                    *ngIf="infoData.msgOkBtn">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body pb-4" [innerHTML]="infoData.msgBody"> </div>
            <div class="modal-footer">
                <button *ngIf="infoData.msgOkBtn" class="btn btn-secondary btn-md" (click)="modalClose(true)"
                    [innerHTML]="infoData.msgOkBtn"></button>
                <button *ngIf="infoData.msgCancelBtn" class="btn btn-secondary btn-md" (click)="modalClose()"
                    [innerHTML]="infoData.msgCancelBtn"></button>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'confrimEmailModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"> </h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close"
                    *ngIf="infoData.msgOkBtn">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body pb-4" [innerHTML]="infoData.msgBody">
            </div>
            <div class="modal-footer" *ngIf="infoData.msgOkBtn">
                <!--<button *ngIf="clientEnv == 'staging'" class="btn btn-secondary btn-md" (click)="goToDeposit()" [innerHTML]="infoData.msgOkBtn"></button>
                <button *ngIf="clientEnv != 'staging'" class="btn btn-secondary btn-md" (click)="goToPreRegLandingPage()" [innerHTML]="infoData.msgOkBtn"></button>-->
                <button class="btn btn-secondary btn-md" (click)="goToDeposit()"
                    [innerHTML]="infoData.msgOkBtn"></button>
            </div>
        </div>
    </div>
</div>


<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'checkConfirmKycLevel'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel"
                    [innerHTML]="headerPageAssets.text.homePage_confirm_kyclavel_title"></h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body pb-4"
                [innerHTML]="headerPageAssets.errormsg.headerPage_error_common_kyclevel_confirmed_msg">
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary btn-md" *ngIf="!infoData.msgOkBtn" (click)="goToHome()"
                    [innerHTML]="headerPageAssets.errormsg.headerPage_common_ok_btn"></button>
                <button *ngIf="infoData.msgOkBtn" class="btn btn-secondary btn-md" (click)="modalClose(true)"
                    [innerHTML]="headerPageAssets.errormsg.headerPage_common_ok_btn"></button>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'checkPlayerConfirmEmail'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel"
                    [innerHTML]="homePageAssets.errormsg.homePage_confirm_email_title"></h2>
            </div>
            <div class="modal-body pb-4"
                [innerHTML]="headerPageAssets.errormsg.headerPage_error_common_email_not_verified">
            </div>
            <div class="modal-footer">
                <button class="btn btn-theme-outline mb-0 mt-0" [disabled]="isCalledApiResendEmail"
                    (click)="resendEmail()"
                    [innerHTML]="headerPageAssets.text.headerPage_common_resend_verification_btn"></button>
                <button class="btn btn-secondary btn-md" (click)="goToHome()"
                    [innerHTML]="headerPageAssets.errormsg.headerPage_common_ok_btn"></button>
            </div>
        </div>
    </div>
</div>


<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'bannedDocumentUpload'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"> </h2>
            </div>
            <div class="modal-body pb-4" [innerHTML]="infoData.msgBody">
            </div>
            <div class="modal-footer" *ngIf="infoData.msgOkBtn">
                <button class="btn btn-secondary btn-md" (click)="modalClose(true)"
                    [innerHTML]="infoData.msgOkBtn"></button>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == '24hrsLogoutInfoModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel"
                    [innerHTML]="headerPageAssets.text.headerPage_24_force_logout_title">
                </h2>
            </div>
            <div class="modal-body pb-4" [innerHTML]="headerPageAssets.text.headerPage_24_force_logout_message">
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary btn-md" (click)="modalClose(true)"
                    [innerHTML]="headerPageAssets.text.headerPage_24_force_logout_btn"></button>
            </div>
        </div>
    </div>
</div>




<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'bannedLimit'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header pb-0">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"> </h2>
            </div>
            <div class="modal-body pb-2" [innerHTML]="infoData.msgBody">
            </div>
            <div class="modal-footer flex-wrap-inherit">
                <button *ngIf="infoData.msgCancelBtn" class="btn btn-theme-outline mb-0 mt-0" (click)="modalClose()"
                    [innerHTML]="infoData.msgCancelBtn"></button>
                <button class="btn btn-secondary btn-md" (click)="modalClose(true)"
                    [innerHTML]="infoData.msgOkBtn"></button>
            </div>
        </div>
    </div>
</div>


<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'confrimSelfModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"> </h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body pb-4" [innerHTML]="infoData.msgBody">
            </div>
            <div class="modal-footer flex-wrap-inherit">
                <button class="btn btn-main btn-main-md btn-block mb-0 mt-0" (click)="modalClose()"
                    [innerHTML]="infoData.msgCancelBtn"></button>
                <button class="btn btn-secondary btn-md btn-block mb-0 mt-0" (click)="modalClose(true)"
                    [innerHTML]="infoData.msgOkBtn"></button>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'playerBannedModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel" [innerHTML]="infoData.msgTitle"></h4>
                <button type="button" class="close" aria-label="Close" (click)="dialogRef.close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="col-sm-12 p-0"
                    [innerHTML]="headerPageAssets.errormsg.headerPage_error_account_banned_modal_msg">
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-main btn-main-md m-auto" (click)="goToHelp()"
                    [innerHTML]="headerPageAssets.text.headerPage_help_btn_label"></button>
            </div>
        </div>
    </div>
</div>


<div class="modal info-modal modal-lg-custom" tabindex="-1" role="dialog"
    *ngIf="infoData.modelName == 'supportMorecontentmodal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content box-bg">
            <div class="modal-header border-bottom-none">
                <h2 class="modal-title w-100 headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"></h2>
                <button type="button" class="close" aria-label="Close" (click)="dialogRef.close()">
                    <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                        [removeSVGAttributes]="['xmlns']"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="paragraph">
                    <p class="" [innerHTML]="infoData.msgBody"></p>
                </div>
            </div>
        </div>
    </div>
</div>


<!-- START Reality check modal -->

<div class="modal info-modal tran-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'realityCheckModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel"
                    [innerHTML]="headerPageAssets.text.headerPage_reality_check_title"></h2>
                <button *ngIf="!formData.isRealityRemaining" type="button" class="close" aria-label="Close"
                    (click)="dialogRef.close()">
                    <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                        [removeSVGAttributes]="['xmlns']"></span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row" *ngIf="formData.isRealityRemaining">
                    <div class="col-sm-12 mb-3">
                        <div class="text-center mb-3 mt-3"
                            [innerHTML]="headerPageAssets.text.headerPage_isReality_label_1"></div>

                        <div class="text-center mb-3" [innerHTML]="headerPageAssets.text.headerPage_isReality_label_2">
                        </div>
                        <div class="mb-3 text-center">
                            <div class="font-bold timeCount"
                                [ngClass]="{'remainingTime' : (isStartRealityTimeInterval && realityRemainingTime.progressBarPr > 0)}">
                                {{realityRemainingTime.minutes}} : {{realityRemainingTime.seconds}}</div>
                        </div>
                    </div>
                    <div class="col-sm-12 text-center mb-3">
                        <button *ngIf="!isStartRealityTimeInterval" class="btn btn-secondary m  -auto"
                            (click)="onTapRealityCounter()"
                            [innerHTML]="headerPageAssets.text.headerPage_isReality_start_btn_label"></button>
                        <button *ngIf="isStartRealityTimeInterval" class="btn btn-secondary m-auto"
                            (click)="onTapRealityCounter(true)" [disabled]="realityRemainingTime.progressBarPr > 0"
                            [innerHTML]="headerPageAssets.text.headerPage_isReality_return_btn_label"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- END Reality check modal -->

<div class="modal info-modal" tabindex="-1" role="dialog"
    *ngIf="infoData.modelName == 'withdrawalThresholdReachedModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel"
                    [innerHTML]="userProfileWithdrawPageAssets.text.userProfileWithdrawPage_automatic_threshold_reached_title">
                </h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body pb-4">
                <div
                    [innerHTML]="userProfileWithdrawPageAssets.text.userProfileWithdrawPage_automatic_threshold_reached_text1">
                </div>

                <div class="d-flex align-items-center mt-4 justify-content-between">
                    <div
                        [innerHTML]="userProfileWithdrawPageAssets.text.userProfileWithdrawPage_automatic_threshold_reached_text2">
                    </div>
                    <div class="switch-body d-flex">
                        <label class="switch">
                            <input type="checkbox" id="thresholdStatus" (change)="onChangeThreshold($event)">
                            <div class="slider">
                                <span class="before"
                                    [innerHTML]="userProfileNotificationsPageAssets.text.userProfileNotificationsPage_off_label"></span>
                                <span class="after"
                                    [innerHTML]="userProfileNotificationsPageAssets.text.userProfileNotificationsPage_on_label"></span>
                            </div>
                        </label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" class="btn btn-theme-outline mb-0 mt-0" (click)="modalClose(true)"
                    [innerHTML]="headerPageAssets.text.headerPage_continue_btn_label"></button>
                <button type="button" class="btn btn-secondary btn-md" (click)="modalClose()"
                    [innerHTML]="userProfileWithdrawPageAssets.text.userProfileWithdrawPage_cancel_btn"></button>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'emailCompleteModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel"
                    [innerHTML]="homePageAssets.text.homePage_complete_reg_modal_title"></h2>
                <button type="button" class="close" (click)="goToHome()" aria-label="Close">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body pb-4">
                <p [innerHTML]="homePageAssets.text.homePage_complete_reg_modal_msg_text1"></p>
                <p><span [innerHTML]="homePageAssets.text.homePage_complete_reg_modal_msg_text2"></span>&nbsp;<a
                        (click)="resendEmail()"
                        [innerHTML]="homePageAssets.text.homePage_complete_reg_modal_msg_text3"></a></p>
            </div>
            <div class="modal-footer email-complete text-left">
                <div class="chat-help d-flex align-items-center" (click)="goToFaq()">
                    <div>
                        <span class="icon chat-icon" [inlineSVG]="'assets/images/icons/chat-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </div>
                    <div class="chat-text" [innerHTML]="homePageAssets.text.homePage_complete_reg_modal_chat_btn_text">
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'verifyLaterModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"> </h2>
            </div>
            <div class="modal-body pb-4" [innerHTML]="infoData.msgBody"> </div>
            <div class="modal-footer">
                <button *ngIf="infoData.msgOkBtn" class="btn btn-secondary btn-md" (click)="modalClose(true)"
                    [innerHTML]="infoData.msgOkBtn"></button>
                <button *ngIf="infoData.msgCancelBtn" class="btn btn-secondary btn-md" (click)="modalClose()"
                    [innerHTML]="infoData.msgCancelBtn"></button>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'changeBankDetailInfoModel'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header" *ngIf="infoData.msgTitle">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"> </h2>
            </div>
            <div class="modal-body pb-4" [innerHTML]="infoData.msgBody"> </div>
            <div class="modal-footer">
                <button *ngIf="infoData.msgOkBtn" class="btn btn-secondary btn-md" (click)="modalClose()"
                    [innerHTML]="infoData.msgOkBtn"></button>
                <button *ngIf="infoData.msgCancelBtn" class="btn btn-secondary btn-md"
                    (click)="modalClose('redirectBankDetail')" [innerHTML]="infoData.msgCancelBtn"></button>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'deleteCardModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"> </h2>
            </div>
            <div class="modal-body pb-4" [innerHTML]="infoData.msgBody"></div>
            <div class="modal-footer">
                <button *ngIf="infoData.msgCancelBtn" type="button" class="btn btn-secondary btn-md"
                    (click)="deleteCardModal('Yes')" [innerHTML]="infoData.msgOkBtn"></button>
                <a *ngIf="infoData.msgOkBtn" class="text-center d-block w100 pt-4" (click)="deleteCardModal('No')"
                    [innerHTML]="infoData.msgCancelBtn"></a>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'registrationCloseConfirmation'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="infoData.msgTitle"> </h2>
            </div>
            <div class="modal-body pb-4" [innerHTML]="infoData.msgBody"></div>
            <div class="modal-footer">
                <a *ngIf="infoData.msgOkBtn" class="btn btn-secondary btn-md" (click)="modalClose()"
                    [innerHTML]="infoData.msgCancelBtn"></a>
                <button *ngIf="infoData.msgCancelBtn" type="button" class="btn btn-theme-outline mb-0 mt-0"
                    (click)="modalClose(true)" [innerHTML]="infoData.msgOkBtn"></button>
            </div>
        </div>
    </div>
</div>

<div class="modal info-modal" tabindex="-1" role="dialog" *ngIf="infoData.modelName == 'lugasLimitUpdateInfoModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel"
                [innerHTML]="replaceAmountText(userProfileLimitsPageAssets.text.userLimitsPage_deposit_over_limit_request_modal_title, formData.amount)">
                </h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body lugas-body pb-4">
                <div class="mb-2" [innerHTML]="userProfileLimitsPageAssets.text.userLimitsPage_deposit_over_limit_request_modal_text1"></div>
                <div class="limit-text" [innerHTML]="userProfileLimitsPageAssets.text.userLimitsPage_deposit_over_limit_request_modal_text2"></div>
                <div class="d-flex align-items-center mt-4 justify-content-between">
                    <div class="custom-control custom-checkbox mb-3">
                        <input type="checkbox" [(ngModel)]="isAcceptlugasLimitUpdate" class="custom-control-input" name="phone" id="lugasLimitCheckbox">
                        <label class="custom-control-label" for="lugasLimitCheckbox" [innerHTML]="userProfileLimitsPageAssets.text.userLimitsPage_deposit_over_limit_checkbox_request_label"></label>
                    </div>
                </div>
            </div>
            <div class="modal-footer">
                <button type="button" [disabled]="!isAcceptlugasLimitUpdate" class="btn btn-theme-outline mb-0 mt-0" (click)="modalClose(true)"
                    [innerHTML]="userProfileLimitsPageAssets.text.userLimitsPage_change_limit_btn_title"></button>
                <button type="button" class="btn btn-secondary btn-md" (click)="modalClose()"
                    [innerHTML]="userProfileWithdrawPageAssets.text.userProfileWithdrawPage_cancel_btn"></button>
            </div>
        </div>
    </div>
</div>