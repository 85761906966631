import { Injectable, Inject, PLATFORM_ID } from '@angular/core';
import { Observable } from 'rxjs';
import { Meta, Title } from '@angular/platform-browser';
import { LocalStorageService } from './local-storage.service';
import * as moment from 'moment';
import { isPlatformBrowser } from '@angular/common';

// Services
import { ConfigService } from './config.service';
import { ApiService } from './api.service';
import { DataService } from './data.service';

declare let decodeURI: any;
@Injectable()
export class SeoService {
	public defaultMeta: any = {
		title: 'DIE SPIELBANK | Online Slots. Entspannt. Sicher. Legal 🎰',
		description: 'Hier Spielt Deutschland 💎 DIE SPIELBANK bietet eine Riesenauswahl an Online-Spielautomaten (Slots). Mit deutscher Lizenz. Sicher und legal. Jetzt spielen',
	};

	constructor(
		public meta: Meta,
		public titleService: Title,
		private _configService: ConfigService,
		private _localStorageService: LocalStorageService,
		private _apiService: ApiService,
		private _dataService: DataService,
		@Inject(PLATFORM_ID) private platformId: Object,
	) { }

	public updateMetaTag(data: any, pageName: any, gameMetaData: any = null) {
		if (!data) {
			return;
		}
		let metaData: any = {};
		let seo = data.seo;


		if (seo[pageName]) {
			metaData = seo[pageName];
		}

		if (gameMetaData) {
			metaData = gameMetaData;
		}

		let metaTitle = metaData.title;
		if (metaTitle) {
			metaTitle = metaTitle.replace('&amp;#', '&#');
		}

		let metaDescription = metaData.description;
		if (metaDescription) {
			metaDescription = metaDescription.replace('&amp;#', '&#');
		}

		this.titleService.setTitle(this.decodeStr(metaTitle) || this.defaultMeta.title);
		this.meta.updateTag({ name: 'title', content: this.decodeStr(metaTitle) || this.defaultMeta.title, charset: "utf-8" });
		this.meta.updateTag({ name: 'keywords', content: this.decodeStr(metaData.keywords), charset: "utf-8" });
		this.meta.updateTag({ name: 'description', content: this.decodeStr(metaDescription) || this.defaultMeta.description, charset: "utf-8" });
		this.meta.updateTag({ property: 'og:type', content: 'Website' });
		this.meta.updateTag({ property: 'og:title', content: metaTitle || this.defaultMeta.title });
		this.meta.updateTag({ property: 'og:description', content: metaDescription || this.defaultMeta.description });
		this.meta.updateTag({ property: 'og:site_name', content: window.location.href });
		this.meta.updateTag({ property: 'og:url', content: metaTitle || this.defaultMeta.title });
		this.meta.updateTag({ property: 'og:image', content: metaTitle || this.defaultMeta.title });
	}

	decodeStr(val: any) {
		if (isPlatformBrowser(this.platformId)) { return  jQuery('<span />').html(val).text()};
	}

	public updateMetaTagNoIndex(val: any) {
		// if (val) {
		// 	this.meta.updateTag({ name: 'robots', content: val });
		// } else {
		// 	this.meta.removeTag('name="robots"');
		// }
	}

	loadPreRenderIoApi(recashUrlAfterDays: number = 3) {
		// let windUrl = window.location.href;
		// if (!windUrl.includes('localhost')) {
		// 	this.serachPreRenderUrl(recashUrlAfterDays);
		// }
	}

	serachPreRenderUrl(recashUrlAfterDays: any) {
		let windowUrl = window.location.protocol + '//' + window.location.host + window.location.pathname;
		if(windowUrl.includes('assets') || windowUrl.includes('pwa')) {
			return;
		}

		let param = {
			"prerenderToken": this._configService.getPreranderToken()
		};
		this.searchPreRenderUrlApi(param).subscribe((res: any) => {
			let urlList = res || [];
			let alreadyCashUrl = false;
			if (urlList.length) {
				urlList.map((val: any) => {
					if (val.url == windowUrl) {
						alreadyCashUrl = true;
						let currentDate = moment().startOf('day');
						let lastRefresh = moment(val.requestedRecacheAt).startOf('day');
						let diff = moment(currentDate).diff(lastRefresh, 'days');
						if (!isNaN(diff) && diff >= recashUrlAfterDays) {
							this.recachePreRenderUrl(val.url);
						}
					}
				});

				if (!alreadyCashUrl) {
					this.recachePreRenderUrl(windowUrl);
				}
			} else {
				this.recachePreRenderUrl(windowUrl);
			}
		}, (err: any) => {
			console.log('searchPreRenderUrlApi errr----', err);
		});
	}

	recachePreRenderUrl(url: any) {
		if(url.includes('assets')) {
			return;
		}
		
		let param = {
			'prerenderToken': this._configService.getPreranderToken(),
			'url': url,
			'startsWith': 'string',
			'adaptiveType': 'desktop'
		};

		if (this._dataService.isMobile()) {
			param['adaptiveType'] = 'mobile'
		}

		this.getPreRenderIoApi(param).subscribe((res: any) => {
		}, (err: any) => {
		});
	}


	getPreRenderIoApi(param: any): Observable<any> | any {
		const apiUrl = 'recache';
		return this._apiService.preRenderIORequest('post', apiUrl, param, '', false, false);
	}

	searchPreRenderUrlApi(param: any): Observable<any> | any {
		const apiUrl = 'search';
		return this._apiService.preRenderIORequest('post', apiUrl, param, '', false, false);
	}
}
