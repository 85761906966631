import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppResolver, AppLoginResolver, DefaultClientLangResolver, checkRootRoutingResolver } from './services/app-resolve.service';

const routes: Routes = [
    {
        path: ':language',
        loadChildren: () => import('./component/home/home.module').then(mod => mod.HomeModule),
        resolve: { appData: AppResolver },
        data: {pages: ['homePage', 'mainCarouselPage'], identifier: ['pages', 'custom']}
    },
    {
        path: '',
        loadChildren: () => import('./component/home/home.module').then(mod => mod.HomeModule),
        resolve: { appData: AppResolver, checkDefaultLang: checkRootRoutingResolver },
        data: {pages: ['homePage', 'mainCarouselPage'], identifier: ['pages', 'custom']}
    },
    {
        path: 'deposit-success',
        loadChildren: () => import('./component/home/home.module').then(mod => mod.HomeModule),
        resolve: { appData: AppResolver, checkLogin: AppLoginResolver },
        data: {pages: ['homePage', 'mainCarouselPage'], identifier: ['pages', 'custom']}
    },
    {
        path: 'einzahlung-erfolgreich',
        loadChildren: () => import('./component/home/home.module').then(mod => mod.HomeModule),
        resolve: { appData: AppResolver, checkLogin: AppLoginResolver },
        data: {pages: ['homePage', 'mainCarouselPage'], identifier: ['pages', 'custom']}
    },

    {
        path: 'deposit-failure',
        loadChildren: () => import('./component/home/home.module').then(mod => mod.HomeModule),
        resolve: { appData: AppResolver, checkLogin: AppLoginResolver },
        data: {pages: ['homePage', 'mainCarouselPage'], identifier: ['pages', 'custom']}
    },
    {
        path: 'einzahlung-fehlgeschlagen',
        loadChildren: () => import('./component/home/home.module').then(mod => mod.HomeModule),
        resolve: { appData: AppResolver, checkLogin: AppLoginResolver },
        data: {pages: ['homePage', 'mainCarouselPage'], identifier: ['pages', 'custom']}
    },
    {
        path: 'deposit-cancelled',
        loadChildren: () => import('./component/home/home.module').then(mod => mod.HomeModule),
        resolve: { appData: AppResolver, checkLogin: AppLoginResolver },
        data: {pages: ['homePage', 'mainCarouselPage'], identifier: ['pages', 'custom']}
    },
    {
        path: 'einzahlung-storniert',
        loadChildren: () => import('./component/home/home.module').then(mod => mod.HomeModule),
        resolve: { appData: AppResolver, checkLogin: AppLoginResolver },
        data: {pages: ['homePage', 'mainCarouselPage'], identifier: ['pages', 'custom']}
    },
    {
        path: '404',
        loadChildren: () => import('./component/error-404/error-404.module').then(mod => mod.Error404Module),
        resolve: { appData: AppResolver },
        data: { pages: ['aboutUsPage', 'error404Page'], identifier: ['pages'] }
    },
    { path: '**', redirectTo: '404', pathMatch: 'full' }
];



@NgModule({
    imports: [RouterModule.forRoot(routes, {
    useHash: false,
    initialNavigation: 'enabledBlocking'
})],
    exports: [RouterModule]
})

export class AppRoutingModule { }
