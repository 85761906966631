import { Component, OnInit, Inject, OnDestroy, AfterViewInit, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

// Services
import { RoutingService } from 'src/app/services/routing.service';
import { DataService } from 'src/app/services/data.service';
import { PlayerProfileService } from 'src/app/services/player-profile.service';
import { LanguageService } from 'src/app/services/language.service';
import { GeneralService } from 'src/app/services/general.service';

declare let jQuery: any;

@Component({
    selector: 'app-regulatory-modal',
    templateUrl: './regulatory-modal.component.html',
    styleUrls: ['./regulatory-modal.component.scss']
})

export class RegulatoryModalComponent implements OnInit, AfterViewInit, OnDestroy {
    // Public Var
    public regulatoryData: any = [];
    public headerPageAssets: any;
    public modalData: any;
    public isApiProcess: boolean = false;
    public registerPageRegModalAssets: any;

    // Private Var
    private loadRegulatoryDataService: any = null;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public dialogRef: MatDialogRef<RegulatoryModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private _routingService: RoutingService,
        private _dataService: DataService,
        private _playerProfileService: PlayerProfileService,
        private _languageService: LanguageService,
        private _generalService: GeneralService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.modalData = data;
    }

    ngOnInit() {
        this.headerPageAssets = this._dataService.getAssets('headerPage');
        this.registerPageRegModalAssets = this._dataService.getAssets('registerPage');
        this.loadRegulatoryData();
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.cliekToEle();
        }, 1500);
    }

    loadRegulatoryData() {
        let langCode = this._languageService.getSelectedLanguage();
        let params = {
            'languageCode': langCode,
            'consentName': this.modalData.consentName
        };
        this.isApiProcess = true;
        this.loadRegulatoryDataService = this._playerProfileService.loadRegulatoryConsent(params).subscribe((res: any) => {
            let data = res || {};
            if (data.success) {
                let consent = JSON.parse(data.message);
                this.regulatoryData = consent.consentList || [];
            } else {
                this._generalService.toastError(this.headerPageAssets.errormsg.headerPage_error_common_msg_text);
            }
            this.isApiProcess = false;
        }, (error: any) => {
            this.isApiProcess = false;
            if(error.message) {
                this._generalService.toastError(this.headerPageAssets.errormsg.headerPage_error_common_msg_text);
            }
        });
    }

    modalClose() {
        this.dialogRef.close();
    }

    acceptRegulatoryConsent() {
        let params = {
            'consentStatus': true,
            'consentName': this.modalData.consentName
        };
        this.loadRegulatoryDataService = this._playerProfileService.updateConsentData(params).subscribe((res: any) => {
            let data = res || {};
            if (data.success) {
                this.dialogRef.close('accepted');
            }
        }, (error: any) => {
            console.log(error);
        });
    }

    cliekToEle() {
        const me = this;
        if (isPlatformBrowser(this.platformId)) {
            const privacyEle = jQuery('.gotoPrivacy');
            if (privacyEle.length) {
                privacyEle.click(() => {
                    me.modalClose();
                    me._routingService.altRouterLink('privacy-notice');
                });
            }
        }
    }

    ngOnDestroy() {
        if (this.loadRegulatoryDataService) {
            this.loadRegulatoryDataService.unsubscribe();
        }
    }
}

