import { Component, OnInit, OnDestroy, Output, Inject, EventEmitter, PLATFORM_ID } from '@angular/core';
import { Router } from '@angular/router';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

//Services
import { DataService } from 'src/app/services/data.service';
import { RegistrationService } from 'src/app/services/registration.service';
import { GeneralService } from 'src/app/services/general.service';
import { GoogleAnalitycsService } from 'src/app/services/google-analitycs.service';
import { PlayerProfileService } from 'src/app/services/player-profile.service';

declare let jQuery: any;

@Component({
    selector: 'app-lugas-limit-modal',
    templateUrl: './lugas-limit-modal.component.html',
    styleUrls: ['./lugas-limit-modal.component.scss']
})

export class LugasLimitModalComponent implements OnInit, OnDestroy {
    @Output() stepStatus: EventEmitter<any> = new EventEmitter();

    // Public Var
    public limitForm!: FormGroup;
    public submitted = false;
    public registerPageAssets: any;
    public userProfileLimitsPageAssets: any = {};
    public lugasLimits: any = {};
    public isRegApiProcess = false;
    public lugasRulePage: any;
    public headerPageAssets: any;

    // Private Var
    private saveRegStepSub: any = null;
    private saveLimitsService: any = null;
    private checkLugasLimitsService: any = null;
    private getLimitApiEmitter: any = null;
    private pageDataEmitter: any = null;

    constructor(
        public dialogRef: MatDialogRef<LugasLimitModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public router: Router,
        public fb: FormBuilder,
        private _dataService: DataService,
        private _registrationService: RegistrationService,
        private _generalService: GeneralService,
        private _googleAnalitycsService: GoogleAnalitycsService,
        private _playerProfileService: PlayerProfileService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    ngOnInit() {
        this.loadInitData();
        this._googleAnalitycsService.trackingUserAction('event', 'regStep3');
        this.pageDataEmitter = this._dataService.pageDataLoadEmitter.subscribe((res) => {
            this.loadInitData();
        });
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            jQuery('body').addClass('modal-open');
            setTimeout(() => {
                setTimeout(() => {
                    jQuery('.form-control').on('focus blur', function (e: any) {
                        jQuery(this).parents('.form-group').toggleClass('has-label', (e.type === 'focus' || this.value.length > 0));
                    }).trigger('blur');
                }, 100);
                this._generalService.redirectToPage();
            }, 1000);
        }
    }

    get f() {
        return this.limitForm.controls;
    }

    loadInitData() {
        this.registerPageAssets = this._dataService.getAssets('registerPage');
        this.headerPageAssets = this._dataService.getAssets('headerPage');
        this.userProfileLimitsPageAssets = this._dataService.getAssets('userProfileLimitsPage');
        this.lugasRulePage = this._dataService.getAssets('lugasRulesPage');
        this.limitForm = this.fb.group({
            limit: [''],
            isConfrimLugasLimit: [true]
        });

        let formData = this._registrationService.getRegStepData('regStep3Data');
        if (formData) {
            this.limitForm.patchValue(formData);
            if (isPlatformBrowser(this.platformId)) {
                jQuery('.form-control').trigger('blur');
            }
        }
    }

    checkLugasLimitSet() {
        this.checkLugasLimitsService = this._playerProfileService.lugasCheckLimitApi().subscribe((res: any) => {
            let data = res || {};
            if (data.success) {
                this.lugasLimits = JSON.parse(data.message) || {};
                if (!this.lugasLimits.limitAvailable) {
                    this.limitForm.get('limit')!.setValidators([Validators.required, Validators.pattern(/^[0-9]\d*$/), Validators.min(1), Validators.max(1000)]);
                    this.limitForm.get('limit')!.updateValueAndValidity();
                }
            }
        }, (err: any) => {
            if (err.errors) {
                if (err.errors.length) {
                    let errArr = err.errors;
                    errArr.map((val: any) => {
                        this.handleRegErrors(val, 'errorMessage');
                    });
                } else {
                    this.handleRegErrors(err, 'message');
                }
            }
        });
    }

    changeLuggasLimitConfrimRadio(event: any) {
        if (!this.limitForm.get('isConfrimLugasLimit')!.value) {
            this.limitForm.get('limit')!.setValidators([Validators.required, Validators.pattern(/^[0-9]\d*$/), Validators.min(1), Validators.max(1000)]);
            this.limitForm.get('limit')!.updateValueAndValidity();
        } else {
            this.limitForm.get('limit')!.reset('');
            this.limitForm.get('limit')!.clearValidators();
            this.limitForm.get('limit')!.updateValueAndValidity();
        }
    }

    onSubmitLimitForm() {
        this.submitted = true;
        if (this.limitForm.invalid) {
            return;
        }

        let limitVal = this.limitForm.value.limit;
        if (this.limitForm.get('isConfrimLugasLimit')!.value) {
            limitVal = 0;
        }

        this.isRegApiProcess = true;
        this.saveLimitsService = this._playerProfileService.setLugasLimit(limitVal).subscribe((res: any) => {
            try {
                let data = res || {};
                this._generalService.toastSuccess(this.userProfileLimitsPageAssets.errormsg.userLimitsPage_success_limits_updated_successfully_msg);
                this.modalClose();
            } catch (e) {
                this.isRegApiProcess = false;
                console.log('exception caught in saveRegStep2', e);
            }
        }, (err: any) => {
            this.isRegApiProcess = false;
            if (err.errors) {
                if (err.errors.length) {
                    let errArr = err.errors;
                    errArr.map((val: any) => {
                        this.handleRegErrors(val, 'errorMessage');
                    });
                } else {
                    this.handleRegErrors(err, 'message');
                }

                let seoObj = {
                    depositLimit: parseInt(limitVal),
                    regStep: '3',
                    errorCode: err.errorCode,
                    errorMessage: err.message
                };
                this._googleAnalitycsService.trackingUserAction('event', 'regError', seoObj);
            }
            if (err.errorCode == '991003') {
                this.limitForm.get('isConfrimLugasLimit')!.setValue(false);
                this.limitForm.get('limit')!.setValidators([Validators.required, Validators.pattern(/^[0-9]\d*$/), Validators.min(1), Validators.max(1000)]);
                this.limitForm.get('limit')!.markAsPristine();
                this.limitForm.get('limit')!.markAsUntouched();
                this.limitForm.get('limit')!.updateValueAndValidity();
            }
        });
    }

    handleRegErrors(err: any, msgKey: any) {
        let isFrmError = true;
        if (err.errorCode == '9479') {
            this.limitForm.controls['limit'].setErrors({ 'e9479': true });
        } else if (err.errorCode == '1053') {
            this._generalService.toastError(this.registerPageAssets.errormsg.registerPageAssets_error_not_able_set_limit_msg);
        } else if (err.errorCode == '990002' || err.errorCode == '990003' || err.errorCode == '990005' || err.errorCode == '991005' || err.errorCode == '991002' || err.errorCode == '991003' ||
            err.errorCode == '991010' || err.errorCode == '991021' || err.errorCode == '991022' || err.errorCode == '991023' || err.errorCode == '990024' || err.errorCode == '991025'
            || err.errorCode == '991026' || err.errorCode == '990004' || err.errorCode == '990006' || err.errorCode == '991111' || err.errorCode == '991030') {
            // Lugas Error err.errorCode
            let lugasErrorMsg = this._generalService.getLugasErrorMessage(this.lugasRulePage, +err.errorCode);
            this._generalService.toastError(lugasErrorMsg);
        } else {
            isFrmError = false;
        }

        if (!isFrmError) {
            let errorBodyMsg = this._registrationService.regErrorCode(parseInt(err.errorCode), this.registerPageAssets);
            if (!errorBodyMsg) {
                errorBodyMsg = err[msgKey];
            }
            this._generalService.toastError(err[msgKey]);
        }
    }

    modalClose(arg: any = '') {
        this.dialogRef.close(arg);
    }

    ngOnDestroy() {
        if (isPlatformBrowser(this.platformId)) {
            jQuery('body').removeClass('modal-open');
        }
        if (this.saveRegStepSub) {
            this.saveRegStepSub.unsubscribe();
        }

        if (this.saveLimitsService) {
            this.saveLimitsService.unsubscribe();
        }
        if (this.checkLugasLimitsService) {
            this.checkLugasLimitsService.unsubscribe();
        }
        if (this.getLimitApiEmitter) {
            this.getLimitApiEmitter.unsubscribe();
        }
        if (this.pageDataEmitter) {
            this.pageDataEmitter.unsubscribe();
        }
    }
}
