import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { LocalStorageService } from './local-storage.service';

// Services
import { ConfigService } from './config.service';

@Injectable()

export class RoutingService {
    private prevRouteUrl: any = {};
    private gameListPrevRouteUrl: any = {};
    private searchGamePrevRouteUrl: any = {};
    private currentActivePageName: any = {};

    constructor(private _configService: ConfigService,
        private router: Router,
        private _localStorageService: LocalStorageService
    ) { }

    altRouterLink(pathName: any, params: any = {}, appendParam: any = '') {
        let lang = this.getSelectedLanguage();
        try {
            let langUrl: any = this.getUrlUsingLang();
            let url = langUrl[pathName][lang];
            if (appendParam) {
                this.router.navigate([url, appendParam], { queryParams: params });
            } else {
                this.router.navigate([url], { queryParams: params });
            }
        } catch (err) {
            console.log('exception caught in altRouterLink', err);
        }
    }

    altRouterLinkActive(pathName: any) {
        let lang = this.getSelectedLanguage();
        try {
            let langUrl: any = this.getUrlUsingLang();
            let url = langUrl[pathName][lang];
            return url;
        } catch (err) {
            console.log('exception caught in altRouterLinkActive', err);
        }
    }

    public getSelectedLanguage(): string {
        let lang: any;
        lang = this._localStorageService.get('language');
        if (!lang) {
            lang = this._configService.getClientDefaultLang();
        }
        return lang;
    }

    setPrevRoute(url: any) {
        this.prevRouteUrl = url;
    }

    getPrevRoute() {
        return this.prevRouteUrl;
    }

    setCurrentActivePageName(url: any) {
        console.log('setCurrentActivePageName--', url)
        this.currentActivePageName = url;
    }

    getCurrentActivePageName() {
        return this.currentActivePageName;
    }

    setGameListPrevRoute(url: any) {
        this.gameListPrevRouteUrl = url;
    }

    getGameListPrevRoute() {
        return this.gameListPrevRouteUrl;
    }

    setSearchGamePrevRouteUrl(url: any) {
        this.searchGamePrevRouteUrl = url;
    }

    getSearchGamePrevRouteUrl() {
        return this.searchGamePrevRouteUrl;
    }

    getUrlUsingLang() {
        return {
            'home': {
                'de': '/de'
            },
            'deposit-success': {
                'de': '/einzahlung-erfolgreich'
            },
            'deposit-failure': {
                'de': '/einzahlung-fehlgeschlagen'
            },
            'deposit-cancelled': {
                'de': '/einzahlung-storniert'
            },
            'login': {
                'de': '/de/login'
            },
            'register': {
                'de': '/de/registrierung'
            },
            'register/account-data': {
                'de': '/de/registrierung/kontodaten'
            },
            'register/personal-data': {
                'de': '/de/registrierung/personenbezogenen-daten'
            },
            'register/limit-bank-data': {
                'de': '/de/registrierung/limit-bankdaten'
            },
            'register/verification': {
                'de': '/de/registrierung/verifizierung'
            },
            'agent-register': {
                'de': '/agenten-register'
            },
            'search': {
                'de': '/de/suche'
            },
            'categories': {
                'de': '/de/kategorien'
            },
            'forgot-password': {
                'de': '/de/passwort-vergessen'
            },
            'promotions': {
                'de': '/de/angebote'
            },
            'casino': {
                'de': '/de/slots'
            },
            'casino/game-play': {
                'de': '/de/slots/spielen'
            },
            'sports': {
                'de': '/de/sport'
            },
            'user': {
                'de': '/de/nutzer'
            },
            'user/overview': {
                'de': '/de/nutzer/uebersicht'
            },
            'user/account': {
                'de': '/de/nutzer/spielerkonto'
            },
            'user/account/account-details': {
                'de': '/de/nutzer/spielerkonto/account-details'
            },
            'user/account/bank-details': {
                'de': '/de/nutzer/spielerkonto/bank-details'
            },
            'user/change-password': {
                'de': '/de/nutzer/Kennwort ändern'
            },
            'user/game-history': {
                'de': '/de/nutzer/spielhistorie'
            },
            'user/notifications': {
                'de': '/de/nutzer/benachrichtigungen'
            },
            'user/security': {
                'de': '/de/nutzer/sicherheit'
            },
            'user/verification': {
                'de': '/de/nutzer/verifizierung'
            },
            'user/documents': {
                'de': '/de/nutzer/dokumente'
            },
            'user/deposit': {
                'de': '/de/nutzer/einzahlung'
            },
            'user/deposit/adyen': {
                'de': '/de/nutzer/einzahlung/adyen'
            },
            'user/deposit/adyen/visa': {
                'de': '/de/nutzer/einzahlung/adyen/visa'
            },
            'user/deposit/adyen/mastercard': {
                'de': '/de/nutzer/einzahlung/adyen/mastercard'
            },

            'user/payout': {
                'de': '/de/nutzer/auszahlung'
            },
            'user/payout/adyen': {
                'de': '/de/nutzer/auszahlung/adyen'
            },
            'user/payout/adyen/payout-method': {
                'de': '/de/nutzer/auszahlung/adyen/auszahlungsmethode'
            },
            'user/cashback': {
                'de': '/de/nutzer/cashback'
            },
            'bonus': {
                'de': '/de/bonus'
            },
            'user/transaction-history': {
                'de': '/de/nutzer/transaktionshistorie'
            },
            'user/limits': {
                'de': '/de/nutzer/limits'
            },
            'user/limits/set-limits': {
                'de': '/de/nutzer/limits/set-limits'
            },
            'user/limits/limits-history': {
                'de': '/de/nutzer/limits/limits-history'
            },
            'user/safe-gambling': {
                'de': '/de/nutzer/spielerschutz'
            },
            'user/self-exclusion': {
                'de': '/de/nutzer/selbstausschluss'
            },
            'user/self-block': {
                'de': '/de/nutzer/selbstblockieren'
            },
            'user/bonus': {
                'de': '/de/user/bonus'
            },
            'marketing-profiling': {
                'de': '/de/werbeeinstellungen'
            },
            'bonus-terms': {
                'de': '/de/bonus-agb'
            },
            'sport-book': {
                'de': '/de/sportwetten'
            },
            'sport-book/bets': {
                'de': '/de/sportwetten/wetten'
            },
            'sport-book/live-bet': {
                'de': '/de/sportwetten?type=livewetten'
            },
            'live-bet': {
                'de': 'livewetten'
            },
            'sports-bet': {
                'de': 'sportwette'
            },
            'sport': {
                'de': 'sportwette'
            },
            'today': {
                'de': 'heute'
            },
            'outright': {
                'de': 'saisonwette'
            },
            'long-term': {
                'de': 'langzeit'
            },
            'results': {
                'de': 'ergebnisse'
            },
            'result': {
                'de': 'ergebnisse'
            },
            'home-bet': {
                'de': 'heimwetten'
            },
            'favorites': {
                'de': 'favoriten'
            },
            'adyen': {
                'de': 'adyen'
            },
            'help': {
                'de': '/de/hilfe'
            },
            'about': {
                'de': '/de/ueber-uns'
            },
            'live-landing': {
                'de': '/de/jetzt-schnell-und-sicher-registrieren'
            },
            'pre-registration-identification-successful': {
                'de': '/de/vorregistrierung-identifzierung-erfolgreich'
            },
            'pre-registration-email-successfully-confirmed': {
                'de': '/de/vorregistrierung-e-mail-erfolgreich-bestaetigt'
            },
            'imprint': {
                'de': '/de/impressum'
            },
            'faq': {
                'de': '/de/faq'
            },
            'game-play': {
                'de': '/de/spielweise'
            },
            'game-list': {
                'de': '/de/alle-spiele'
            },
            'contact': {
                'de': '/de/kontakt'
            },
            'terms-and-conditions': {
                'de': '/de/agb'
            },
            'safety': {
                'de': '/de/sicherheit'
            },
            'privacy-notice': {
                'de': '/de/datenschutz'
            },
            'cookie-policy': {
                'de': '/de/cookies'
            },
            'betting-rules': {
                'de': '/de/wettregeln'
            },
            'responsible-gaming': {
                'de': '/de/verantwortungsbewusstes-spielen'
            },
            'data-protection': {
                'de': '/de/Datenschutz'
            },
            '404': {
                'de': '/de/404'
            }
        }
    }
}
