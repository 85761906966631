import { Component, OnInit, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { RoutingService } from 'src/app/services/routing.service';
import { DataService } from 'src/app/services/data.service';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { GeneralService } from 'src/app/services/general.service';
import { AccountDataService } from 'src/app/services/account-data.service';
import { LanguageService } from 'src/app/services/language.service';
import { PlayerProfileService } from 'src/app/services/player-profile.service';
import { GamesService } from '../../services/games.service';

declare let jQuery: any;

@Component({
    selector: 'info-modal',
    templateUrl: './info-modal.component.html',
    styleUrls: ['./info-modal.component.scss']
})

export class InfoModalComponent implements OnInit, AfterViewInit, OnDestroy {
    // Public Var
    public realityRemainingTime: any = {};
    public realityTimeInterval: any;
    public infoData;
    public formData: any = {};
    public headerPageAssets: any = {};
    public homePageAssets: any = {};
    public userProfileWithdrawPageAssets: any = {};
    public userProfileLimitsPageAssets: any = {};
    public isCalledApiResendEmail: any = false;
    public clientEnv: any = '';
    public isAcceptlugasLimitUpdate: boolean = false;
    public userProfileNotificationsPageAssets: any = {};
    public isStartRealityTimeInterval: any = false;

    // Private Var
    private resendEmailServiceSub: any = null;
    private deActivateWithdrawThresholdService: any = null;
    private startRealityCounterApiSub: any = null;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public dialogRef: MatDialogRef<InfoModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private _routingService: RoutingService,
        private _dataService: DataService,
        private _localStorageService: LocalStorageService,
        private _generalService: GeneralService,
        private _accountDataService: AccountDataService,
        private _languageService: LanguageService,
        private _playerProfileService: PlayerProfileService,
        private _gamesService: GamesService
    ) {
        this.infoData = data;
        if (data.formData) {
            this.formData = data.formData;
        }

        this.clientEnv = this._localStorageService.get('clientEnv');
    }

    ngOnInit() {
        this.headerPageAssets = this._dataService.getAssets('headerPage');
        this.userProfileNotificationsPageAssets = this._dataService.getAssets('userProfileNotificationsPage');
        this.homePageAssets = this._dataService.getAssets('homePage');
        this.userProfileWithdrawPageAssets = this._dataService.getAssets('userProfileWithdrawPage');
        this.userProfileLimitsPageAssets = this._dataService.getAssets('userProfileLimitsPage');

        if (this.formData.isRealityRemaining) {
            if (this.formData.byDefaultCounterStart) {
                this.startIntervalRealityCounterTime();
            } else {
                this.calculateRealityPuseTime();
            }
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this._generalService.redirectToPage();
        }, 1000);
    }

    modalClose(arg: any = '') {
        this.dialogRef.close(arg);
    }

    goToDeposit() {
        let playerDetails = this._localStorageService.get('player');
        if (playerDetails) {
            this._routingService.altRouterLink('user/deposit');
        } else {
            this._dataService.setGoToLoginFromUrl('user/deposit');
            this._routingService.altRouterLink('login');
        }
        this.dialogRef.close();
    }

    goToFaq() {
        this._routingService.altRouterLink('faq');
        this.dialogRef.close();
    }

    goToPreRegLandingPage() {
        this._routingService.altRouterLink('pre-registration-email-successfully-confirmed');
        this.dialogRef.close();
    }

    goToHelp() {
        this._routingService.altRouterLink('help');
        this.dialogRef.close();
    }

    goToHome() {
        this._routingService.altRouterLink('home');
        this.dialogRef.close();
    }

    resendEmail() {
        let langCode = this._languageService.getSelectedLanguage();
        let params: any = {
            changeEmailPageUrl: window.location.origin + '/' + langCode
        };
        this.isCalledApiResendEmail = true;
        this.resendEmailServiceSub = this._accountDataService.resendEmail(params).subscribe((res: any) => {
            if (res.success) {
                this._generalService.toastSuccess(this.headerPageAssets.errormsg.headerPage_error_common_email_sent_success_msg);
                this.dialogRef.close();
            } else {
                this._generalService.toastError(this.headerPageAssets.errormsg.headerPage_error_common_email_sent_success_msg);
            }
            this.isCalledApiResendEmail = false;
        }, (err: any) => {
            this.isCalledApiResendEmail = false;
            this._generalService.toastError();
        })
    }

    onChangeThreshold(e: any) {
        let params = {
            deactivate: e.target.checked
        };
        this.deActivateWithdrawThresholdService = this._playerProfileService.deActivateWithdrawThresholdApi(params).subscribe((res: any) => {
            let data = res || {};
            if (data.success) {
                this._generalService.toastSuccess(this.userProfileWithdrawPageAssets.errormsg.userProfileWithdrawPage_success_threshold_activate_msg);
            } else {
                this._generalService.toastSuccess(this.userProfileWithdrawPageAssets.errormsg.userProfileWithdrawPage_error_threshold_activate_msg);
            }
        }, (err: any) => {
            try {
                let errMsg = JSON.parse(err.message);
                this._generalService.toastError(errMsg);
            } catch (e) {
                this._generalService.toastError(err.message);
            }
        });
    }

    calculateRealityPuseTime() {
        this.isStartRealityTimeInterval = false;
        if (parseInt(this.formData.leftPauseTime) <= 0) {
            this.dialogRef.close(true);
        }
        this.realityRemainingTime.progressBarPr = 0;
        let remaingDate: any = new Date().setSeconds(new Date().getSeconds() + parseInt(this.formData.leftPauseTime));
        let progressBarPr = 100 / (parseInt(this.formData.leftPauseTime));
        let currentDate: any = new Date();
        if (currentDate >= remaingDate) {
            clearInterval(this.realityTimeInterval);
            this.realityRemainingTime = {
                minutes: '00',
                seconds: '00',
                progressBarPr: 0
            };
            return;
        }

        let distance = remaingDate - currentDate;
        let _second = 1000;
        let _minute = _second * 60;
        let _hour = _minute * 60;

        if (distance <= 0) {
            this.realityRemainingTime = {
                minutes: '00',
                seconds: '00',
                progressBarPr: 0
            };
            return;
        }

        let minutes: any = Math.floor((distance % _hour) / _minute);
        let seconds: any = Math.floor((distance % _minute) / _second);

        if (minutes <= 9) {
            minutes = '0' + minutes;
        }

        if (seconds <= 9) {
            seconds = '0' + seconds;
        }

        this.realityRemainingTime['minutes'] = minutes || '00';
        this.realityRemainingTime['seconds'] = seconds || '00';

        if (progressBarPr <= 0) {
            progressBarPr = 0;
        }

        if (this.realityRemainingTime.progressBarPr <= 0) {
            this.realityRemainingTime.progressBarPr = 0;
        }

        const tmp: any = parseFloat(this.realityRemainingTime.progressBarPr);
        this.realityRemainingTime.progressBarPr = (tmp + progressBarPr).toFixed(2);
    }

    onTapRealityCounter(isFinishCounter: boolean = false) {
        let param = {};
        if (isFinishCounter) {
            param = {
                isFinish: isFinishCounter
            };
        }
        this.startRealityCounterApiSub = this._gamesService.startRealityCounterApi(param).subscribe((res: any) => {
            if (res.success && isFinishCounter == false) {
                this.startIntervalRealityCounterTime();
            }
            if (isFinishCounter) {
                this.modalClose('continueGame');
            }
        }, (error: any) => {
            let errorBodyMsg = 'Something went wrong, please try again.';
            let errorTitleMsg = 'Fehler';
            if (error && error.errorCode) {
                let errorCode = +error.errorCode;
                errorBodyMsg = error.message;
            }

            if (!errorBodyMsg) {
                errorBodyMsg = 'Something went wrong, please try again.';
            }
            this._generalService.toastError(errorBodyMsg);
        });
    };

    startIntervalRealityCounterTime() {
        if (parseInt(this.formData.leftPauseTime) <= 0) {
            this.dialogRef.close(true);
        }
        this.realityRemainingTime.progressBarPr = 1;
        let remaingDate: any = new Date().setSeconds(new Date().getSeconds() + parseInt(this.formData.leftPauseTime + 1));
        let progressBarPr = 101 / (parseInt(this.formData.leftPauseTime));
        this.isStartRealityTimeInterval = true;
        this.realityTimeInterval = setInterval(() => {
            let currentDate: any = new Date();
            if (currentDate >= remaingDate) {
                clearInterval(this.realityTimeInterval);
                this.realityRemainingTime = {
                    minutes: '00',
                    seconds: '00',
                    progressBarPr: 0
                };
                return;
            }

            let distance = remaingDate - currentDate;
            let _second = 1000;
            let _minute = _second * 60;
            let _hour = _minute * 60;

            if (distance <= 0) {
                this.realityRemainingTime = {
                    minutes: '00',
                    seconds: '00',
                    progressBarPr: 0
                };
                return;
            }

            let minutes: any = Math.floor((distance % _hour) / _minute);
            let seconds: any = Math.floor((distance % _minute) / _second);

            if (minutes <= 9) {
                minutes = '0' + minutes;
            }

            if (seconds <= 9) {
                seconds = '0' + seconds;
            }

            this.realityRemainingTime['minutes'] = minutes || '00';
            this.realityRemainingTime['seconds'] = seconds || '00';

            if (progressBarPr <= 0) {
                progressBarPr = 0;
            }

            if (this.realityRemainingTime.progressBarPr <= 0) {
                this.realityRemainingTime.progressBarPr = 0;
            }

            const tmp: any = parseFloat(this.realityRemainingTime.progressBarPr);
            this.realityRemainingTime.progressBarPr = (tmp + progressBarPr).toFixed(2);
        }, 1010);
    }

    deleteCardModal(type: any) {
        this.dialogRef.close(type);
    }

    replaceAmountText(msg: any, amount: any) {
        if(!msg) {
            return msg;
        }
        return msg.replace('XXXX', amount);
    }
    
    ngOnDestroy() {
        if (this.realityTimeInterval) {
            clearInterval(this.realityTimeInterval);
        }
        if (this.deActivateWithdrawThresholdService) {
            this.deActivateWithdrawThresholdService.unsubscribe();
        }
        if (this.resendEmailServiceSub) {
            this.resendEmailServiceSub.unsubscribe();
        }
        if (this.startRealityCounterApiSub) {
            this.startRealityCounterApiSub.unsubscribe();
        }
    }
}
