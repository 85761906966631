<div class="modal tran-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="transactionOverviewAssets.text.transaction_overview_modal_title"></h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <p [innerHTML]="transactionOverviewAssets.text.transaction_overview_modal_text"></p>
                        <div class="trans-details-box d-flex justify-content-between align-items-center">
                            <div [innerHTML]="transactionOverviewAssets.text.transaction_overview_modal_bets_label"></div>
                            <div class="font-bold">{{playerCurrencySymbol.currencySign}} {{_dataService.showCommaVal(transactionData?.betAmount)}} </div>
                        </div>
                        <div class="trans-details-box d-flex justify-content-between align-items-center" *ngIf="transactionData?.profit > 0">
                            <div [innerHTML]="transactionOverviewAssets.text.transaction_overview_modal_winning_label"></div>
                            <div class="font-bold">{{playerCurrencySymbol.currencySign}} {{_dataService.showCommaVal(transactionData?.profit)}}</div>
                        </div>
                        <div class="trans-details-box d-flex justify-content-between align-items-center" *ngIf="transactionData?.profit <= 0">
                            <div [innerHTML]="transactionOverviewAssets.text.transaction_overview_modal_loss_label"></div>
                            <div class="font-bold">{{playerCurrencySymbol.currencySign}} {{mathAbs(transactionData?.profit)}}</div>
                        </div>
                    </div>
                    <div class="col-sm-12 text-center">
                        <button class="btn btn-secondary reg-btn btn-md" (click)="modalClose()"
                        [innerHTML]="transactionOverviewAssets.text.transaction_overview_modal_confirm_label"></button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
