import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root'
})

export class LocalStorageService {

    constructor() { }

    public set(setItem: any, data: any){
        localStorage.setItem(setItem, JSON.stringify(data));
    }

    public get(getItem: any){
        return JSON.parse(localStorage.getItem(getItem) as any);
    }

    public clear(){
        localStorage.clear();
    }

    public remove(key: any){
        localStorage.removeItem(key);
    }
}
