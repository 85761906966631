<div class="modal verification-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_id_verification_title"></h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'" [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body">
                <div class="row">
                    <div class="col-sm-12">
                        <div class="text-center mb-5" [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_modal_welcome_text"> </div>
                        <div class="text-center" [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_modal_thanks_text"></div>
                        <div class="d-flex align-items-cente justify-content-center mb-4">
                            <div class="icon mr-3" [inlineSVG]="'assets/images/icon-success.svg'" [removeSVGAttributes]="['xmlns']"></div>
                            <div class="bold" [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_modal_email_confirmed_text"></div>
                        </div>
                        <p class="mb-3">
                          <span [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_modal_start_play_game_by_identify_text"></span> 
                          <span class="bold" [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_modal_win_paid_to_verified_players_text"></span> 
                          <span [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_modal_after_verification_receive_bonus_text"></span>
                        </p>
                        <div class="verification-main">
                            <div class="row-custom">
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="box-bg">
                                        <div class="verification">
                                          <a class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Verified" *ngIf="regulationsStatus?.schufaStatus">
                                            <span class="status-icon verified"
                                              [inlineSVG]="userProfileIdVerificationAssets.digital.userIdVerificationPage_verified_icon"
                                              [removeSVGAttributes]="['xmlns']"></span>
                                          </a>
                                          <a class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Unverified"
                                            *ngIf="!regulationsStatus?.schufaStatus">
                                            <span class="status-icon"
                                              [inlineSVG]="userProfileIdVerificationAssets.digital.userIdVerificationPage_unverified_icon"
                                              [removeSVGAttributes]="['xmlns']"></span>
                                          </a>
                                        </div>
                                        <div class="mb-3">
                                          <img class="logo-image" [src]="userProfileIdVerificationAssets.digital.userIdVerificationPage_schufa_logo"
                                            [alt]="userProfileIdVerificationAssets.text.schufa_alt">
                                          <p class="text-white" *ngIf="regulationsStatus?.schufaVerificationDate"><span
                                              [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_id_verification_last_update_date_lable"></span>:
                                            <b class="last-update-date"> {{ regulationsStatus.schufaVerificationDate | date:"dd.MM.yy HH:mm" }}</b>
                                          </p>
                                        </div>
                                        <p [innerHTML]="registerPageAssets.text.registerPageAssets_schufa_text"></p>
                                
                                        <div class="custom-control custom-checkbox pt-0">
                                          <input type="checkbox" class="custom-control-input" id="schufaAgree" name="schufaAgree"
                                            [(ngModel)]="schufaAgreeIsChecked">
                                          <label class="custom-control-label" for="schufaAgree"
                                            [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_schufa_agree_text">
                                          </label>
                                        </div>
                                
                                        <button type="button" class="btn btn-secondary btn-md" (click)="checkSchufaStatus()"
                                          [disabled]="!schufaAgreeIsChecked">
                                          <span [innerHTML]="registerPageAssets.text.registerPageAssets_indentity_verification_btn_label"></span>
                                          <span class="icon-right-arrow" [inlineSVG]="'assets/images/arrow-right.svg'"
                                            [removeSVGAttributes]="['xmlns']"></span>
                                        </button>
                                
                                        <div class="pt-1" *ngIf="regulationsStatus?.schufaStatus">
                                          <p class="red-color m-0"
                                            [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_schufa_already_verified_text"></p>
                                        </div>
                                      </div>
                                </div>

                                 <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="box-bg">
                                        <div class="verification">
                                          <a class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Verified"
                                            *ngIf="regulationsStatus?.pxlStatus">
                                            <span class="status-icon verified"
                                              [inlineSVG]="userProfileIdVerificationAssets.digital.userIdVerificationPage_verified_icon"
                                              [removeSVGAttributes]="['xmlns']"></span>
                                          </a>
                                          <a class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Unverified"
                                            *ngIf="!regulationsStatus?.pxlStatus">
                                            <span class="status-icon"
                                              [inlineSVG]="userProfileIdVerificationAssets.digital.userIdVerificationPage_unverified_icon"
                                              [removeSVGAttributes]="['xmlns']"></span>
                                          </a>
                                        </div>
                                        <div class="mb-3">
                                          <img class="logo-image pxl-logo" [src]="registerPageAssets.digital.registerPageAssets_pxl_logo"
                                            [alt]="registerPageAssets.text.verify_alt">
                                          <p class="text-white" *ngIf="regulationsStatus?.postIdentVerificationDate"><span
                                              [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_id_verification_last_update_date_lable"></span>:
                                            <b class="last-update-date"> {{ regulationsStatus.postIdentVerificationDate | date:"dd.MM.yy HH:mm"
                                              }}</b>
                                          </p>
                                        </div>
                                        <p [innerHTML]="registerPageAssets.text.registerPageAssets_pxl_text"></p>
                                        <button type="button" class="btn btn-secondary btn-md" (click)="loadPXLVerificationApi()">
                                          <span [innerHTML]="registerPageAssets.text.registerPageAssets_indentity_verification_btn_label"></span>
                                          <span class="icon-right-arrow" [inlineSVG]="'assets/images/arrow-right.svg'"
                                            [removeSVGAttributes]="['xmlns']"></span>
                                        </button>
                                        <div class="pt-1" *ngIf="regulationsStatus?.pxlStatus">
                                          <p class="red-color m-0"
                                            [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_verifyu_already_verified_text"></p>
                                        </div>
                                      </div>
                                </div>
                                
                                <div class="col-sm-12 col-md-12 col-lg-12">
                                    <div class="box-bg">
                                        <div class="verification">
                                          <a class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Verified"
                                            *ngIf="regulationsStatus?.postIdentStatus">
                                            <span class="status-icon verified"
                                              [inlineSVG]="userProfileIdVerificationAssets.digital.userIdVerificationPage_verified_icon"
                                              [removeSVGAttributes]="['xmlns']"></span>
                                          </a>
                                          <a class="d-inline-block" tabindex="0" data-placement="top" data-toggle="tooltip" title="Unverified"
                                            *ngIf="!regulationsStatus?.postIdentStatus">
                                            <span class="status-icon"
                                              [inlineSVG]="userProfileIdVerificationAssets.digital.userIdVerificationPage_unverified_icon"
                                              [removeSVGAttributes]="['xmlns']"></span>
                                          </a>
                                        </div>
                                        <div class="mb-3">
                                          <img class="logo-image" [src]="userProfileIdVerificationAssets.digital.userIdVerificationPage_postIdent_logo"
                                            [alt]="userProfileIdVerificationAssets.text.postindent_alt">
                                          <p class="text-white" *ngIf="regulationsStatus?.postIdentVerificationDate"><span
                                              [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_id_verification_last_update_date_lable"></span>:
                                            <b class="last-update-date"> {{ regulationsStatus.postIdentVerificationDate | date:"dd.MM.yy HH:mm" }}</b>
                                          </p>
                                        </div>
                                        <p [innerHTML]="registerPageAssets.text.registerPageAssets_postIdent_text"></p>
                                        <button type="button" class="btn btn-secondary btn-md" (click)="formularDownload()">
                                          <span [innerHTML]="registerPageAssets.text.registerPageAssets_download_formular_btn_label"></span>
                                          <span class="icon-right-arrow" [inlineSVG]="'assets/images/arrow-right.svg'"
                                            [removeSVGAttributes]="['xmlns']"></span>
                                        </button>
                                        <div class="pt-1" *ngIf="regulationsStatus?.postIdentStatus">
                                          <p class="red-color m-0"
                                            [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_postIdent_already_verified_text">
                                          </p>
                                        </div>
                                      </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-12 text-right mt-5">
                        <a (click)="verifyLater()" [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_modal_verify_later_btn_text"></a>
                        <p class="mt-2" [innerHTML]="userProfileIdVerificationAssets.text.userIdVerificationPage_modal_verify_info_text"></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
