import { Component, AfterViewInit, OnInit, OnDestroy, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

// Services
import { DataService } from '../../services/data.service';
import { ConfigService } from 'src/app/services/config.service';
import { RoutingService } from 'src/app/services/routing.service';
import { UserService } from 'src/app/services/user.service';

declare let jQuery: any;

@Component({
    selector: 'app-nav-bottom',
    templateUrl: './nav-bottom.component.html',
    styleUrls: ['./nav-bottom.component.scss']
})

export class NavBottomComponent implements AfterViewInit, OnInit, OnDestroy {
    // Public Var
    public isMobile: boolean = false;
    public headerPageAssets: any;
    public isToornamentAllowed: boolean = false;
    public isVertualSportAllowed: boolean = false;
    public isShowMenuList: boolean = false;
    public isLogin: boolean = false;

    // Private Var
    private isMobileEmitter: any = null;
    private pageDataEmitter: any = null;
    private loginEmitter: any = null;

    constructor(
        private el: ElementRef,
        public route: ActivatedRoute,
        public router: Router,
        public _routingService: RoutingService,
        private _dataService: DataService,
        private _configService: ConfigService,
        private _userService: UserService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.isMobile = this._dataService.isMobile();
    }

    ngOnInit() {
        this.loadInitData();
        this.pageDataEmitter = this._dataService.pageDataLoadEmitter.subscribe((res) => {
            this.loadInitData();
        });
        this.isMobileEmitter = this._dataService.isMobileEmitter.subscribe((res) => {
            this.isMobile = res;
        });
        this.isLogin = this._userService.checkLoginUser();
        this.loginEmitter = this._configService.loginEmitter.subscribe((data) => {
            this.isLogin = this._userService.checkLoginUser();
        });
    }

    loadInitData() {
        this.isVertualSportAllowed = this._configService.getAllowedVirtualSport();
        this.isToornamentAllowed = this._configService.getAllowedTournament();
        this.headerPageAssets = this._dataService.getAssets('headerPage');
    }

    ngAfterViewInit() { }

    showMenuList(boolVar: any) {
        if (boolVar) {
            if (isPlatformBrowser(this.platformId)) {
                jQuery('.more-menu').toggleClass('menu-open');
                if (jQuery('.more-menu').hasClass('menu-open')) {
                    this.isShowMenuList = true;
                } else {
                    jQuery('.more-menu').removeClass('menu-open');
                    this.isShowMenuList = false;
                }
            }
        } else {
            if (isPlatformBrowser(this.platformId)) {
                jQuery('.more-menu').removeClass('menu-open');
            }
            this.isShowMenuList = false;
        }
    }

    closeMenuList() {
        if (isPlatformBrowser(this.platformId)) {
            jQuery('.more-menu').removeClass("menu-open");
        }
        this.isShowMenuList = false;
    }

    openNavBottom() { }

    hideNavBotom() {
        this._dataService.showHideNavBottomMenu(false);
    }

    changeRouting(pageName: any) {
        this._routingService.altRouterLink(pageName);
        this.closeMenuList();
    }

    goToBonus() {
        if (this.isLogin) {
            this.changeRouting('user/bonus');
        } else {
            this._routingService.altRouterLink('bonus');
            this.closeMenuList();
        }
    }

    ngOnDestroy() {
        if (this.isMobileEmitter) {
            this.isMobileEmitter.unsubscribe();
        }
        if (this.pageDataEmitter) {
            this.pageDataEmitter.unsubscribe();
        }
        if (this.loginEmitter) {
            this.loginEmitter.unsubscribe();
        }
    }

}
