<ng-container>
    <div class="d-flex footer-sticky-menu-body">
        <div class="container">
            <nav class="footer-sticky-menu">
                <ul class="nav footer-item d-flex">
                    <li class="nav-item more-menu">
                        <a class="nav-link" (click)="showMenuList(true)">
                            <span class="nav-link-main">
                                <span class="icon" [inlineSVG]="headerPageAssets?.digital.headerPage_menu_bar_icon"
                                    [removeSVGAttributes]="['xmlns']">
                                </span>
                                <span class="title-lnk"
                                    [innerHTML]="headerPageAssets?.text.headerPage_nav_more_menu_label"></span>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="showMenuList(false)"
                            [routerLink]="[_routingService.altRouterLinkActive('game-list')]"
                            [ngClass]="{'is-active':(router.url == _routingService.altRouterLinkActive('game-list'))}">
                            <span class="nav-link-main">
                                <span class="icon" [inlineSVG]="headerPageAssets?.digital.headerPage_games_footer_icon"
                                    [removeSVGAttributes]="['xmlns']">
                                </span>
                                <span class="title-lnk"
                                    [innerHTML]="headerPageAssets?.text.headerPage_nav_games_menu_label"></span>
                            </span>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" (click)="showMenuList(false)"
                            [routerLink]="[_routingService.altRouterLinkActive('search')]"
                            [ngClass]="{'is-active':(router.url == _routingService.altRouterLinkActive('search'))}">
                            <span class="nav-link-main">
                                <span class="icon" [inlineSVG]="'assets/images/icons/search-footer.svg'"
                                    [removeSVGAttributes]="['xmlns']">
                                </span>
                                <span class="title-lnk"
                                    [innerHTML]="headerPageAssets?.text.headerPage_nav_search_menu_label"></span>
                            </span>
                        </a>
                    </li>
                </ul>
            </nav>
        </div>
    </div>

    <div *ngIf="isShowMenuList" class="nav-bottom-body">
        <a class="navbar-brand" (click)="_routingService.altRouterLink('home')">
            <img class="nav-bottom-logo" [src]="headerPageAssets?.digital.headerPage_logo_icon"
                [alt]="headerPageAssets.text.logo_alt">
        </a>
        <button type="button" class="close close-userprofile" aria-label="Close" (click)="closeMenuList()">
            <span aria-hidden="true">
                <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                    [removeSVGAttributes]="['xmlns']"></span>
            </span>
        </button>
        <div class="nav-bottom-inner">
            <div class="panel-group" id="accordionMenu" role="tablist" aria-multiselectable="true">
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <a (click)="changeRouting('game-list')"
                            [innerHTML]="headerPageAssets.text.headerPage_all_games_menu">
                        </a>
                    </div>
                </div>
                <div class="panel panel-default" *ngIf="isLogin">
                    <div class="panel-heading">
                        <a (click)="changeRouting('user/deposit')"
                            [innerHTML]="headerPageAssets.text.headerPage_submenu_2">
                        </a>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <a (click)="changeRouting('about')" [innerHTML]="headerPageAssets.text.headerPage_aboutUs_menu">
                        </a>
                    </div>
                </div>
                <div class="panel panel-default">
                    <div class="panel-heading">
                        <a (click)="changeRouting('faq')"
                            [innerHTML]="headerPageAssets.text.headerPage_support_menu"></a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-container>