import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

// Services
import { ApiService } from './api.service';
import { DataService } from './data.service';
import { PlayerProfileService } from './player-profile.service';
import { GeneralService } from './general.service';
import { UserService } from './user.service';
import { RegistrationService } from './registration.service';
import { GoogleAnalitycsService } from './google-analitycs.service';
import { RoutingService } from './routing.service';

// Modal
import { MessageModalComponent } from '../component/user/deposit/adyen/message-modal/message-modal.component';

declare let jQuery: any;

@Injectable()
export class AdyenDepositService {
    constructor(
        public dialog: MatDialog,
        private _apiService: ApiService,
        private _dataService: DataService,
        private _playerProfileService: PlayerProfileService,
        private _generalService: GeneralService,
        private _userService: UserService,
        private _registrationService: RegistrationService,
        private _googleAnalitycsService: GoogleAnalitycsService,
        private _routingService: RoutingService
    ) { }

    // Get save Methods
    getPlayerDepositTokens(): Observable<any> | any {
        const apiUrl = 'playerProfile/playerDepositTokens';
        return this._apiService.request('get', apiUrl, {}, {}, false);
    }

    // Get Origin Key payments
    getOriginKey(): Observable<any> | any {
        const apiUrl = 'adyenpay/originKey';
        return this._apiService.request('post', apiUrl, {}, {}, false);
    }

    // Save depositCard
    saveDepositCard(data: any): Observable<any> | any {
        const apiUrl = 'adyenpay/depositCard';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // Save depositCard AP
    saveDepositCardAP(data: any): Observable<any> | any {
        const apiUrl = 'adyenpay/depositAP';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // Update Status APi
    depositUpdateStatusApi(data: any): Observable<any> | any {
        const apiUrl = 'adyenpay/v1/updateStatus';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    //Validate Deposit Bonus Api
    validateDepositBonusApi(data: any): Observable<any> | any {
        let apiUrl = 'bonus/v2/validateDepositBonus';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    //Validate Deposit Bonus Api
    deletePaymentTokenApi(data: any): Observable<any> | any {
        let apiUrl = 'v2/profile/deletePaymentToken';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // Deposit Paypal Status APi
    paypalStatusApi(data: any): Observable<any> | any {
        const apiUrl = 'paypalPayment/v1/paypalStatus';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // Deposit Paypal Payment APi
    paypalPaymentAPi(data: any): Observable<any> | any {
        const apiUrl = 'paypalPayment/deposit';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // Deposit PaysafeGroup SKRILL and Neteller Payment APi
    paysafeGroupProcessPaymentApi(data: any): Observable<any> | any {
        const apiUrl = 'processPayment/deposit';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    depositUpdateStatus(obj: any, assets: any) {
        let depositUpdateStatusMethod = 'depositUpdateStatusApi';
        if (obj.method === 'paypal') {
            depositUpdateStatusMethod = 'paypalStatusApi';
        }

        this[depositUpdateStatusMethod](obj).subscribe((data: any) => {
            try {
                let modalTitle = assets.text.userDepositPage_deposit_success_title;
                let modalMsg = assets.errormsg.userDepositPage_success_deposit_success_msg;

                if (data.message) {
                    let msgData = JSON.parse(data.message);
                    if (msgData.status) {
                        if (msgData.status.toLowerCase() == 'pending') {
                            obj['depositStatus'] = 'pending';
                            modalTitle = assets.text.userDepositPage_deposit_pending_title;
                            modalMsg = assets.errormsg.userDepositPage_pending_deposit_release_manually_msg;
                        } else if (msgData.status.toLowerCase() == 'pending approval') {
                            obj['depositStatus'] = 'pending';
                            modalTitle = assets.text.userDepositPage_deposit_pending_title;
                            modalMsg = assets.errormsg.userDepositPage_error_paypal_payment_not_approval;
                        }
                        this._googleAnalitycsService.trackingUserAction('event', 'deposit');
                    }
                }

                let dipoSuccess = this.adyenDepositSuccessModal(modalTitle, modalMsg, obj, assets);
                dipoSuccess.afterClosed().subscribe(result => {
                });
                this.loadPlayerProfileDetails(true);
            } catch (e) {
                console.log(e, 'exception caught in depositUpdateStatus');
            }
        }, (error: any) => {
            this.adyenPaymentErrorHandler(error, obj, assets);
        });
    }

    loadPlayerProfileDetails(isLoader: boolean = false) {
        this._playerProfileService.getPlayerProfile(isLoader).subscribe((res: any) => {
        }, (error: any) => {
            console.log(error);
        });

    }

    public adyenPaymentErrorHandler(error: any, obj: any, assets: any) {
        let code = null
        let errorCode = null;
        let errorBodyMsg = '';
        if (error && error.errors.length) {
            code = error.errors[0];
            errorCode = +code.errorCode;
            errorBodyMsg = this.adyenDepositErrorCode(assets, errorCode) || code.errorMessage;
        } else {
            errorCode = +error.errorCode;
            errorBodyMsg = this.adyenDepositErrorCode(assets, errorCode) || error.message;
        }

        try {
            if (error.errorCode == 9512) {
                let panicMsg = JSON.parse(error.message);
                if (panicMsg.panicEnable) {
                    errorBodyMsg = assets.errormsg.userDepositPage_error_panic_exclusion_enable_left_time_msg + ' ' + '<b>' + panicMsg.leftTime + '</b>';
                }
            } else if (error.errorCode == 990002 || error.errorCode == 990003 || error.errorCode == 990005 || error.errorCode == 991005 || error.errorCode == 991002 || error.errorCode == 991003 ||
                error.errorCode == 991010 || error.errorCode == 991021 || error.errorCode == 991022 || error.errorCode == 991023 || error.errorCode == 990024 || error.errorCode == 991025
                || error.errorCode == 991026 || error.errorCode == 990004 || error.errorCode == 990006 || error.errorCode == 991111 || error.errorCode == 991030) {
                let lugasRulePage = this._dataService.getAssets('lugasRulesPage');
                let lugasErrorMsg = this._generalService.getLugasErrorMessage(lugasRulePage, error.errorCode);

                if (error.errorCode == 991010) {
                    let msgData = JSON.parse(error.message) || {};
                    let currency = this._userService.getPlayerCurrency();
                    lugasErrorMsg = lugasErrorMsg + ' ' + msgData.availableAmount + currency.currencySign;
                    let errorModal = this.adyenValidateApiDepositErrorModal(
                        assets.text.userDepositPage_deposit_limit_error_modal_title,
                        assets.text.userDepositPage_deposit_limit_error_modal_msg,
                        {},
                        'validateApiModal',
                        {}
                    );
                    errorModal.afterClosed().subscribe(result => {
                        if (result == 'procced') {
                            this._routingService.altRouterLink('user/limits');
                        }
                    });
                    return;
                }

                if (error.errorCode == 990002 || error.errorCode == 991003) {
                    this._registrationService.openLugasLimitModal();
                    return;
                }
                errorBodyMsg = lugasErrorMsg;
            } else if (errorCode == 1053 || errorCode == 8319 || errorCode == 8320 || errorCode == 8321 || errorCode == 8322 || errorCode == 8323 || errorCode == 8324 || errorCode == 8325 || errorCode == 8326) {
                let lugasRulePage = this._dataService.getAssets('lugasRulesPage');
                let msg = '';
                console.log('errorCode===', errorCode);
                if (errorCode == 8326) {
                    let msgText = lugasRulePage['errormsg']['lugasRulesPage_lugas_limit_change_error_msg_8326'];
                    let res = JSON.parse(error.message);
                    msg = msgText.replace("XXXX", this._dataService.showCommaVal(res.availableAmount));
                } else {
                    msg = lugasRulePage['errormsg']['lugasRulesPage_lugas_limit_change_error_msg_' + errorCode];
                }

                this.adyenDepositErrorModal(
                    assets.text.userDepositPage_deposit_error_title,
                    msg,
                    obj, assets
                );
                return;
            }

        } catch (e) {
            console.log(e);
        }

        this.adyenDepositErrorModal(assets.text.userDepositPage_deposit_error_title, errorBodyMsg, obj, assets);
    }

    adyenDepositSuccessModal(msgTitle: string = '', msgBody: string = '', obj: any, assets: any) {
        if (!msgTitle) {
            msgTitle = assets.text.userDepositPage_deposit_success_title;
        }

        if (!msgBody) {
            msgBody = assets.errormsg.userDepositPage_success_deposit_success_msg;
        }
        return this.dialog.open(MessageModalComponent, {
            data: {
                msgTitle,
                msgBody,
                msgOkBtn: assets.text.userDepositPage_ok_btn_label,
                msgCancelBtn: null,
                msgType: 'success',
                obj
            },
            disableClose: true
        });
    }

    adyenDepositErrorModal(msgTitle: string = '', msgBody: string = '', obj: any, assets: any) {
        if (!msgTitle) {
            msgTitle = assets.text.userDepositPage_deposit_error_title;
        }

        if (!msgBody) {
            msgBody = assets.errormsg.userDepositPage_error_deposit_error_msg;
        }

        return this.dialog.open(MessageModalComponent, {
            data: {
                msgTitle,
                msgBody,
                msgOkBtn: assets.text.userDepositPage_ok_btn_label,
                msgCancelBtn: null,
                msgType: 'error',
                obj
            },
            disableClose: true
        });
    }

    adyenValidateApiDepositErrorModal(msgTitle: string = '', msgBody: string = '', assets: any, modelName: string = 'validateApiModal', obj: any = {}) {
        if (!msgTitle) {
            msgTitle = assets.text.userDepositPage_deposit_error_title;
        }

        if (!msgBody) {
            msgBody = assets.errormsg.userDepositPage_error_deposit_error_msg;
        }

        return this.dialog.open(MessageModalComponent, {
            data: {
                msgTitle,
                msgBody,
                msgOkBtn: assets.text.userDepositPage_proceed_btn_label,
                msgCancelBtn: assets.text.userDepositPage_cancel_btn_label,
                msgType: 'error',
                modelName: modelName,
                obj
            },
            disableClose: true
        });
    }

    checkPaypalRedirectionStatus(status: any, assets: any) {
        if (status) {
            let paypalCancelRef = this.adyenDepositErrorModal(
                assets.text.userDepositPage_deposit_error_title,
                assets.errormsg.userDepositPage_error_transaction_cancelled, {}, assets);

            paypalCancelRef.afterClosed().subscribe(result => {
                this._routingService.altRouterLink('home');
            });
        }
    }

    checkPaysafeGroupRedirection(status: any, assets: any) {
        let msgTitle;
        let msgBody;

        if (status == 'depositSuccess') {
            msgTitle = assets.text.userDepositPage_deposit_success_title;
            msgBody = assets.errormsg.userDepositPage_success_deposit_success_msg
        } else if (status == 'depositFailure') {
            msgTitle = assets.text.userDepositPage_deposit_error_title;
            msgBody = assets.errormsg.userDepositPage_error_deposit_error_msg
        } else if (status == 'depositCancelled') {
            msgTitle = assets.text.userDepositPage_deposit_cancelled_title;
            msgBody = assets.errormsg.userDepositPage_error_transaction_cancelled    
        }

        
        this.adyenDepositErrorModal( msgTitle,msgBody, {}, assets);
    }

    // Adyen Return Error Code
    adyenDepositErrorCode(assets: any, code: any) {
        switch (code) {
            case 8270:
                return assets.errormsg.userDepositPage_error_deposit_not_allowed;
            case 8290:
                return assets.errormsg.userDepositPage_error_payment_not_allowed;
            case 8292:
                return assets.errormsg.userDepositPage_error_kyc_config_payment_not_allowed;
            case 9225:
                return assets.errormsg.userDepositPage_error_epg_aml_not_allowed;
            case 1032:
                return assets.errormsg.userDepositPage_error_epg_amount_required;
            case 9913:
                return assets.errormsg.userDepositPage_error_paypal_rejected_account;
            case 9914:
                return assets.errormsg.userDepositPage_error_paypal_pending_approval;
            case 1127:
            case 5024:
                return assets.errormsg.userDepositPage_error_card_entered_wrong_doesnt_match;
            case 1128:
                return assets.errormsg.userDepositPage_error_credit_card_cvc_required;
            case 1126:
                return assets.errormsg.userDepositPage_error_epg_creditcard_required;
            case 1124:
                return assets.errormsg.userDepositPage_error_epg_creditcard_expiry_month_required;
            case 1125:
                return assets.errormsg.userDepositPage_error_epg_creditcard_expiry_year_required;
            case 8272:
                return assets.errormsg.userDepositPage_error_epg_creditcard_type_required;
            case 9105:
                return assets.errormsg.userDepositPage_error_valid_time_period;
            case 9007:
                return assets.errormsg.userDepositPage_error_card_numner_incorrect_invalid;
            case 9108:
                return assets.errormsg.userDepositPage_error_unable_parse_json_data;
            case 9023:
                return assets.errormsg.userDepositPage_error_specified_cvc_invalid;
            case 9064:
                return assets.errormsg.userDepositPage_error_expiry_date_invalid;
            case 9196:
                return assets.errormsg.userDepositPage_error_epg_payment_method_invalid;
            case 8218:
                return assets.errormsg.userDepositPage_error_epg_payment_configuration_missing;
            case 1088:
                return assets.errormsg.userDepositPage_error_epg_deposit_minmax;
            case 9002:
            case 8260:
                return assets.errormsg.userDepositPage_error_doc_validation_expired_kycLevel1;
            case 8301:
                return assets.errormsg.userDepositPage_error_epg_email_not_confirm;
            case 9511:
                return assets.errormsg.userDepositPage_error_xtree_hmpi_deposit_not_allowed;
            case 1087:
                return assets.errormsg.userDepositPage_error_reached_deposit_limit;
            case 9000:
                return assets.errormsg.userDepositPage_error_not_possible_aclear_assess;
            case 9001:
                return assets.errormsg.userDepositPage_error_transaction_refused;
            case 9003:
                return assets.errormsg.userDepositPage_error_transaction_occurred_acquirer;
            case 9004:
                return assets.errormsg.userDepositPage_error_transaction_blocked_unusable;
            case 9005:
                return assets.errormsg.userDepositPage_error_transaction_expired_unusable;
            case 9006:
                return assets.errormsg.userDepositPage_error_amount_mismatch;
            case 9008:
                return assets.errormsg.userDepositPage_error_authorise_transaction;
            case 9009:
                return assets.errormsg.userDepositPage_error_authorise_transactionbank_does_not_support_disallow_transaction;
            case 9010:
                return assets.errormsg.userDepositPage_error_3d_secure_authentication_not_executed;
            case 9011:
                return assets.errormsg.userDepositPage_error_enough_money_payable_amount;
            case 9012:
                return assets.errormsg.userDepositPage_error_transaction_between_states;
            case 9013:
                return assets.errormsg.userDepositPage_error_possible_fruad;
            case 9014:
                return assets.errormsg.userDepositPage_error_transaction_cancelled;
            case 9015:
                return assets.errormsg.userDepositPage_error_shopper_cancelled_transaction;
            case 9016:
                return assets.errormsg.userDepositPage_error_specified_pin_number_incorrect_invalid;
            case 9017:
                return assets.errormsg.userDepositPage_error_incorrect_pin_three_times;
            case 9018:
                return assets.errormsg.userDepositPage_error_not_possible_validate_specified_pin;
            case 9019:
                return assets.errormsg.userDepositPage_error_possible_fraud;
            case 9020:
                return assets.errormsg.userDepositPage_error_transaction_not_submitted_correctly;
            case 9021:
                return assets.errormsg.userDepositPage_error_flagged_transaction_fraudulent;
            case 9022:
                return assets.errormsg.userDepositPage_error_transaction_not_permitted_issuer;
            case 9024:
                return assets.errormsg.userDepositPage_error_restricted_card_invalid_card_contry;
            case 9025:
                return assets.errormsg.userDepositPage_error_revocation_authorizations_order_stop_payment;
            case 9026:
                return assets.errormsg.userDepositPage_error_generic_declined;
            case 9027:
                return assets.errormsg.userDepositPage_error_withdrawal_amount_permitted_shopper_card_exceeded;
            case 9028:
                return assets.errormsg.userDepositPage_error_number_withdrawals_permitted_shopper_card_exceeded;
            case 9029:
                return assets.errormsg.userDepositPage_error_amount_partially_approved;
            case 9030:
                return assets.errormsg.userDepositPage_error_issuer_reported_transaction_suspected_fraud;
            case 9031:
                return assets.errormsg.userDepositPage_error_avs_declined;
            case 9032:
                return assets.errormsg.userDepositPage_error_card_required_online_pin;
            case 9033:
                return assets.errormsg.userDepositPage_error_no_checking_account_available_card;
            case 9034:
                return assets.errormsg.userDepositPage_error_no_saving_account_available_card;
            case 9035:
                return assets.errormsg.userDepositPage_error_not_allowed;
            case 9036:
                return assets.errormsg.userDepositPage_error_no_amount_specified;
            case 9037:
                return assets.errormsg.userDepositPage_error_unable_determine_variant;
            case 9038:
                return assets.errormsg.userDepositPage_error_cvc_not_right_length;
            case 9039:
                return assets.errormsg.userDepositPage_error_billing_address_problem;
            case 9040:
                return assets.errormsg.userDepositPage_error_invalid_pares_from_issuer;
            case 9041:
                return assets.errormsg.userDepositPage_error_session_already_used_previously;
            case 9042:
                return assets.errormsg.userDepositPage_error_recurring_not_enabled;
            case 9043:
                return assets.errormsg.userDepositPage_error_invalid_bank_account_number;
            case 9044:
                return assets.errormsg.userDepositPage_error_invalid_variant;
            case 9045:
                return assets.errormsg.userDepositPage_error_bank_details_missing;
            case 9046:
                return assets.errormsg.userDepositPage_error_invalid_bank_country_code_specified;
            case 9047:
                return assets.errormsg.userDepositPage_error_bank_country_not_supported;
            case 9048:
                return assets.errormsg.userDepositPage_error_no_invoice_lines_provided;
            case 9049:
                return assets.errormsg.userDepositPage_error_received_incorrect_invoice_line;
            case 9050:
                return assets.errormsg.userDepositPage_error_total_amount_not_same_sum_lines;
            case 9051:
                return assets.errormsg.userDepositPage_error_invalid_dob;
            case 9052:
                return assets.errormsg.userDepositPage_error_invalid_billing_address;
            case 9053:
                return assets.errormsg.userDepositPage_error_invalid_delivery_address;
            case 9054:
                return assets.errormsg.userDepositPage_error_invalid_shopper_name;
            case 9055:
                return assets.errormsg.userDepositPage_error_shopper_email_missing;
            case 9056:
                return assets.errormsg.userDepositPage_error_shopper_reference_missing;
            case 9057:
                return assets.errormsg.userDepositPage_error_phone_number_missing;
            case 9058:
                return assets.errormsg.userDepositPage_error_phone_number_should_mobile;
            case 9059:
                return assets.errormsg.userDepositPage_error_invalid_phone_number;
            case 9060:
                return assets.errormsg.userDepositPage_error_invalid_recurring_contract_specified;
            case 9061:
                return assets.errormsg.userDepositPage_error_bank_acc_not_valid_missing;
            case 9062:
                return assets.errormsg.userDepositPage_error_account_holder_missing;
            case 9063:
                return assets.errormsg.userDepositPage_error_card_holder_missing;
            case 9065:
                return assets.errormsg.userDepositPage_error_reference_missing;
            case 9066:
                return assets.errormsg.userDepositPage_error_billing_address_problem_city;
            case 9067:
                return assets.errormsg.userDepositPage_error_billing_address_problem_street;
            case 9068:
                return assets.errormsg.userDepositPage_error_billing_address_problem_house_no;
            case 9069:
                return assets.errormsg.userDepositPage_error_billing_address_problem_country;
            case 9070:
                return assets.errormsg.userDepositPage_error_billing_address_problem_state;
            case 9071:
                return assets.errormsg.userDepositPage_error_failed_retrive_invoice_lines;
            case 9072:
                return assets.errormsg.userDepositPage_error_invalid_amout_specified;
            case 9073:
                return assets.errormsg.userDepositPage_error_unsupported_currency_spedified;
            case 9074:
                return assets.errormsg.userDepositPage_error_recurring_requires_shopper_email;
            case 9075:
                return assets.errormsg.userDepositPage_error_invalid_exp_month_year;
            case 9076:
                return assets.errormsg.userDepositPage_error_bank_name_location_invalid_missing;
            case 9077:
                return assets.errormsg.userDepositPage_error_submitted_total_ideal_merchant_return_url_length_0_1;
            case 9078:
                return assets.errormsg.userDepositPage_error_invalid_start_month_year;
            case 9079:
                return assets.errormsg.userDepositPage_error_invalid_issuer_country_code;
            case 9080:
                return assets.errormsg.userDepositPage_error_invalid_social_security_number;
            case 9081:
                return assets.errormsg.userDepositPage_error_delivery_address_problem_city;
            case 9082:
                return assets.errormsg.userDepositPage_error_delivery_address_problem_street;
            case 9083:
                return assets.errormsg.userDepositPage_error_delivery_address_problem_house_number_name;
            case 9084:
                return assets.errormsg.userDepositPage_error_delivery_address_problem_country;
            case 9085:
                return assets.errormsg.userDepositPage_error_delivery_address_problem_state_province;
            case 9086:
                return assets.errormsg.userDepositPage_error_invalid_number_installments;
            case 9087:
                return assets.errormsg.userDepositPage_error_invaild_cvc;
            case 9088:
                return assets.errormsg.userDepositPage_error_no_additional_data_specified;
            case 9089:
                return assets.errormsg.userDepositPage_error_no_acquier_specified;
            case 9090:
                return assets.errormsg.userDepositPage_error_no_authorization_mid_specified;
            case 9091:
                return assets.errormsg.userDepositPage_error_no_fields_specified;
            case 9092:
                return assets.errormsg.userDepositPage_error_required_field_0_not_specified;
            case 9093:
                return assets.errormsg.userDepositPage_error_invalid_number_requests;
            case 9094:
                return assets.errormsg.userDepositPage_error_not_allowed_store_payout_details;
            case 9098:
                return assets.errormsg.userDepositPage_error_auto_capture_delay_invaild_out_of_range;
            case 9099:
                return assets.errormsg.userDepositPage_error_mandate_id_does_not_match_pattern;
            case 9100:
                return assets.errormsg.userDepositPage_error_amount_not_allowed_for_this_operation;
            case 9101:
                return assets.errormsg.userDepositPage_error_original_psp_reference_required_operation;
            case 9102:
                return assets.errormsg.userDepositPage_error_authorization_code_required_operation;
            case 9103:
                return assets.errormsg.userDepositPage_error_generation_date_required_but_missing;
            case 9104:
                return assets.errormsg.userDepositPage_error_unable_parse_generation_date;
            case 9106:
                return assets.errormsg.userDepositPage_error_unable_load_private_key_decryption;
            case 9107:
                return assets.errormsg.userDepositPage_error_unable_decrypt_data;
            case 9109:
                return assets.errormsg.userDepositPage_error_invalid_shopper_reference;
            case 9110:
                return assets.errormsg.userDepositPage_error_invalid_shopper_email;
            case 9111:
                return assets.errormsg.userDepositPage_error_invalid_selected_brand;
            case 9112:
                return assets.errormsg.userDepositPage_error_invalid_recurring_contract;
            case 9113:
                return assets.errormsg.userDepositPage_error_invalid_recurring_detail_name;
            case 9114:
                return assets.errormsg.userDepositPage_error_invalid_additional_data;
            case 9115:
                return assets.errormsg.userDepositPage_error_missing_additional_data_field;
            case 9116:
                return assets.errormsg.userDepositPage_error_invalid_additional_data_field;
            case 9117:
                return assets.errormsg.userDepositPage_error_invalid_psp_echo_data;
            case 9118:
                return assets.errormsg.userDepositPage_error_invalid_shopper_statement;
            case 9119:
                return assets.errormsg.userDepositPage_error_invalid_shopper_ip;
            case 9120:
                return assets.errormsg.userDepositPage_error_no_params_specified;
            case 9121:
                return assets.errormsg.userDepositPage_error_invalid_field_0;
            case 9122:
                return assets.errormsg.userDepositPage_error_bin_details_not_found_for_given_card_number;
            case 9123:
                return assets.errormsg.userDepositPage_error_billing_address_missing;
            case 9124:
                return assets.errormsg.userDepositPage_error_could_not_find_account_with_key;
            case 9125:
                return assets.errormsg.userDepositPage_error_invalid_mcc;
            case 9126:
                return assets.errormsg.userDepositPage_error_reference_may_not_exceed_79_char;
            case 9127:
                return assets.errormsg.userDepositPage_error_cryptographic_operation_not_proceed;
            case 9128:
                return assets.errormsg.userDepositPage_error_invalid_country_code;
            case 9129:
                return assets.errormsg.userDepositPage_error_missing_invalid_network_tx_reference;
            case 9130:
                return assets.errormsg.userDepositPage_error_no_invoice_project_provided;
            case 9131:
                return assets.errormsg.userDepositPage_error_no_invoice_batch_provided;
            case 9132:
                return assets.errormsg.userDepositPage_error_no_crediotr_account_specified;
            case 9133:
                return assets.errormsg.userDepositPage_error_no_project_code_specified;
            case 9134:
                return assets.errormsg.userDepositPage_error_no_creditor_account_found;
            case 9135:
                return assets.errormsg.userDepositPage_error_no_project_found;
            case 9136:
                return assets.errormsg.userDepositPage_error_unable_create_invoice_project;
            case 9137:
                return assets.errormsg.userDepositPage_error_invoice_batch_already_exists;
            case 9138:
                return assets.errormsg.userDepositPage_error_unable_create_invoice_batch;
            case 9139:
                return assets.errormsg.userDepositPage_error_invoice_batch_validity_period_exceeded;
            case 9140:
                return assets.errormsg.userDepositPage_error_no_dunning_configuration_found;
            case 9141:
                return assets.errormsg.userDepositPage_error_invalid_dunning_configuration;
            case 9142:
                return assets.errormsg.userDepositPage_error_while_storing_debtor;
            case 9143:
                return assets.errormsg.userDepositPage_error_while_storing_invoice;
            case 9144:
                return assets.errormsg.userDepositPage_error_invoice_already_exists_for_creditor_account;
            case 9145:
                return assets.errormsg.userDepositPage_error_while_searching_invoices;
            case 9146:
                return assets.errormsg.userDepositPage_error_no_voice_configuration_config_credit_account;
            case 9147:
                return assets.errormsg.userDepositPage_error_invalid_invoice_config_credit_account;
            case 9148:
                return assets.errormsg.userDepositPage_error_no_method_specified;
            case 9149:
                return assets.errormsg.userDepositPage_error_server_could_not_proecess_request;
            case 9150:
                return assets.errormsg.userDepositPage_error_problem_parsing_request;
            case 9151:
                return assets.errormsg.userDepositPage_error_request_already_processed;
            case 9152:
                return assets.errormsg.userDepositPage_error_contract_not_found;
            case 9153:
                return assets.errormsg.userDepositPage_error_too_many_payment_details_defined;
            case 9154:
                return assets.errormsg.userDepositPage_error_invalid_contract;
            case 9155:
                return assets.errormsg.userDepositPage_error_payment_detail_not_found;
            case 9156:
                return assets.errormsg.userDepositPage_error_failed_disable;
            case 9157:
                return assets.errormsg.userDepositPage_error_recurring_detail_reference_not_available;
            case 9158:
                return assets.errormsg.userDepositPage_error_no_applicable_contract_types_left_for_payment_method;
            case 9159:
                return assets.errormsg.userDepositPage_error_invalid_merchant_account;
            case 9160:
                return assets.errormsg.userDepositPage_error_invalid_empty_request_data;
            case 9162:
                return assets.errormsg.userDepositPage_error_unable_process;
            case 9163:
                return assets.errormsg.userDepositPage_error_payment_details_not_supported;
            case 9164:
                return assets.errormsg.userDepositPage_error_invalid_request_original_psp_reference;
            case 9165:
                return assets.errormsg.userDepositPage_error_us_payment_details_not_supported;
            case 9166:
                return assets.errormsg.userDepositPage_error_invalid_request;
            case 9167:
                return assets.errormsg.userDepositPage_error_tx_variant_not_supported_redemption;
            case 9168:
                return assets.errormsg.userDepositPage_error_invalid_acquirer_account;
            case 9169:
                return assets.errormsg.userDepositPage_error_configuration_error_acquirer_indentification;
            case 9170:
                return assets.errormsg.userDepositPage_error_configuration_error_acquirer_password;
            case 9171:
                return assets.errormsg.userDepositPage_error_configuration_error_api_key;
            case 9172:
                return assets.errormsg.userDepositPage_error_configuration_error_redirect_url;
            case 9173:
                return assets.errormsg.userDepositPage_error_configuration_error_acquirer_account_data;
            case 9174:
                return assets.errormsg.userDepositPage_error_configuration_error_currency_code;
            case 9175:
                return assets.errormsg.userDepositPage_error_configuration_error_terminal_id;
            case 9176:
                return assets.errormsg.userDepositPage_error_configuration_error_serial_number;
            case 9177:
                return assets.errormsg.userDepositPage_error_configuration_error_password;
            case 9178:
                return assets.errormsg.userDepositPage_error_configuration_error_project_id;
            case 9179:
                return assets.errormsg.userDepositPage_error_configuration_error_merchant_category_code;
            case 9180:
                return assets.errormsg.userDepositPage_error_configuration_error_merchant_name;
            case 9181:
                return assets.errormsg.userDepositPage_error_invalid_company_registration_number;
            case 9182:
                return assets.errormsg.userDepositPage_error_invalid_company_name;
            case 9183:
                return assets.errormsg.userDepositPage_error_missing_company_details;
            case 9184:
                return assets.errormsg.userDepositPage_error_configuration_error_private_key_alias;
            case 9185:
                return assets.errormsg.userDepositPage_error_configuration_error_public_key_alias;
            case 9186:
                return assets.errorsmsg.userDepositPage_error_card_number_cannot_specified;
            case 9187:
                return assets.errormsg.userDepositPage_error_recurring_not_allowed_in_control_virtual_card_requests;
            case 9188:
                return assets.errormsg.userDepositPage_error_invalid_authorization_type_supplied;
            case 9189:
                return assets.errormsg.userDepositPage_error_unable_determine_variant;
            case 9190:
                return assets.errormsg.userDepositPage_error_internal_error;
            case 9191:
                return assets.errormsg.userDepositPage_error_missing_payment_data;
            case 9192:
                return assets.errormsg.userDepositPage_error_invalid_payment_data;
            case 9193:
                return assets.errormsg.userDepositPage_error_missing_payment_method_details;
            case 9194:
                return assets.errormsg.userDepositPage_error_invalid_payment_method_details;
            case 9195:
                return assets.errormsg.userDepositPage_error_missing_payment_method_data;
            case 9197:
                return assets.errormsg.userDepositPage_error_session_has_expired;
            case 9198:
                return assets.errormsg.userDepositPage_error_request_contains_no_sdk_verion_although_channel_set_web;
            case 9199:
                return assets.errormsg.userDepositPage_error_provide_correct_token_ios_android_sdk_version_web;
            case 9200:
                return assets.errormsg.userDepositPage_error_provided_sdk_token_could_not_parsed;
            case 9201:
                return assets.errormsg.userDepositPage_error_origin_provided;
            case 9202:
                return assets.errormsg.userDepositPage_error_end_point_requires_version_specified;
            case 9203:
                return assets.errormsg.userDepositPage_error_token_missing;
            case 9204:
                return assets.errormsg.userDepositPage_error_invalid_sdk_version_provided;
            case 9205:
                return assets.errormsg.userDepositPage_error_provided_sdk_token_invalid_timestamp;
            case 9206:
                return assets.errormsg.userDepositPage_error_invalid_payload_provided;
            case 9207:
                return assets.errormsg.userDepositPage_error_request_does_not_contain_sdk_version;
            case 9208:
                return assets.errormsg.userDepositPage_error_no_issuer_selected;
            case 9209:
                return assets.errormsg.userDepositPage_error_unknown_terminal;
            case 9210:
                return assets.errormsg.userDepositPage_error_missing_unique_terminal_id;
            case 9211:
                return assets.errormsg.userDepositPage_error_installments_were_not_configured_setup_request;
            case 9212:
                return assets.errormsg.userDepositPage_error_invalid_open_invoice_request;
            case 9213:
                return assets.errormsg.userDepositPage_error_invalid_open_invoice_request;
            case 9214:
                return assets.errormsg.userDepositPage_error_selected_flow_invalid;
            case 9215:
                return assets.errormsg.userDepositPage_error_provided_return_url_query_string_invalid;
            case 9216:
                return assets.errormsg.userDepositPage_error_3d_auth_data_incomplete_provided_both_pares_md;
            case 1058:
                return assets.errormsg.userDepositPage_error_provide_bonus_id;
            case 1054:
                return assets.errormsg.userDepositPage_error_required_deposit_amount;
            case 1059:
                return assets.errormsg.userDepositPage_error_provide_payment_provider_name;
            case 9819:
                return assets.errormsg.userDepositPage_error_bonus_not_available_given_id;
            case 9816:
                return assets.errormsg.userDepositPage_error_payment_provider_not_eligible_this_bonus;
            case 9817:
                return assets.errormsg.userDepositPage_error_less_minimum_amount_selected_bonus;
            case 9818:
                return assets.errormsg.userDepositPage_error_greater_max_amount_selected_bonus;
            case 2000:
                return assets.errormsg.userDepositPage_error_json_null_malformed;
            case 9563:
                return assets.errormsg.userDepositPage_error_register_with_lugas;
            case 8268:
                return assets.errormsg.userDepositPage_error_required_redirect_url;
            case 9265:
                return assets.errormsg.userDepositPage_error_invalid_payment_method;
            case 9244:
                return assets.errormsg.userDepositPage_error_player_banned_oasis;
            case 9508:
                return assets.errormsg.userDepositPage_error_cooling_period_reached;
            case 9512:
                return assets.errormsg.userDepositPage_error_enabled_panic;
            case 8296:
                return assets.errormsg.userDepositPage_error_acc_banned_contact_support;
            case 9875:
                return assets.errormsg.userDepositPage_error_player_primary_brand_not_matched;
            case 9513:
                return assets.errormsg.userDepositPage_error_xtee_hampi_statut_deposit_not_allowed;
            case 5026:
                return assets.errormsg.userDepositPage_error_required_acc_holder_name;
            case 9910:
                return assets.errormsg.userDepositPage_error_deposit_pending_given_card;
            case 9856:
                return assets.errormsg.userDepositPage_error_player_breached_aml_config_rule;
            case 9497:
                return assets.errormsg.userDepositPage_error_pares_missing;
            case 9498:
                return assets.errormsg.userDepositPage_error_shopper_order_missing;
            case 8262:
                return assets.errormsg.userDepositPage_error_deposit_limit_exceed_for_kyc_level;
            case 9235:
                return assets.errormsg.userDepositPage_error_deposit_exception_msg;
            case 9994:
                return assets.errormsg.userDepositPage_error_customer_account_not_found;
            case 9996:
                return assets.errormsg.userDepositPage_error_first_name_not_found;
            case 9997:
                return assets.errormsg.userDepositPage_error_last_name_not_found;
            case 9998:
                return assets.errormsg.userDepositPage_error_dob_not_found;
        }
    }
}

