import { EventEmitter, Injectable, OnInit, Inject, PLATFORM_ID } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from './local-storage.service';
import { Observable } from 'rxjs';
import { isPlatformBrowser } from '@angular/common';

// Services
import { ApiService } from './api.service';
import { ConfigService } from './config.service';
import { DataService } from './data.service';
import { GeneralService } from './general.service';
import { GoogleAnalitycsService } from './google-analitycs.service';
import { RoutingService } from './routing.service';
import { LanguageService } from './language.service';

// Models
import { PlayerArray } from '../model/player-model';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';

// const WebSocketConstructor = WebSocket;
declare let Stomp: any;
declare let jQuery: any;

@Injectable()

export class UserService {
    public loggedUser!: PlayerArray;
    public playerEmitter: EventEmitter<PlayerArray> = new EventEmitter<PlayerArray>();
    public playerInterval: any;
    public casinoAccessEmitter: EventEmitter<boolean> = new EventEmitter<boolean>();
    public ws: any;
    public stompClient: any;
    public tokenTimeInterval: any;
    public connectionErrorModal: any = null;
    public forceLogoutInterval: any = null;
    public updatePlayerInterval: any = null;
    public webSocketReconnectTimeInterval: any = null;
    public idleTimeoutSub: any = null;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        private dialog: MatDialog,
        private idle: Idle,
        private _apiService: ApiService,
        private _dataService: DataService,
        private _configService: ConfigService,
        private _generalService: GeneralService,
        private _cookieService: CookieService,
        private _localStorageService: LocalStorageService,
        private _routingService: RoutingService,
        private _languageService: LanguageService,
        private _googleAnalitycsService: GoogleAnalitycsService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) { }

    // Login check
    setPlayer(data: PlayerArray) {
        this.loggedUser = data;
        this._localStorageService.set('player', data);
    }

    // setPlayerPersionalDetails
    setPlayerPersionalDetails(data: any) {
        this._localStorageService.set('playerPersionalDetails', data);
    }

    // Get PlayerPersionalDetails data
    getPlayerPersionalDetails(): any {
        return this._localStorageService.get('playerPersionalDetails');
    }

    public notifyUpdatePlayerData(player?: any) {
        let plyaerObj;
        if (player === undefined) {
            plyaerObj = this._localStorageService.get('player');
        } else {
            plyaerObj = player;
        }
        this.playerEmitter.next(plyaerObj);
    }

    // Get player data
    getPlayer(): any {
        return this._localStorageService.get('player');
    }

    // Get player flag
    getPlayerFlag(): any {
        return this._localStorageService.get('playerFlag');
    }

    // setAgentPlayerFlag check
    setAgentPlayerFlag(data: any) {
        this._localStorageService.set('agentPlayerFlag', data);
    }

    // getAgentPlayer check
    getAgentPlayerFlag() {
        return this._localStorageService.get('agentPlayerFlag');
    }

    // setPPPAgentPlayerDetails check
    setPPPAgentPlayerDetails(data: any) {
        this._localStorageService.set('pppAgentPlayerDetails', data);
    }

    // getPPPAgentPlayerDetails check
    getPPPAgentPlayerDetails() {
        return this._localStorageService.get('pppAgentPlayerDetails');
    }

    getPlayerLanguage() {
        let playerDetail: any = this._localStorageService.get('player');
        if (playerDetail) {
            return playerDetail.playerLanguage;
        }
    }

    updatePlayer() {
        this.connectWs();
        this.connectAndReconnectWs(true);
        this.updatePlayerData();
    }

    // Check update player
    updatePlayerCheck(obj: any): Observable<any> | any {
        let apiUrl = 'updatePlayer';
        return this._apiService.request('get', apiUrl, obj, {}, false);
    }

    updatePlayerData() {
        let obj = {};
        let me = this;
        this.updatePlayerCheck(obj).subscribe((data: any) => {
            try {
                if (data.errorCode === 2002) {
                    this._localStorageService.clear();
                    this.deleteCookie('authToken');
                    this._routingService.altRouterLink('home');
                    this._configService.notifyUserLogout();
                }

                if (data.errorCode === 2007) {
                    this._localStorageService.clear();
                    this.deleteCookie('authToken');
                    this._routingService.altRouterLink('home');
                    this._configService.notifyUserLogout();
                }

                if (data.success === true) {
                    // Update User name and Balance
                    let userData = JSON.parse(data.FE_PLAYER_DATA.FE_PLAYER_BOOLEAN_DATA[0]);
                    let playerFlag = JSON.parse(data.FE_PLAYER_DATA.FE_PLAYER_REGULATORY_FLAGS);
                    this._localStorageService.set('playerFlag', playerFlag);

                    let playerObj = this._localStorageService.get('player');
                    let oasisStatus = data.FE_PLAYER_DATA.oasisStatusObj || {};

                    playerObj['totalMoney'] = userData.totalMoney;
                    playerObj['bonusMoney'] = userData.bonusMoney;
                    playerObj['cashMoney'] = userData.cashMoney;
                    playerObj['depositAllowed'] = userData.depositAllowed;
                    playerObj['withdrawalAllowed'] = userData.withdrawalAllowed;
                    playerObj.banStatus = oasisStatus.banStatus;
                    playerObj.isadvertisementAllow = oasisStatus.isadvertisementAllow;
                    playerObj.isbonusAllow = oasisStatus.isbonusAllow;

                    this._localStorageService.set('player', playerObj);
                    let loggedUser = <PlayerArray>{
                        playerId: data.FE_PLAYER_DATA.playerId,
                        userName: userData.userName,
                        cashMoney: userData.cashMoney,
                        bonusMoney: userData.bonusMoney,
                        totalMoney: userData.totalMoney,
                        playerCurrency: playerObj['playerCurrency'],
                        playerEmailVerified: userData.playerEmailVerified
                    };

                    this.updateCasinoAccess(data.FE_PLAYER_DATA.casino_access);
                    this.notifyCasinoAccess(data.FE_PLAYER_DATA.casino_access);

                    if (data.FE_PLAYER_DATA.casino_access === false) {
                        if (this.router.url === this._routingService.altRouterLinkActive('casino')) {
                            this._routingService.altRouterLink('home');
                        }
                    }

                    this.notifyUpdatePlayerData(loggedUser);
                    if (userData.isBan === true) {
                        this._localStorageService.clear();
                        this.deleteCookie('authToken');
                        this._routingService.altRouterLink('home');
                        this._configService.notifyUserLogout();
                    }

                    if (userData.isLocked === true) {
                        this._localStorageService.clear();
                        this.deleteCookie('authToken');
                        this._routingService.altRouterLink('home');
                        this._configService.notifyUserLogout();
                    }

                    if (userData.isForceLogout === true) {
                        this.forceLogoutApiCalled();
                    }
                }
            } catch (e) {
                console.log(e, 'exception caught in updatePlayerData');
            }
        }, (error: any) => {
            if (me.playerInterval) {
                console.log('logout is called clearInterval 22.....');
                if (isPlatformBrowser(this.platformId)) {
                    clearInterval(me.playerInterval);
                }
            }
            this._localStorageService.clear();
            this.deleteCookie('authToken');
            this._routingService.altRouterLink('home');
            this._configService.notifyUserLogout();
        });
    }

    connectWs() {
        let socketUrl = this._configService.getWsConnectionUrl();
        this.ws = new WebSocket(socketUrl, 'stomp');
        this.stompClient = Stomp.over(this.ws);
    }

    private connectAndReconnectWs(isPageReload: boolean = false) {
        let socketUrl = this._configService.getWsConnectionUrl();
        this.ws = new WebSocket(socketUrl, 'stomp');
        this.stompClient = Stomp.over(this.ws);
        this.stompClient.connect({}, (frame: any) => {
            this.stompSuccessCallback();
        }, () => {
            console.log('it is in web socket failed connection')
            let player = this.getPlayer();
            if (player && !isPageReload) {
                this.stompErrorCallback();
            }

            if (player) {
                this.clearSetTimeout(this.webSocketReconnectTimeInterval);
                this.webSocketReconnectTimeInterval = setTimeout(() => {
                    this.connectAndReconnectWs();
                }, 2000);
            }
        });
    }

    stompSuccessCallback() {
        console.log('stompClient connected--');
        let authToken = this._cookieService.get('authToken');
        this.stompClient.subscribe("/queue/" + authToken, (message: any) => {
            console.log('queue message--', message);
            try {
                if (message.body) {
                    let data = JSON.parse(message.body);
                    if (data.status) {
                        let status = data.status;
                        if (status.isForceLogout == true) {
                            this.forceLogoutApiCalled();
                        }

                        if (status.playerForgot == true || status.selfExclusion == true || status.ban == true || status.lock == true) {
                            this._localStorageService.clear();
                            this.deleteCookie('authToken');
                            this._routingService.altRouterLink('home');
                            this._configService.notifyUserLogout();
                        }

                        if (status.withdrawalAllowed == false) {
                            this._routingService.altRouterLink('user/payout');
                        }

                        if (status.AUTOMATIC_WITHDRAW_THRESHOLD) {
                            this.withdrawalThresholdReached(status.AUTOMATIC_WITHDRAW_THRESHOLD);
                        }

                        if (status.depositAllowed == false) {
                            this._routingService.altRouterLink('user/deposit');
                        }

                        if (status.casino_access == true) {
                            this.updateCasinoAccess(data.FE_PLAYER_DATA.casino_access);
                            this.notifyCasinoAccess(data.FE_PLAYER_DATA.casino_access);
                        } else if (status.casino_access == false) {
                            if (this.router.url === this._routingService.altRouterLinkActive('casino')) {
                                this._routingService.altRouterLink('home');
                            }
                        }

                        for (let key in status) {
                            let val = status[key];

                            // update playerflag param
                            let getPlayerFlag = this._localStorageService.get('playerFlag');
                            if (getPlayerFlag[key] || getPlayerFlag[key] == true || getPlayerFlag[key] == false) {
                                getPlayerFlag[key] = val;
                                this._localStorageService.set('playerFlag', getPlayerFlag);
                            }

                            // update player details param
                            let playerDetails = this._localStorageService.get('player');
                            if (playerDetails[key] || playerDetails[key] == true || playerDetails[key] == false) {
                                playerDetails[key] = val;
                                this._localStorageService.set('player', playerDetails);
                            }
                        }
                        this.notifyUpdatePlayerData();
                    } else if (data.RealityCheck) {
                        let param = data.RealityCheck;
                        if (param.isRealityReached) {
                            param['isRealityRemaining'] = true;
                        }
                        this.dialog.closeAll();
                        this._routingService.altRouterLink('home');
                        this._generalService.infomodel('', '', '', '', 'realityCheckModal', param);
                    } else if (data.PXLStatus) {
                        this.getPXLStatus(data.PXLStatus);
                    }
                }
            } catch (err) {

            }
        }, {});
        this.stompClient.debug = null;
    }

    stompErrorCallback() {
        // if(this.connectionErrorModal) {
        //     this.connectionErrorModal.close();
        // }
        // setTimeout(() => {
        //     this.connectionErrorModal = this._generalService.infomodel('Connection to server lost', 'Please wait or try again later', 'Retry');
        //     this.connectionErrorModal.afterClosed().subscribe(result => {
        //             this.connectAndReconnectWs();
        //     });
        // }, 5000);
    }

    disconnectWs() {
        if (this.ws) {
            console.log('socket disconnected--');
            this.ws.close();
        }
    }

    forceLogoutApiCalled() {
        this.forceLogout().subscribe(() => {
            this._localStorageService.clear();
            this.deleteCookie('authToken');
            this._routingService.altRouterLink('home');
            this._configService.notifyUserLogout();
        }, () => {
            console.log('updatePlayerForceLogout failure...');
        });
    }

    updateCasinoAccess(val: any) {
        let userDetails = <PlayerArray>this._localStorageService.get('player');
        userDetails['casino_access'] = val;
        this._localStorageService.set('player', userDetails);
    }

    notifyCasinoAccess(access: any) {
        this.casinoAccessEmitter.next(access);
    }

    // Player force logout
    forceLogout(): Observable<any> | any {
        let apiUrl = 'logout';
        return this._apiService.request('post', apiUrl, '', {}, true);
    }

    // Player logout
    userLogOut(param: any = {}): Observable<any> | any {
        let apiUrl = 'logout';
        return this._apiService.request('post', apiUrl, param, {}, true);
    }

    //confrim email
    confrimEmail(data: any): Observable<any> | any {
        let apiUrl = 'confirmEmail-v1';
        return this._apiService.request('post', apiUrl, data, {}, false);
    }

    refreshTokenApi(data: any): Observable<any> | any {
        let apiUrl = 'token/refreshToken';
        return this._apiService.request('get', apiUrl, {}, {}, true);
    }

    playerDepositDeatailsApi(): Observable<any> | any {
        let apiUrl = 'playerProfile/playerDepositDeatails';
        return this._apiService.request('get', apiUrl, {}, {}, false);
    }

    depositUpdateStatusAnaliticApi(params: any): Observable<any> | any {
        let apiUrl = 'playerProfile/playerDepositDeatails';
        return this._apiService.request('get', apiUrl, {}, params, false);
    }

    getAgentSystemDetailsApi(): Observable<any> | any {
        let apiUrl = 'v2/profile/getPartnerInfo';
        return this._apiService.request('get', apiUrl, {}, {}, false);
    }

    getshopDetailsApi(): Observable<any> | any {
        let apiUrl = 'v2/profile/getshopDetails';
        return this._apiService.request('get', apiUrl, {}, {}, true);
    }

    withdrawalThresholdReached(amount: any) {
        this._generalService.openWithadrawalThresholdModal(amount);
    }

    // logout
    logout(reason: any = 'Player clicked on the logout button and signed-out out of the frontend.') {
        let param = {
            "reason": reason
        }
        this.userLogOut(param).subscribe(() => {
            this.clearLocalStorage();
            let interval = this.getInterval();
            if (interval) {
                if (isPlatformBrowser(this.platformId)) {
                    clearInterval(interval);
                }
            }
            this._localStorageService.set('iFrameLogin', 2);
            this._configService.notifyUserLogout();
            this._dataService.setLoginState(false);
            this._routingService.altRouterLink('home');
            if (isPlatformBrowser(this.platformId)) {
                jQuery('body').removeClass('overflow-cls');
            }
        }, (err: any) => {
            if ((err.error && err.errorCode == 2002) || err == 'Server error') {
                this.clearLocalStorage();
                let interval = this.getInterval();
                if (interval) {
                    if (isPlatformBrowser(this.platformId)) {
                        clearInterval(interval);
                    }
                }
                this._localStorageService.set('iFrameLogin', 2);
                this._configService.notifyUserLogout();
                this._dataService.setLoginState(false);
                this._routingService.altRouterLink('home');
                if (isPlatformBrowser(this.platformId)) {
                    jQuery('body').removeClass('overflow-cls');
                }
            }
        });
    }

    checkAccess() {
        if (this.checkLoginUser() === false) {
            this._routingService.altRouterLink('home');
        }
    }

    // Check login
    checkLoginUser() {
        let islogged = false;
        let userDetails = this._localStorageService.get('player');
        if (userDetails !== null && userDetails !== undefined) {
            islogged = true;
        } else {
            islogged = false;
        }
        return islogged;
    }

    getPlayerCurrency() {
        let playerCurreny = this._dataService.getCountryCurrencyCode();
        let playerData = this.getPlayer();
        if (playerData) {
            playerCurreny = playerData.playerCurrency;
        }

        let ret = { currencyCode: 'EUR', currencySign: '€' };
        if (playerCurreny == 'USD') {
            ret = { currencyCode: 'USD', currencySign: '$' };
        } else if (playerCurreny == 'GBP') {
            ret = { currencyCode: 'GBP', currencySign: '£' };
        }
        return ret;
    }

    setPlayerLanguage(val: any) {
        let getLang = this._localStorageService.get('language');
        this._localStorageService.set('language', val);
        if (getLang != val) {
            this._languageService.loadLanguageData();
        }
    }

    // Remove player data local storage
    clearLocalStorage() {
        this._localStorageService.remove('sportBookHash');
        this._localStorageService.remove('player');
        this._localStorageService.remove('playerPersionalDetails');
        this._localStorageService.remove('playerFlag');
        this._localStorageService.remove('agentPlayerFlag');
        this._localStorageService.remove('pppAgentPlayerDetails');
        this._localStorageService.remove('loginTime');
        this.deleteCookie('authToken');
    }

    getInterval() {
        return this.playerInterval;
    }

    public setIsSiteBlocked(val: any) {
        this._localStorageService.set('isSiteBlocked', val);
    }

    public getIsSiteBlocked() {
        return JSON.parse(this._localStorageService.get('isSiteBlocked'));
    }

    // Check Site Accessibility
    checkSiteAccessibility(): Observable<any> | any {
        let apiUrl = 'checkSiteAccessibility';
        return this._apiService.request('get', apiUrl, '', '', false, false);
    }

    public getCheckSiteAccessibility(assets: any) {
        this.checkSiteAccessibility().subscribe((res: any) => {
            let data = JSON.parse(res.message);
            this.setIsSiteBlocked(data.siteBlocked);
            if (data.siteBlocked) {
                this._generalService.infomodel(assets.errormsg.headerPage_error_common_modal_site_access_title, assets.errormsg.headerPage_error_common_modal_site_access_msg, assets.errormsg.headerPage_common_ok_btn);
            }
        }, (err: any) => {
            let data = JSON.parse(err.message);
            this.setIsSiteBlocked(data.siteBlocked);
            if (data.siteBlocked) {
                this._generalService.infomodel(assets.errormsg.headerPage_error_common_modal_site_access_title, assets.errormsg.headerPage_error_common_modal_site_access_msg, assets.errormsg.headerPage_common_ok_btn);
            }

        });
    }

    public getPXLStatus(res: any) {
        let obj = res || {};
        let registerPageAssets: any = this._dataService.getAssets('registerPage') || {};
        this.dialog.closeAll();
        if (obj.success) {
            let message = registerPageAssets.errormsg.registerPageAssets_pxl_success_msg;
            if (obj.errorCode == 16001 || obj.errorCode == '16001') {
                message = registerPageAssets.errormsg.registerPageAssets_verification_passport_successfull_upload_utility_bill_error_msg;
            }
            this._googleAnalitycsService.trackingUserAction('event', 'verification_success');
            this._googleAnalitycsService.trackingUserAction('event', 'confirm_id');
            let infodModal = this._generalService.infomodel(registerPageAssets.errormsg.registerPageAssets_success_modal_title, message, registerPageAssets.errormsg.registerPage_modal_ok_btn);
            infodModal.afterClosed().subscribe(result => {
                if (obj.errorCode == 16001 || obj.errorCode == '16001') {
                    this.dialog.closeAll();
                    this._routingService.altRouterLink('user/documents');
                }
            });
        } else {
            let errorMessage = registerPageAssets.errormsg.registerPageAssets_pxl_error_msg;
            if (obj.errorCode) {
                let keyName = 'registerPageAssets_pxl_error_msg_' + obj.errorCode;
                errorMessage = registerPageAssets.errormsg[keyName];
            }

            if (!errorMessage) {
                errorMessage = registerPageAssets.errormsg.registerPageAssets_pxl_error_msg;
            }
            this._generalService.infomodel(registerPageAssets.errormsg.registerPage_error_modal_title, errorMessage, registerPageAssets.errormsg.registerPage_modal_ok_btn);
            let eventParam = {
                errorCode: obj.errorCode,
                errorMessage: errorMessage
            }
            this._googleAnalitycsService.trackingUserAction('event', 'verification_error', eventParam);
        }
    }

    deleteCookie(val: any) {
        let protocol = window.location.protocol;
        if (protocol.includes('https')) {
            this._cookieService.delete(val, '/', '', true);
        } else {
            this._cookieService.delete(val, '/');
        }
    }

    public setAuthToken(token: any) {
        this.deleteCookie('authToken');
        let protocol = window.location.protocol;
        if (protocol.includes('https')) {
            this._cookieService.set('authToken', token, 1000, '/', '', true);
        } else {
            this._cookieService.set('authToken', token, 1000, '/');
        }
    }

    public setAutoCompleteAuthToken(token: any) {
        this.deleteCookie('autoCompleteAuthToken');
        let protocol = window.location.protocol;
        if (protocol.includes('https')) {
            this._cookieService.set('autoCompleteAuthToken', token, 1000, '/', '', true);
        } else {
            this._cookieService.set('autoCompleteAuthToken', token, 1000, '/');
        }
    }

    checkTokenExpiryTime() {
        this.refreshAuthToken();
    }

    clearTokenTimeInterval() {
        if (this.tokenTimeInterval) {
            if (isPlatformBrowser(this.platformId)) {
                clearInterval(this.tokenTimeInterval);
            }
        }
    }

    refreshAuthToken() {
        if (this.checkLoginUser() === false) {
            this.clearTokenTimeInterval();
            return;
        }

        this.clearTokenTimeInterval();
        this.clearUpdatePlayerInterval()
        this.refreshTokenApi({}).subscribe((res: any) => {
            let data = res.message || {};
            if (res.success) {
                this.setAuthToken(data.refreshToken);
                this.connectAndReconnectWs();
                let currentDate = moment(new Date());
                let exDate = moment(data.expiredDate * 1000);
                let diff = exDate.diff(currentDate, 'seconds');
                // this.loadUpdatePlayerInterval();
                if (isPlatformBrowser(this.platformId)) {
                    this.tokenTimeInterval = setInterval((event: any) => {
                        this.checkTokenExpiryTime();
                        this.clearTokenTimeInterval();
                    }, ((diff * 1000) - 60000));
                }
            }
        }, (err: any) => {
            console.log('err======', err);
        });
    }

    loadUpdatePlayerInterval() {
        this.clearUpdatePlayerInterval();
        if (isPlatformBrowser(this.platformId)) {
            this.updatePlayerInterval = setInterval(() => {
                this.updatePlayerData();
            }, 5000);
        }
    }

    clearUpdatePlayerInterval() {
        if (this.updatePlayerInterval) {
            if (isPlatformBrowser(this.platformId)) {
                clearInterval(this.updatePlayerInterval);
            }
        }
    }

    clearSetTimeout(val: any) {
        if (val) {
            clearTimeout(val);
        }
    }

    // after 15 min idle user logout and show message
    setIdlState() {
        this.idle.setIdle(300);
        this.idle.setTimeout(1500);
        this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

        this.idleTimeoutSub = this.idle.onTimeout.subscribe(() => {
            console.log('idle timeout called');
            if (this.checkLoginUser()) {
                this.logout('The player was logged out due to the idle time interval being reached, as the player did not perform any activity on the frontend.');
                setTimeout(() => {
                    this.dialog.closeAll();
                    let headerPageAssets: any = this._dataService.getAssets('headerPage') || {};
                    this._generalService.infomodel(
                        headerPageAssets.errormsg.headerPage_error_session_expired_title,
                        headerPageAssets.errormsg.headerPage_error_session_expired_msg,
                        headerPageAssets.errormsg.headerPage_common_ok_btn
                    );
                }, 1000);
                this.idleTimeoutSub.unsubscribe();
            }
        });

        this.idle.onIdleStart.subscribe(() => {
            console.log("You've gone idle!");
        });

        this.idle.watch();
    }

    resetIdleTime() {
        this.idle.watch();
    }

    clearIdleTime() {
        this.idle.ngOnDestroy();
        this.idle.stop();
        this.idle.onTimeout.observers.length = 0;
        this.idle.onIdleStart.observers.length = 0;
        this.idle.onIdleEnd.observers.length = 0;
    }

    checkForceLogout24Hrs() {
        let lastLoginTime = this._localStorageService.get('loginTime');
        if (!lastLoginTime) {
            return;
        }

        let currentDate = moment(new Date());
        let exDate = moment(new Date(lastLoginTime));
        let diff = currentDate.diff(exDate, 'minutes');
        let remaingMinute = 1440 - diff;
        if (this.forceLogoutInterval) {
            if (isPlatformBrowser(this.platformId)) {
                clearInterval(this.forceLogoutInterval);
            }
        }
        this.forceLogoutInterval = setInterval((event: any) => {
            if (isPlatformBrowser(this.platformId)) {
                clearInterval(this.forceLogoutInterval);
            }
            const logged24hrsModal = this._generalService.infomodel('', '', '', '', '24hrsLogoutInfoModal');
            logged24hrsModal.afterClosed().subscribe((result: any) => {
                this.logout();
            });
        }, ((remaingMinute * 60) * 1000));
    }

    public currecnySymbol(currency: any): any {
        if (currency == 'EUR') {
            return '€';
        }
        if (currency == 'TND') {
            return 'DTN';
        }
        if (currency == 'BRL') {
            return 'R$';
        }
    }
}
