import { Component, OnInit, AfterViewInit, OnDestroy, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { FileUploader } from 'ng2-file-upload';
import { LocalStorageService } from 'src/app/services/local-storage.service';

// Services
import { DocumentService } from 'src/app/services/document.service';
import { AmlService } from 'src/app/services/aml.service';
import { DataService } from 'src/app/services/data.service';
import { SeoService } from 'src/app/services/seo.service';

const URL = 'http://localhost:3000/fileupload/';

@Component({
    selector: 'app-aml-kyc-modal',
    templateUrl: './aml-kyc-modal.component.html',
    styleUrls: ['./aml-kyc-modal.component.scss']
})

export class AmlKycModalComponent implements OnInit, AfterViewInit, OnDestroy {
    // Public Var
    public documentsForm!: FormGroup;
    public modalData;
    public submitted = false;
    public docNameAssArray = <any>{};
    public idDocumentList: any[] = [];
    public addressDocumentList: any[] = [];
    public uploadedIdDocumentList: any[] = [];
    public uploadedAddressDocumentList: any[] = [];
    public idProofUploadArray: any[] = [];
    public idProofUploaderErrors: boolean = false;
    public idProofUploader: FileUploader = new FileUploader({
        url: URL,
        disableMultipart: true,
        autoUpload: false,
        method: 'post',
        itemAlias: 'attachment',
        allowedMimeType: ['application/pdf', 'image/bmp', 'image/gif', 'image/png', 'image/jpg', 'image/jpeg'],
        maxFileSize: 5 * 1024 * 1024,
        queueLimit: 2
    });
    public addressProofUploadArray: any[] = [];
    public addressProofUploaderErrors: boolean = false;
    public addressProofUploader: FileUploader = new FileUploader({
        url: URL,
        disableMultipart: true,
        autoUpload: false,
        method: 'post',
        itemAlias: 'attachment',
        allowedMimeType: ['application/pdf', 'image/bmp', 'image/gif', 'image/png', 'image/jpg', 'image/jpeg'],
        maxFileSize: 5 * 1024 * 1024,
        queueLimit: 2
    });
    public serverErrorMsg: string = '';
    public serverErrorCode: string = '';
    public docUploadErrorMsg: string = '';
    public amlKycPageAssets: any;

    // Private Var
    private docAssDetailsPerCountryService: any = null;
    private playerUploadedDocListService: any = null;
    private saveDocDataService: any = null;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public dialogRef: MatDialogRef<AmlKycModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private _documentService: DocumentService,
        private _amlService: AmlService,
        private formBuilder: FormBuilder,
        private _dataService: DataService,
        private _localStorageService: LocalStorageService,
        private _seoService: SeoService
    ) {
        this.modalData = data;
    }

    ngOnInit() {
        this.amlKycPageAssets = this._dataService.getAssets('documentUploadAmlKycPage');
        this._seoService.updateMetaTag(this.amlKycPageAssets, 'documentUploadAmlKycPage');
        this.loadFrmInit();
        this.formFiledOnChange('idPOI', 'idDocMasterCode');
        this.formFiledOnChange('addressPOI', 'addressDocMasterCode');
        this.loadDocAssDetailsPerCountry();
        this.loadPlayerUploadedDocumentList();
        this.uploaderInit('idProofUploader', 'idProofUploadArray');
        this.uploaderInit('addressProofUploader', 'addressProofUploadArray');
    }

    ngAfterViewInit() { }

    loadFrmInit() {
        let playerFlag: any = this._localStorageService.get('playerFlag');
        let profession = '', pepFlag = '';
        if(playerFlag && playerFlag.profession) {
            profession = playerFlag.profession; 
        }
        
        if(playerFlag && (playerFlag.pepFlag == false || playerFlag.pepFlag == true)) {
            pepFlag = playerFlag.pepFlag; 
        }

        this.documentsForm = this.formBuilder.group({
            idPOI: [''],
            addressPOI: [''],
            pepFlag: [pepFlag, Validators.required],
            profession: [profession, Validators.required],
            idDocMasterCode: ['', Validators.required],
            addressDocMasterCode: ['', Validators.required]
        });
    }

    formFiledOnChange(filed1: any, filed2: any) {
        this.documentsForm.get(filed1)!.valueChanges.subscribe(val => {
            this.serverErrorMsg = '';
            if (!val) {
                this.documentsForm.get(filed2)!.setValidators([Validators.required]);
            } else {
                this.documentsForm.get(filed2)!.setValidators([]);
            }
            this.documentsForm.get(filed2)!.updateValueAndValidity();
        });
    }

    uploaderInit(arrName: any, arrName2: any) {
        this[arrName].onWhenAddingFileFailed = (item: any, filter: any) => {
            this[arrName + 'Errors'] = true;
            switch (filter.name) {
                case 'queueLimit':
                    this.docUploadErrorMsg = this.amlKycPageAssets.errormsg.amlKycPage_error_docUpload_limit_exist;
                    break;
                case 'fileSize':
                    this.docUploadErrorMsg = this.amlKycPageAssets.errormsg.amlKycPage_error_docUpload_file_size_exist;
                    break;
                case 'mimeType':
                    this.docUploadErrorMsg = this.amlKycPageAssets.errormsg.amlKycPage_error_docUpload_file_invalid;
                    break;
            }
        }

        this[arrName].onBeforeUploadItem = (item: any, filter: any) => {
            this[arrName + 'Errors'] = false;
        }

        this[arrName].onAfterAddingAll = (item: any) => {
            for (let i = 0; i < item.length; i++) {
                this.readBase64(item[i]._file, arrName2);
            }
        }
    }

    readBase64(file: any, arrName: any) {
        let reader = new FileReader();
        reader.onload = (event: any) => {
            if (!this[arrName]) {
                this[arrName] = [];
            }

            this[arrName].push({
                'imageFileType': file.type,
                'imageStringData': btoa(event.target.result),
                'newImageName': file.name,
                'imageFileSize': file.size
            });
        }
        reader.readAsBinaryString(file);
    }

    removeDocFromList(index: any, arrName: any) {
        this[arrName + 'Errors'] = false;
        this.serverErrorCode = '';
        this.serverErrorMsg = '';
        this[arrName].splice(index, 1);
    }

    loadDocAssDetailsPerCountry() {
        this.docAssDetailsPerCountryService = this._documentService.getDocAssDetailsPerCountry('EE').subscribe((res: any) => {
            try {
                let data = res || {};
                let docTypePerCountry = JSON.parse(data.message);
                let docTypeDetailArray = docTypePerCountry.DOC_ASSOCIATION_DETAILS;
                this.docNameAssArray = this._documentService.getDocTypeNameAssArray(docTypeDetailArray);
                docTypeDetailArray.map((item: any) => {
                    if (item.docCategoryCode == 'id') {
                        this.idDocumentList.push(item);
                    } else if (item.docCategoryCode == 'address') {
                        this.addressDocumentList.push(item);
                    }
                });
            } catch (e) {
                console.log('exception caught in loadDocAssDetailsPerCountry', e);
            }
        }, (error: any) => {
            console.log(error);
        });
    }

    loadPlayerUploadedDocumentList() {
        this.getDocUploadedList(1);
    }

    getDocUploadedList(offset: any) {
        let params = {
            'perPage': 1000,
            'offset': 0
        };
        this.playerUploadedDocListService = this._documentService.getPlayerUploadedDocumentList(params).subscribe((res: any) => {
            try {
                let data = res || {};
                let docUploadedListArray = JSON.parse(data.message);
                let docListArray = docUploadedListArray.docList;
                docListArray.map((item: any) => {
                    if (item.docCategory == 'id') {
                        this.uploadedIdDocumentList.push(item);
                    } else if (item.docCategory == 'address') {
                        this.uploadedAddressDocumentList.push(item);
                    }
                });
            } catch (e) {
                console.log('exception caught in loadDocAssDetailsPerCountry', e);
            }
        }, (error: any) => {
            console.log(error);
        });
    }

    changeDocumentList(event: any, arrName: any, idDocArr: any) {
        let val = event.target.value;
        if(val && this[idDocArr]) {
            this[idDocArr].queue = [];
            this[arrName] = [];
        }
    }

    get dFrm() { return this.documentsForm.controls; }

    onAmlDocSubmit() {
        this.submitted = true;
        this.serverErrorMsg = '';
        if (this.documentsForm.invalid) {
            return;
        }

        this.idProofUploadArray.map((val) => {
            val['docMasterCode'] = this.documentsForm.value.idDocMasterCode;
            return val;
        });

        this.addressProofUploadArray.map((val) => {
            val['docMasterCode'] = this.documentsForm.value.addressDocMasterCode;
            return val;
        })

        let newDocArr = this.idProofUploadArray.concat(this.addressProofUploadArray);
        let saveDocData = {
            'uploadDocListFlag': true,
            'pepFlag': this.documentsForm.value.pepFlag,
            'profession': this.documentsForm.value.profession,
            uploadDocumentsList: newDocArr
        } as any;

        if(this.documentsForm.value.idPOI && this.documentsForm.value.addressPOI) {
            saveDocData['uploadDocListFlag'] = false;
            delete saveDocData['documentList'];
        }
        this.saveUploadPlayerDocumentData(saveDocData);
    }

    saveUploadPlayerDocumentData(docData: any) {
        this.saveDocDataService = this._amlService.uploadDocumentAML(docData).subscribe((res: any) => {
            try {
                let data = res || {};
                let docUploadRes = JSON.parse(data.message);
                this.modalClose();
            } catch (e) {
                console.log('exception caught in saveUploadPlayerDocumentData', e);
            }
        }, (error: any) => {
            this.serverErrorCode = error['errorCode'];
            this.serverErrorMsg = this._amlService.uploadDocErrorCode(this.serverErrorCode, this.amlKycPageAssets);
        });
    }

    modalClose() {
        this.dialogRef.close(this.modalData);
    }

    ngOnDestroy() {
        if (this.docAssDetailsPerCountryService) {
            this.docAssDetailsPerCountryService.unsubscribe();
        }
        if (this.saveDocDataService) {
            this.saveDocDataService.unsubscribe();
        }
        if (this.playerUploadedDocListService) {
            this.playerUploadedDocListService.unsubscribe();
        }
    }
}

