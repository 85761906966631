import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDialogModule } from '@angular/material/dialog';

import { MainHeaderComponent } from './main-header/main-header.component';
import { SharedModule } from '../../services/shared.module';


@NgModule({
    imports: [
        FormsModule,
        CommonModule,
        SharedModule,
        MatDialogModule
    ],
    declarations: [
        MainHeaderComponent
    ],
    exports: [
        MainHeaderComponent
    ]
})

export class HeaderModule { }
