import { Injectable, EventEmitter } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';

// Services
import { ConfigService } from './config.service';
import { ApiService } from './api.service';

declare let jQuery: any;

@Injectable()
export class DepositService {
	public depositStepsEmitter: EventEmitter<any> = new EventEmitter();
	public withdrawStepsEmitter = new BehaviorSubject<boolean>(false);

	constructor(
		private _configService: ConfigService,
		private _apiService: ApiService
	) { }

	getPaymentMethod(assets: any) {
		let paymentMethod = this._configService.getAllowedPaymentMethod();
		let methodArr = [];

		for (let i in paymentMethod) {
			let methodName = paymentMethod[i];
			let tmpObj = {};
			if (methodName == 'ADYEN') {
				tmpObj = {
					title: 'ADYEN',
					className: 'adyen'
				};
			}
			methodArr.push(tmpObj);
		}
		return methodArr;
	}

	// GET Payment Provider MinMax Amount
	getDepositMinMaxAmount(): Observable<any> | any {
		const apiUrl = 'paymentProviderMinAndMax';
		return this._apiService.request('get', apiUrl, {}, {}, true);
	}
}
