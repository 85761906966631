import { Injectable } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { GamesService } from './games.service';
import { LocalStorageService } from './local-storage.service';

declare let window: any;

@Injectable()
export class GoogleAnalitycsService {
    constructor(
        private titleService: Title,
        private router: Router,
        private gamesService: GamesService,
        private _localStorageService: LocalStorageService
    ) { }

    public trackingUserAction(key: string = '', val: string = '', keyObj: any = {}) {
        const currentUrl = this.router.url;
        const player: any = this._localStorageService.get('player') || {};
        const playerFlag: any = this._localStorageService.get('playerFlag') || {};
        const gameData: any = this.gamesService.getGamePlayData() || {};
        const obj = {
            pagePath: currentUrl,
            pageTitle: document.title,
            userID: player.playerId || undefined,
            userBalance: parseInt(player.totalMoney) || undefined,
            userBalanceBonus: parseInt(player.bonusMoney) || undefined,
            userBalanceReal: parseInt(player.cashMoney) || undefined,
            userType: undefined,
            // newsletterAcceptance: playerFlag.allowNewsOffers || undefined,
            depositLimit: undefined,
            transactionID: undefined,
            product: undefined,
            paymentProvider: undefined,
            // gameName: gameData.displayName || undefined,
            // gameProvider: gameData.providerName || undefined,
            errorCode: undefined,
            errorMessage: undefined,
            event: 'vPageview'
        } as any;

        if (key) {
            obj[key] = val;
        }

        for (const i in keyObj) {
            obj[i] = keyObj[i];
        }

        setTimeout(() => {
            obj.pageTitle = this.titleService.getTitle();
            if(window.dataLayer) {
                window.dataLayer.push(obj);
            }
        }, 500);
    }
}
