import {Injectable, EventEmitter} from '@angular/core';
import {Observable} from 'rxjs';

// Services
import { ConfigService } from './config.service';
import { LocalStorageService } from './local-storage.service';
import { ApiService } from './api.service';
import { DataService } from './data.service';
import { RoutingService } from './routing.service';
// import { AppService } from './app.service';

@Injectable()

export class LanguageService {
    public langChangeEvent: EventEmitter<string> = new EventEmitter();
    public changeLangSuceess: boolean = false;

    constructor(
        private _configService: ConfigService,
        private _apiService: ApiService,
        private _dataService: DataService,
        private _localStorageService: LocalStorageService,
        private _routingService: RoutingService,
        // private _appService: AppService
    ) {
    }

    // language Change API
    languageChange(data: any): Observable<any> | any {
        let apiUrl = 'languageChange';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    setSelectedLanguage(val: any) {
        this._localStorageService.set('language', val);
    }

    // This method return lang code
    getSelectedLanguage(): string {
        let lang: any;
        lang = this._localStorageService.get('language');
        if (!lang) {
            lang = this._configService.getClientDefaultLang();
            this.setSelectedLanguage(lang);
        }
        return lang;
    }

    loadLanguageData(isChangelang: boolean = false) {
        const langCode = this.getSelectedLanguage();
        const params = {
            languageCode: langCode
        };

        this.languageChange(params).subscribe((res: any) => {
            try {
                const message = res.message || '';
                // let data = JSON.parse(message);
                // console.log('change lang data---', data)
                if (isChangelang) {
                    this._routingService.altRouterLink('home');
                } else {
                    this.setChangeLangSuceessOnPageReload(true);
                }
                this._dataService.loadInitApiData(message);
            } catch (e) {
                console.log('exception caught in loadLanguageData');
            }
        }, (err: any) => {
            console.log('err in loadLanguageData', err);
        });
    }

    setChangeLangSuceessOnPageReload(val: any) {
        this.changeLangSuceess = val;
    }

    getChangeLangSuceessOnPageReload() {
        return this.changeLangSuceess;
    }
}
