import { Injectable, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

declare let window: any;
declare let jQuery: any;

@Injectable()

export class ConfigService {
  public loginEmitter: EventEmitter<any> = new EventEmitter();
  public navEmitter: EventEmitter<any> = new EventEmitter();
  public subscriberLoaderEmitter: EventEmitter<any> = new EventEmitter();
  public logoutFromUpdatePlayerEventEmitter: EventEmitter<any> = new EventEmitter();
  public constants: any = {};
  public env: any;

  constructor(
    private _localStorageService: LocalStorageService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    let url = window.location.href;
    if ((url.includes('staging')) || (url.includes('localhost'))) {
      this.env = 'staging';
    } else if ((url.includes('die-spielbank.alteatec.dev')) || (url.includes('sachsen.alteatec.dev'))) {
      this.env = 'dev';
    } else {
      this.env = 'production';
    }
    this.env = 'production';
    this._localStorageService.set('clientEnv', this.env);
    this.constants = this.getConstants();
  }

  getClient() {
    let isClient;
    //alteatec-AGENT
    if (window.location.hostname.indexOf('staging') !== -1) {
      isClient = 'spielbank';
    } else {
      isClient = 'spielbank';
    }
    return isClient;
  }

  getConstants() {
    let client = this.getClient();
    this._localStorageService.set('isClient', client);
    let DEFAULT_LANG, APIURL, DOMAIN_NAME, GMAE1X2URL, GMAEMERKURURL,
      SKRILL_SERVICE_CODE, NETELLER_SERVICE_CODE,
      MERCHANT_ID, MERCHANT_GUID, GAME_PROVIDERS, ALLOWED_PAYMENT_METHOD,
      GAMECLIENT_PARAMS_URL, ALLOWED_TOURNAMENT, AMQ_WS_URL, ALLOWED_LIVE_CASINO, MENTORIFRAMEURL,
      GERMANAUTOCOMPLETEAPIURL, PRERANDER_TOKEN, PRERANDER_APIENDPOINT;

    let url = window.location.href;
    let dArr = url.split("/");
    let domain_url = dArr[0] + "//" + dArr[2];

    if (this.env === 'staging') {
      switch (client) {
        /** spielbank Staging **/
        case "spielbank":
          APIURL = 'https://staging.die-spielbank.de/alt/api/';
          DOMAIN_NAME = domain_url;
          GMAE1X2URL = 'https://1x2networkhubstage.com/f1x2games/loadGame.jsp';
          GMAEMERKURURL = 'https://du201-i.edictmaltaservices.com.mt/gamestart.html';
          SKRILL_SERVICE_CODE = 5;
          NETELLER_SERVICE_CODE = 19;
          MERCHANT_ID = 5000382;
          GAME_PROVIDERS = ['Greentube', 'Relax', 'OryxGaming', 'Merkur', 'Pragmatic', 'Playngo'];
          ALLOWED_PAYMENT_METHOD = ['ADYEN']; //LPS, EPG, ADYEN
          MERCHANT_GUID = '054FB896-1CBD-FF84-22FC-B36819CA5FF1';
          DEFAULT_LANG = 'de';
          ALLOWED_TOURNAMENT = false;
          ALLOWED_LIVE_CASINO = true;
          GAMECLIENT_PARAMS_URL = 'https://nrgs-b2b-cstg.greentube.com/Nrgs/B2B/Web/NovolineDE/V5/OpenURL';
          AMQ_WS_URL = 'wss://staging.die-spielbank.de/amq/';
          MENTORIFRAMEURL = 'https://pic.mentor-stage.neccton.com/';
          GERMANAUTOCOMPLETEAPIURL = 'https://autocomplete2.postdirekt.de/autocomplete2/';
          PRERANDER_TOKEN = '9LngJOu1FiLdoKtsBs7X';
          PRERANDER_APIENDPOINT = 'https://api.prerender.io/';
          break;
      }
    } else if (this.env === 'dev') {
      switch (client) {
        /** spielbank Staging **/
        case "spielbank":
          APIURL = 'https://die-spielbank.alteatec.dev/alt/api/';
          DOMAIN_NAME = domain_url;
          GMAE1X2URL = 'https://1x2networkhubstage.com/f1x2games/loadGame.jsp';
          GMAEMERKURURL = 'https://du201-i.edictmaltaservices.com.mt/gamestart.html';
          SKRILL_SERVICE_CODE = 5;
          NETELLER_SERVICE_CODE = 19;
          MERCHANT_ID = 5000382;
          GAME_PROVIDERS = ['Greentube', 'Relax', 'OryxGaming', 'Merkur', 'Pragmatic', 'Playngo'];
          ALLOWED_PAYMENT_METHOD = ['ADYEN']; //LPS, EPG, ADYEN
          MERCHANT_GUID = '054FB896-1CBD-FF84-22FC-B36819CA5FF1';
          DEFAULT_LANG = 'de';
          ALLOWED_TOURNAMENT = false;
          ALLOWED_LIVE_CASINO = true;
          GAMECLIENT_PARAMS_URL = 'https://nrgs-b2b-cstg.greentube.com/Nrgs/B2B/Web/NovolineDE/V5/OpenURL';
          AMQ_WS_URL = 'wss://amq-private-wss.dev.svc.cluster.local:61615/';
          MENTORIFRAMEURL = 'https://pic.mentor-stage.neccton.com/';
          GERMANAUTOCOMPLETEAPIURL = 'https://autocomplete2.postdirekt.de/autocomplete2/';
          PRERANDER_TOKEN = '9LngJOu1FiLdoKtsBs7X';
          PRERANDER_APIENDPOINT = 'https://api.prerender.io/';
          break;
      }
    } else if (this.env === 'production') {
      switch (client) {
        /** spielbank Live **/
        case "spielbank":
          APIURL = 'https://www.die-spielbank.de/alt/api/';
          DOMAIN_NAME = domain_url;
          GMAE1X2URL = 'https://1x2networkhubstage.com/f1x2games/loadGame.jsp';
          GMAEMERKURURL = 'https://du008-p.edictmaltaservices.com.mt/gamestart.html';
          SKRILL_SERVICE_CODE = 5;
          NETELLER_SERVICE_CODE = 19;
          MERCHANT_ID = 5000382;
          GAME_PROVIDERS = ['Greentube', 'Relax', 'OryxGaming', 'Merkur', 'Pragmatic', 'Playngo'];
          ALLOWED_PAYMENT_METHOD = ['ADYEN']; //LPS, EPG
          MERCHANT_GUID = '054FB896-1CBD-FF84-22FC-B36819CA5FF1';
          DEFAULT_LANG = 'de';
          ALLOWED_TOURNAMENT = false;
          ALLOWED_LIVE_CASINO = true;
          GAMECLIENT_PARAMS_URL = 'https://nrgs-b2b-cstg.greentube.com/Nrgs/B2B/Web/NovolineDE/V5/OpenURL';
          AMQ_WS_URL = 'wss://www.die-spielbank.de/amq';
          MENTORIFRAMEURL = 'https://pic.mentor.neccton.com/';
          GERMANAUTOCOMPLETEAPIURL = 'https://autocomplete2.postdirekt.de/autocomplete2/';
          PRERANDER_TOKEN = '9LngJOu1FiLdoKtsBs7X';
          PRERANDER_APIENDPOINT = 'https://api.prerender.io/';
          break;
      }
    }
    let obj = {
      APIURL: APIURL,
      DOMAIN_NAME: DOMAIN_NAME,
      GMAE1X2URL: GMAE1X2URL,
      GMAEMERKURURL: GMAEMERKURURL,
      SKRILL_SERVICE_CODE: SKRILL_SERVICE_CODE,
      NETELLER_SERVICE_CODE: NETELLER_SERVICE_CODE,
      MERCHANT_ID: MERCHANT_ID,
      MERCHANT_GUID: MERCHANT_GUID,
      DEFAULT_LANG: DEFAULT_LANG,
      ALLOWED_PAYMENT_METHOD: ALLOWED_PAYMENT_METHOD,
      GAME_PROVIDERS: GAME_PROVIDERS,
      GAMECLIENT_PARAMS_URL: GAMECLIENT_PARAMS_URL,
      ALLOWED_TOURNAMENT: ALLOWED_TOURNAMENT,
      AMQ_WS_URL: AMQ_WS_URL,
      MENTORIFRAMEURL: MENTORIFRAMEURL,
      ALLOWED_LIVE_CASINO,
      PRERANDER_TOKEN,
      PRERANDER_APIENDPOINT,
      GERMANAUTOCOMPLETEAPIURL: GERMANAUTOCOMPLETEAPIURL,
    };
    return obj;
  }

  public getApiEndPoint() {
    return this.constants.APIURL;
  }

  public get1X2GamesEndPoint() {
    return this.constants.GMAE1X2URL;
  }

  public getMerkurGamesEndPoint() {
    return this.constants.GMAEMERKURURL;
  }

  public getPaymentServiceCode() {
    return this.constants;
  }

  public getMerchantId() {
    return this.constants.MERCHANT_ID;
  }

  public getAllowedTournament() {
    return this.constants.ALLOWED_TOURNAMENT;
  }

  public getAllowedLiveCasino() {
    return this.constants.ALLOWED_LIVE_CASINO;
  }

  public getAllowedVirtualSport() {
    let gameProviders = this.getAllowedGameProviders();
    if (gameProviders.indexOf('1x2Gaming') !== -1) {
      return true;
    }
    return false;
  }

  public getMerchantGUID() {
    return this.constants.MERCHANT_GUID;
  }

  public getAllowedPaymentMethod() {
    return this.constants.ALLOWED_PAYMENT_METHOD;
  }

  public getAllowedGameProviders() {
    return this.constants.GAME_PROVIDERS;
  }

  public getGameClientParameterURl() {
    return this.constants.GAMECLIENT_PARAMS_URL;
  }

  public showHideMenu() {
    this.navEmitter.next(null);
  }

  public getGermanAutocompleteApiEndPoint() {
    return this.constants.GERMANAUTOCOMPLETEAPIURL;
  }

  public notifyUserLogin() {
    let data = {
      type: 'login',
      data: {}
    };
    this.loginEmitter.next(data);
  }

  public notifyUserLogout(val: any = {}) {
    let data = {
      type: 'logout',
      data: val
    };
    this.loginEmitter.next(data);
  }

  public notifyLogoutFromUpdatePlayerEmitter(arg: any) {
    if (arg) {
      this.logoutFromUpdatePlayerEventEmitter.next(arg);
    }
  }

  public getLanguageCode(lang: any) {
    let lngVal = 'de_DE';
    if (lang === 'de') {
      lngVal = 'de_DE';
    }
    return lngVal;
  }

  public getLanguages(): string {
    let isClient = this.getClient();
    let langArr: any = [{
      languageName: 'German',
      languageCode: 'de',
    }];

    switch (isClient) {
      case 'spielbank':
        langArr = [{
          languageName: 'German',
          languageCode: 'de',
        }];
        break;
    }
    return langArr;
  }

  public getClientDefaultLang() {
    return this.constants.DEFAULT_LANG;
  }

  public getClientEnv() {
    return this.env;
  }

  public getVerifyUConfiguration() {
    let clientEnv = this._localStorageService.get('clientEnv');
    if (clientEnv == 'staging' || clientEnv == 'dev') {
      return {
        URL: 'http://localhost:3000/fileupload/',
        openIdConnectUrl: 'https://app.verify-u.com/oauthconfig',
        clientId: '9f4a660c-d15c-4354-ad13-cb90ff7be145',
        autoIdentScope: 'autoident-test',
        videoIdentScope: 'videoident-test',

      };
    } else {
      return {
        URL: 'http://localhost:3000/fileupload/',
        openIdConnectUrl: 'https://app.verify-u.com/oauthconfig',
        clientId: '9f4a660c-d15c-4354-ad13-cb90ff7be145',
        autoIdentScope: 'autoident-prod',
        videoIdentScope: 'videoident-prod',

      };
    }

  }

  public getWsConnectionUrl() {
    if (window.location.protocol == 'https:') {
      return this.constants.AMQ_WS_URL;
    } else {
      return 'ws://167.99.35.229:61614/';
    }
  }

  public getGMTTimeZone() {
    let d = new Date().toString();
    let reg = /[0-9]{2}:[0-9]{2}:[0-9]{2}\s/i;
    let gmtStr = '';
    try {
      let match = d.match(reg);
      if (match) {
        let ind: any = match['index'];

        let start = ind + 9;
        let end = ind + 17;

        for (let i = start; i < end; i++) {
          gmtStr += d[i];
          if (i == end - 3) {
            gmtStr += ':';
          }
        }
      } else {
        console.log('Does not match');
      }
    } catch (e) {
      console.log(e);
    }
    return gmtStr;
  }

  public getMentorAuthIframeEndPoint() {
    return this.constants.MENTORIFRAMEURL;
  }

  public subscriberLoader(val: any) {
    this.subscriberLoaderEmitter.next(val);
  }

  public getPreranderToken() {
    return this.constants.PRERANDER_TOKEN;
  }

  public getPreranderApiEndPoint() {
    return this.constants.PRERANDER_APIENDPOINT;
  }

  getBrowserTabId() {
    if (isPlatformBrowser(this.platformId)) {
      window.tabId = sessionStorage.tabID && sessionStorage.closedLastTab !== '2' ? sessionStorage.tabID : sessionStorage.tabID = Math.floor(Math.random() * 1000000);
      sessionStorage.closedLastTab = '2';
      jQuery(window).on('unload beforeunload', () => {
        sessionStorage.closedLastTab = '1';
      });
    }
  }
}
