import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { GeneralService } from './general.service';

@Injectable()

export class PlayerProfileService {
    public playerProfileDataEmitter: EventEmitter<any> = new EventEmitter();
    public loadPlayerProfileDataEmitter: EventEmitter<any> = new EventEmitter();
    public profileData: any = {};
    public playerQueryParamToken: any = '';

    constructor(
        private _apiService: ApiService,
        private localStorageService: LocalStorageService,
        private _generalService: GeneralService
    ) { }

    // GET Player Profile Detail API
    getPlayerProfile(isLoader: any): Observable<any> | any {
        let apiUrl = 'profile/v3/profile-v3';
        return this._apiService.request('get', apiUrl, '', '', isLoader);
    }

    // SAVE Player Profile API
    savePlayerProfil(data: any, params: any = {}): Observable<any> | any {
        let apiUrl = 'profile/v3/profile-v3';
        return this._apiService.request('post', apiUrl, data, params, true);
    }

    // SAVE Schufa Verification
    schufaVerificationApi(param: any = {}): Observable<any> | any {
        let apiUrl = 'schufa/schufaVerification';
        return this._apiService.request('get', apiUrl, {}, param, true);
    }

    // SAVE Schufa Verification
    getPostIdentDocApi(): Observable<any> | any {
        let apiUrl = 'regulations/getPostIdentDoc';
        return this._apiService.request('get', apiUrl, {}, {}, true);
    }

    // SAVE Schufa Verification
    getRegulationsStatus(): Observable<any> | any {
        let apiUrl = 'regulations/getStatus';
        return this._apiService.request('get', apiUrl, {}, {}, true);
    }

    saveContactDetail(data: any): Observable<any> | any {
        let apiUrl = 'contactUs';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // This function is return player excluded
    saveExclusions(data: any): Observable<any> | any {
        const apiUrl = 'playerProfile/exclusions';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // This function is return player excluded
    saveSelfBan(data: any): Observable<any> | any {
        const apiUrl = 'playerProfile/ban';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // LOAD Regulatory Consent Data API
    loadRegulatoryConsent(data: any, isLoader: boolean = false): Observable<any> | any {
        let apiUrl = 'playerTnc/v2/view';
        return this._apiService.request('post', apiUrl, data, {}, isLoader, false);
    }

    loadChangeConsentData(data: any, isLoader: boolean = false): Observable<any> | any {
        let apiUrl = 'v2/profile/changeConsentData';
        return this._apiService.request('post', apiUrl, data, {}, isLoader);
    }

    // GDPR - Load Consent Data API
    updateConsentData(data: any): Observable<any> | any {
        let apiUrl = 'v2/profile/updateConsentData';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // GET Player limit API
    getAllLimits(params: any): Observable<any> | any {
       const apiUrl = 'limits/v1/fetch';
       return this._apiService.request('get', apiUrl, '', params, true);
    }

      // GET Player limit API
    getOldLimits(params: any): Observable<any> | any {
       const apiUrl = 'limits/fetch';
       return this._apiService.request('get', apiUrl, '', params, true);
    }

    // GET Player limit-History API
    getLimitsHistory(data: any): Observable<any> | any {
        const apiUrl = 'playerProfile/get/limits';
        return this._apiService.request('get', apiUrl, {}, data, true);
    }

    // GET Player limit API
    saveLimits(data: any): Observable<any> | any {
        const apiUrl = 'v1/register/limits/set';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // GET setLugasLimit
    setLugasLimit(limitVal: any, qParam: any = {}): Observable<any> | any {
        const apiUrl = 'lugas/setLimit/' + limitVal;
        return this._apiService.request('put', apiUrl, {}, qParam, true);
    }

    // GET lugasCheckLimitApi API
    lugasCheckLimitApi(): Observable<any> | any {
        const apiUrl = 'lugas/checkLimit';
        return this._apiService.request('get', apiUrl, {}, {}, true);
    }

    // Load Regulatory Consent Data API
    loadMentorAuthenticate(data: any, isLoader: boolean = false): Observable<any> | any {
        const apiUrl = 'mentor/authenticate';
        return this._apiService.request('get', apiUrl, '', '', false);
    }

    // GET Get Withdraw Threshold API
    getWithdrawThresholdApi(): Observable<any> | any {
        const apiUrl = 'limits/getWithdrawThreshold';
        return this._apiService.request('get', apiUrl, '', '', true);
    }

    // POST Set Withdraw Threshold API
    setWithdrawThresholdApi(data: any): Observable<any> | any {
        const apiUrl = 'limits/setWithdrawThreshold';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // POST DeActivate Withdraw Threshold API
    deActivateWithdrawThresholdApi(data: any): Observable<any> | any {
        const apiUrl = 'limits/deActivateWithdrawThreshold';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // GET Player Profile Detail API
    deletePlayerAccountApi(): Observable<any> | any {
        let apiUrl = 'v2/profile/deletePlayerAccount';
        return this._apiService.request('get', apiUrl, '', {}, true);
    }

    // checkPlayerLimitApi
    checkPlayerLimitApi(): Observable<any> | any {
        let apiUrl = 'lugas/checkPlayer';
        return this._apiService.request('get', apiUrl, '', {}, true);
    }

    // Player State API
    lugasPlayerstate(state: boolean): Observable<any> | any {
        let apiUrl = `lugas/playerstate/${state}`;
        return this._apiService.request('put', apiUrl, '', {}, true);
    }

    profileDataEmitter(data: any) {
        this.playerProfileDataEmitter.emit(data);
    }

    loadProfileData(data: any) {
        this.loadPlayerProfileDataEmitter.next(data);
    }

    setPlayerProfileData(val: any) {
        this.profileData = val;
    }

    getPlayerProfileData() {
        return this.profileData;
    }

    setPlayerQueryParamsToken(val: any) {
        this.playerQueryParamToken = val;
    }

    getPlayerQueryParamsToken() {
        return this.playerQueryParamToken;
    }

    checkPlayerLimitSetting(): Observable<any> | Promise<any> | any {
        let resultPromise = new Promise((resolve, reject) => {
            let userDetail: any = this.localStorageService.get('player');
            if (!userDetail) {
                resolve(true);
                return;
            }
            this.checkPlayerLimitApi().subscribe((res: any) => {
                let data = res || {};
                try {
                    if (data.message) {
                        let msgData = JSON.parse(data.message);
                        if (msgData.isRegistered) {
                            resolve(true);
                        } else {
                            resolve(false);
                        }
                    } else {
                        resolve(true);
                    }
                } catch (e) {
                    reject(false);
                }
            }, (err: any) => {
                reject(false);
            });
        });
        return resultPromise;
    }

    openPreVerificationModal(verifSatusFlag: any, verificationAssets: any) {
        let title = verificationAssets.text.userIdVerificationPage_reidentify_profile_modal_title;
        let msg = 'Please a new identify';
        if (verifSatusFlag == 'kycExpired') {
            msg = verificationAssets.text.userIdVerificationPage_personal_detail_change_modal_desc;
        } else if (verifSatusFlag == 'ibanStatus') {
            msg = verificationAssets.text.userIdVerificationPage_bank_detail_change_modal_desc;
        } else if (verifSatusFlag == 'fullyVerifiedStatus') {
            title = verificationAssets.text.userIdVerificationPage_verification_modal_title;
            msg = verificationAssets.text.userIdVerificationPage_profile_not_fully_verified_modal_desc;
        } else if (verifSatusFlag == 'preReverificationStatus') {
            msg = verificationAssets.text.userIdVerificationPage_review_and_update_data_modal_desc;
        } else if (verifSatusFlag == 'ibanToVerify') {
            msg = verificationAssets.text.userIdVerificationPage_iban_verify_modal_desc;
        } else if (verifSatusFlag == 'ibanToReVerify') {
            msg = verificationAssets.text.userIdVerificationPage_iban_verify_recheck_modal_desc;
        } else if (verifSatusFlag == 'identityToReVerify') {
            msg = verificationAssets.text.userIdVerificationPage_profile_recheck_modal_desc;
        }
        
        let okBtn = verificationAssets.text.userIdVerificationPage_ok_btn_label;
        return this._generalService.infomodel(title, msg, okBtn);
    }
}
