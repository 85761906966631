import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

// Services
import { ApiService } from './api.service';

@Injectable()

export class PlayerAccountService {
    constructor(
        private _apiService: ApiService
    ) { }

    // loadtransacationOverview - Load latest Consent Data API
    loadtransacationOverview(): Observable<any> | any {
        let apiUrl = 'playerOverview/trancactionOverview';
        return this._apiService.request('get', apiUrl, {}, {}, false);
    }

    playerTransactionHistory(data: any): Observable<any> | any {
        const apiUrl = 'playertransactions/transactionsV2';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    cancelWithdrawApi(data: any): Observable<any> | any {
        const apiUrl = 'playerProfile/cancelWithdraw';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }
}
