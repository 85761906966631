import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

// Services
import { ApiService } from './api.service';
import { LocalStorageService } from './local-storage.service';
import { DataService } from './data.service';

// Component
import { RegulatoryModalComponent } from 'src/app/directive/regulatory-modal/regulatory-modal.component';

@Injectable()

export class GdprService {
    constructor(
        public dialog: MatDialog,
        private _apiService: ApiService,
        private _localStorageService: LocalStorageService,
        private _dataService: DataService
    ) { }

    // GDPR - Load Consent Data API
    loadConsentData(data: any): Observable<any> | any {
        let apiUrl = 'v2/profile/loadConsentData';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // GDPR - Load latest Consent Data API
    loadLatestConsentData(): Observable<any> | any {
        let apiUrl = 'v2/profile/loadLatestConsentData';
        return this._apiService.request('get', apiUrl, {}, {}, true);
    }

    openAcceptRegulatoryConsentModal(): any {
        let playerFlag: any = this._localStorageService.get('playerFlag');
        let playerData: any = this._localStorageService.get('player');
        
        if (!playerFlag) {
            return false;
        }

        if(playerData.isadvertisementAllow == false) {
            return false;
        }

        let params = {}, flag: any;

        let headerPageAssets = this._dataService.getAssets('headerPage') || {};
        let modalTitle: any = {};
        if (headerPageAssets.text) {
            modalTitle = headerPageAssets.text || {};
        }

        if (!playerFlag.playerTncFlag) {
            flag = 'playerTncFlag';
            params = { title: modalTitle['headerPage_tnc_modal_title'] || 'Terms and Conditions', consentName: 'GTC', isAcceptBtn: true }
        } else if (!playerFlag.dataPrivacyPolicyFlag) {
            flag = 'dataPrivacyPolicyFlag';
            params = { title: modalTitle['headerPage_privacy_notice_modal_title'] || 'Privacy Notice', consentName: 'DPP', isAcceptBtn: true }
        } else if (!playerFlag.marketingProfilingFlag) {
            flag = 'marketingProfilingFlag';
            params = { title: modalTitle['headerPage_marketing_profiling_modal_title'] || 'Marketing Profiling', consentName: 'MP', isAcceptBtn: true }
        } else {
            return false;
        }

        let consentModal = this.dialog.open(RegulatoryModalComponent, {
            data: params,
            disableClose: true
        });

        consentModal.afterClosed().subscribe(result => {
            if (result == 'accepted') {
                playerFlag[flag] = true;
                this._localStorageService.set('playerFlag', playerFlag);
                this.openAcceptRegulatoryConsentModal();
            }
        });
    }
}
