<div class="modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="modal-title w-100" id="myModalLabel"
                    [innerHTML]="amlKycPageAssets.text.amlKycPage_header_title"></h4>
                <button type="button" class="close" aria-label="Close" (click)="dialogRef.close()">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>
            <div class="modal-body">
                <div class="body">
                    <div class="row">
                        <div class="col-sm-12 mb-3">
                            <div class="headline headline-beta is-first"
                                [innerHTML]="amlKycPageAssets.text.amlKycPage_doc_title"></div>
                            <div class="paragraph" [innerHTML]="amlKycPageAssets.text.amlKycPage_doc_info_criteria1">
                            </div>
                            <div class="paragraph mt-0">
                                <div class="alert alert-info"
                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_doc_info_criteria2"></div>
                            </div>
                            <form class="doc-form" [formGroup]="documentsForm" (ngSubmit)="onAmlDocSubmit()">

                                <!-- ID PROOF UPLOAD -->
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group mw-300 mt-4" *ngIf="uploadedIdDocumentList.length > 0">
                                            <label for="pepFlag" class="form-fake-label d-flex"
                                                [innerHTML]="amlKycPageAssets.text.amlKycPage_POI_label">
                                            </label>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="POIYes" formControlName="idPOI" [value]="true"
                                                    class="custom-control-input">
                                                <label class="custom-control-label" for="POIYes"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_POI_radio_yes"></label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="POINo" formControlName="idPOI" [value]="false"
                                                    class="custom-control-input">
                                                <label class="custom-control-label" for="POINo"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_POI_radio_no"></label>
                                            </div>
                                        </div>

                                        <ng-container *ngIf="!dFrm.idPOI.value">
                                            <div class="form-group mw-300">
                                                <label for="idDocMasterCode" class="form-fake-label"
                                                    [ngClass]="{'valid-input': !dFrm.idDocMasterCode.errors && dFrm.idDocMasterCode.valid, 'invalidvalid-input': dFrm.idDocMasterCode.errors && (dFrm.idDocMasterCode.dirty || dFrm.idDocMasterCode.touched)}"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_docType_label">
                                                </label>
                                                <select class="form-control"
                                                    [ngClass]="{'is-valid': !dFrm.idDocMasterCode.errors && dFrm.idDocMasterCode.valid, 'is-invalid': dFrm.idDocMasterCode.errors && (dFrm.idDocMasterCode.dirty || dFrm.idDocMasterCode.touched)}"
                                                    id="idDocMasterCode" formControlName="idDocMasterCode"
                                                    (change)="changeDocumentList($event, 'idProofUploadArray', 'idProofUploader')"
                                                    required>
                                                    <option selected disabled value="">Choose...</option>
                                                    <option *ngFor="let idDocument of idDocumentList"
                                                        [value]="idDocument.docMasterCode">
                                                        {{idDocument.docMasterName}}</option>
                                                </select>
                                                <div *ngIf="dFrm.idDocMasterCode.errors && dFrm.idDocMasterCode.touched"
                                                    class="text-danger">
                                                    <div *ngIf="dFrm.idDocMasterCode.errors.required"
                                                        [innerHTML]="amlKycPageAssets.errormsg.amlKycPage_error_docType_required">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mw-300 pt-2">
                                                <label for="idDocUpload"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_docUpload_label"></label>
                                                <input type="file" class="form-control-file" id="idDocUpload"
                                                    ng2FileSelect [uploader]="idProofUploader" multiple />
                                                <div class="uploaded-doc-list w-100 my-3">
                                                    <ul>
                                                        <li *ngFor="let item of idProofUploader.queue; let i = index;">
                                                            <div class="file-name">{{ item?.file?.name }}</div>
                                                            <div class="remove-file"
                                                                (click)="item.remove(); removeDocFromList(i, 'idProofUploadArray');">
                                                                X
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="form-group mw-300">
                                                <div class="alert alert-danger" *ngIf="idProofUploaderErrors">
                                                    <div>{{docUploadErrorMsg}}</div>
                                                </div>
                                            </div>
                                        </ng-container>


                                    </div>
                                    <div class="col-lg-12 usps">
                                        <div class="paragraph mt-0" *ngIf="uploadedIdDocumentList.length <= 0">
                                            <div class="alert alert-info"
                                                [innerHTML]="amlKycPageAssets.errormsg.amlKycPage_error_document_not_upload">
                                            </div>
                                        </div>
                                        <table class="table-borderless" *ngIf="uploadedIdDocumentList.length > 0">
                                            <thead>
                                                <tr class="table-active">
                                                    <th scope="col"
                                                        [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label1">
                                                    </th>
                                                    <th scope="col"
                                                        [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label2">
                                                    </th>
                                                    <th scope="col"
                                                        [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label3">
                                                    </th>
                                                    <th scope="col"
                                                        [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label4">
                                                    </th>
                                                    <th scope="col"
                                                        [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label5">
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr *ngFor="let idDocList of uploadedIdDocumentList">
                                                    <td>{{idDocList.dateUploaded | date : "dd.MM.yy"}}</td>
                                                    <td>{{docNameAssArray[idDocList.docMasterCode]?.name}}</td>
                                                    <td>{{idDocList.docExpiryDate?(idDocList.docExpiryDate | date :
                                                        "dd.MM.yy"):''}}
                                                    </td>
                                                    <td>{{idDocList.docNumber?idDocList.docNumber:''}}</td>
                                                    <td>{{idDocList.status}}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>

                                <!-- ADDRESS PROOF UPLOAD -->
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group mw-300 mt-4"
                                            *ngIf="uploadedAddressDocumentList.length > 0">
                                            <label for="pepFlag" class="form-fake-label d-flex"
                                                [innerHTML]="amlKycPageAssets.text.amlKycPage_POA_label"></label>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="adrPOIYes" formControlName="addressPOI"
                                                    [value]="true" class="custom-control-input">
                                                <label class="custom-control-label" for="adrPOIYes"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_POI_radio_yes"></label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="adrPOINo" formControlName="addressPOI"
                                                    [value]="false" class="custom-control-input">
                                                <label class="custom-control-label" for="adrPOINo"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_POI_radio_no"></label>
                                            </div>
                                        </div>
                                        <ng-container *ngIf="!dFrm.addressPOI.value">
                                            <div class="form-group mw-300">
                                                <label for="addressDocMasterCode" class="form-fake-label"
                                                    [ngClass]="{'valid-input': !dFrm.addressDocMasterCode.errors && dFrm.addressDocMasterCode.valid, 'invalidvalid-input': dFrm.addressDocMasterCode.errors && (dFrm.addressDocMasterCode.dirty || dFrm.addressDocMasterCode.touched)}"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_docType_label">
                                                </label>
                                                <select class="form-control"
                                                    [ngClass]="{'is-valid': !dFrm.addressDocMasterCode.errors && dFrm.addressDocMasterCode.valid, 'is-invalid': dFrm.addressDocMasterCode.errors && (dFrm.addressDocMasterCode.dirty || dFrm.addressDocMasterCode.touched)}"
                                                    id="addressDocMasterCode" formControlName="addressDocMasterCode"
                                                    (change)="changeDocumentList($event, 'addressProofUploadArray', 'addressProofUploader')"
                                                    required>
                                                    <option selected disabled value="">Choose...</option>
                                                    <option *ngFor="let addressDocument of addressDocumentList"
                                                        [value]="addressDocument.docMasterCode">
                                                        {{addressDocument.docMasterName}}</option>
                                                </select>
                                                <div *ngIf="dFrm.addressDocMasterCode.errors && dFrm.addressDocMasterCode.touched"
                                                    class="text-danger">
                                                    <div *ngIf="dFrm.addressDocMasterCode.errors.required"
                                                        [innerHTML]="amlKycPageAssets.errormsg.amlKycPage_error_docType_required">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="form-group mw-300 pt-2">
                                                <label for="addDocUpload"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_docUpload_label"></label>
                                                <input type="file" class="form-control-file" id="addDocUpload"
                                                    ng2FileSelect [uploader]="addressProofUploader" multiple />
                                                <div class="uploaded-doc-list w-100 my-3">
                                                    <ul>
                                                        <li
                                                            *ngFor="let item of addressProofUploader.queue; let i = index;">
                                                            <div class="file-name">{{ item?.file?.name }}</div>
                                                            <div class="remove-file"
                                                                (click)="item.remove(); removeDocFromList(i, 'addressProofUploadArray');">
                                                                X
                                                            </div>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                            <div class="form-group mw-300">
                                                <div class="alert alert-danger" *ngIf="addressProofUploaderErrors">
                                                    <div>{{docUploadErrorMsg}}</div>
                                                </div>
                                            </div>
                                        </ng-container>
                                        <div class="form-group">
                                            <div class="paragraph mt-0" *ngIf="uploadedAddressDocumentList.length <= 0">
                                                <div class="alert alert-info"
                                                    [innerHTML]="amlKycPageAssets.errormsg.amlKycPage_error_document_not_upload">
                                                </div>
                                            </div>
                                            <table class="table-borderless"
                                                *ngIf="uploadedAddressDocumentList.length > 0">
                                                <thead>
                                                    <tr class="table-active">
                                                        <th scope="col"
                                                            [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label1">
                                                        </th>
                                                        <th scope="col"
                                                            [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label2">
                                                        </th>
                                                        <th scope="col"
                                                            [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label3">
                                                        </th>
                                                        <th scope="col"
                                                            [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label4">
                                                        </th>
                                                        <th scope="col"
                                                            [innerHTML]="amlKycPageAssets.text.amlKycPage_doclist_label5">
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let addressDocList of uploadedAddressDocumentList">
                                                        <td>{{addressDocList.dateUploaded | date : "dd.MM.yy"}}
                                                        </td>
                                                        <td>{{docNameAssArray[addressDocList.docMasterCode]?.name}}
                                                        </td>
                                                        <td>{{addressDocList.docExpiryDate?(addressDocList.docExpiryDate
                                                            | date : "dd.MM.yy"):''}}
                                                        </td>
                                                        <td>{{addressDocList.docNumber?addressDocList.docNumber:''}}
                                                        </td>
                                                        <td>{{addressDocList.status}}</td>

                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="form-group mw-300">
                                            <label for="profession" class="form-fake-label"
                                                [ngClass]="{'valid-input': !dFrm.profession.errors && dFrm.profession.valid, 'invalidvalid-input': dFrm.profession.errors && (dFrm.profession.dirty || dFrm.profession.touched)}"><span
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_profession_label"></span>
                                                <img *ngIf="!dFrm.profession.errors && dFrm.profession.valid"
                                                    src="assets/images/icon-alert-check.svg" [alt]="amlKycPageAssets.text.icon_alert_check_alt">
                                            </label>
                                            <input type="text" class="form-control"
                                                [ngClass]="{'is-valid': !dFrm.profession.errors && dFrm.profession.valid, 'is-invalid': dFrm.profession.errors && (dFrm.profession.dirty || dFrm.profession.touched)}"
                                                id="profession" formControlName="profession"
                                                [placeholder]="amlKycPageAssets.text.amlKycPage_profession_label"
                                                required>
                                            <div *ngIf="dFrm.profession.errors && dFrm.profession.touched"
                                                class="text-danger">
                                                <div *ngIf="dFrm.profession.errors.required"
                                                    [innerHTML]="amlKycPageAssets.errormsg.amlKycPage_error_profession_required">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="form-group mw-300 mt-4">
                                            <label for="pepFlag" class="form-fake-label d-flex"
                                                [ngClass]="{'valid-input': !dFrm.pepFlag.errors && dFrm.pepFlag.valid, 'invalidvalid-input': dFrm.pepFlag.errors && (dFrm.pepFlag.dirty || dFrm.pepFlag.touched)}">
                                                <span [innerHTML]="amlKycPageAssets.text.amlKycPage_pep_label"></span>
                                                <img *ngIf="!dFrm.pepFlag.errors && dFrm.pepFlag.valid"
                                                    src="assets/images/icon-alert-check.svg"  [alt]="amlKycPageAssets.text.icon_alert_check_alt">
                                            </label>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="pepFlagTrue" formControlName="pepFlag"
                                                    [value]="true" class="custom-control-input">
                                                <label class="custom-control-label" for="pepFlagTrue"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_POI_radio_yes"></label>
                                            </div>
                                            <div class="custom-control custom-radio custom-control-inline">
                                                <input type="radio" id="pepFlagFalse" formControlName="pepFlag"
                                                    [value]="false" class="custom-control-input">
                                                <label class="custom-control-label" for="pepFlagFalse"
                                                    [innerHTML]="amlKycPageAssets.text.amlKycPage_POI_radio_no"></label>
                                            </div>
                                            <div *ngIf="dFrm.pepFlag.errors && dFrm.pepFlag.touched"
                                                class="text-danger">
                                                <div *ngIf="dFrm.pepFlag.errors.required"
                                                    [innerHTML]="amlKycPageAssets.errormsg.amlKycPage_error_pep_required">
                                                </div>
                                            </div>
                                        </div>
                                        <div class="alert alert-danger" *ngIf="serverErrorMsg">
                                            <div>{{serverErrorMsg}}</div>
                                        </div>
                                    </div>

                                </div>

                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="form-group text-center">
                                            <button type="submit" class="btn btn-yellow"
                                                [disabled]="documentsForm.invalid || (idProofUploadArray.length == 0 && !dFrm.idPOI.value) || (addressProofUploadArray.length == 0 && !dFrm.addressPOI.value)"
                                                [innerHTML]="amlKycPageAssets.text.amlKycPage_save_btn_label"></button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>