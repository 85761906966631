import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { AmlKycModalComponent } from './aml-kyc-modal.component';
import { FileUploadModule } from 'ng2-file-upload';


@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        RouterModule,
        FileUploadModule
    ],
    declarations: [
        AmlKycModalComponent
    ],
    exports: [
        AmlKycModalComponent
    ],
    providers: []
})

export class AmlKycModule { }
