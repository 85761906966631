import { Pipe, PipeTransform, Inject, PLATFORM_ID } from '@angular/core';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
declare let $: any;

@Pipe({ name: 'safeHTML', pure: false })
export class SafeHtmlPipe implements PipeTransform {
  constructor(@Inject(PLATFORM_ID) private platformId: Object) {
  }
  transform(content: any) {
    if (isPlatformBrowser(this.platformId)) {
      return $('<span />').html(content).text();
    }
  }
}
