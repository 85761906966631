<div class="modal msg-modal" tabindex="-1" role="dialog" *ngIf="!depositData.modelName">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="depositData.msgTitle"></h2>
                <button type="button" class="close" aria-label="Close" (click)="modalClose()">
                        <span aria-hidden="true">
                            <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                                [removeSVGAttributes]="['xmlns']"></span>
                        </span>
                    </button>
            </div>
            <div class="modal-body">
                <div class="col-sm-12 p-0">
                    <div class="body text-center" [ngClass]="{'red-color': depositData.msgType == 'error'}" [innerHTML]="depositData.msgBody"></div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-secondary reg-btn btn-md" (click)="modalClose()" [innerHTML]="depositData.msgOkBtn"></button>
            </div>
        </div>
    </div>
</div>

<div class="modal msg-modal" tabindex="-1" role="dialog" *ngIf="depositData.modelName == 'validateApiModal'">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="depositData.msgTitle"></h2>
                <button type="button" class="close" aria-label="Close" (click)="dialogRef.close()">
                        <span aria-hidden="true">
                            <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                                [removeSVGAttributes]="['xmlns']"></span>
                        </span>
                    </button>
            </div>
            <div class="modal-body">
                <div class="col-sm-12 p-0">
                    <div class="body" [ngClass]="{'red-color': depositData.msgType == 'error'}" [innerHTML]="depositData.msgBody"></div>
                </div>
            </div>
            <div class="modal-footer">
                <button class="btn btn-main btn-main-md mr-2" (click)="modalClose('cancel')" [innerHTML]="depositData.msgCancelBtn"></button>
                <button class="btn btn-secondary btn-md reg-btn" (click)="modalClose('procced')" [innerHTML]="depositData.msgOkBtn"></button>
            </div>
        </div>
    </div>
</div>