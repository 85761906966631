import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ApiService } from './api.service';

@Injectable()
export class AdyenWithdrawService {
	constructor(
		private _apiService: ApiService,
	) { }

	// Get Withdrawal Details
	getWithdrawalDetails(isLoader: boolean = true): Observable<any> | any {
		const apiUrl = 'regulatedClosedLoop/withdrawaldetails-v1';
		return this._apiService.request('get', apiUrl, {}, {}, isLoader);
	}

	// Get Payment Methods Eligible for Withdraw
	gePaymentMethods(data: any): Observable<any> | any {
		const apiUrl = 'regulatedClosedLoop/paymentMethods-v1?amount=' + data;
		return this._apiService.request('get', apiUrl, {}, {}, true);
	}

	// Process Withdrawal
	processPayout(data: any): Observable<any> | any {
		const apiUrl = 'regulatedClosedLoop/processPayout-v1';
		return this._apiService.request('post', apiUrl, data, {}, true);
	}

	//Player KycLevel Configuration
	getPlayerKycLevelConfiguration(): Observable<any> | any {
		let apiUrl = 'playerProfile/getPlayerKycLevelConfiguration';
		return this._apiService.request('post', apiUrl, {}, {}, true);
	}

	// This function is used for  get enable count of withdraw
	loadWithdrawEnable(): Observable<any> | any {
		let apiUrl = 'playerProfile/withdraw/enable/v2';
		return this._apiService.request('post', apiUrl, {}, {}, true);
	}

	// This function is used for Get Bank Transfer Details
	getPlayerBankDetails(): Observable<any> | any {
		let apiUrl = 'playerProfile/getPlayerBankDetails';
		return this._apiService.request('post', apiUrl, {}, {}, true);
	}

	//Player KycLevel Configuration
	getWithdrawBankTransfer(data: any): Observable<any> | any {
		let apiUrl = 'playerProfile/withdrawBankTransfer/v2';
		return this._apiService.request('post', apiUrl, data, {}, true);
	}
}
