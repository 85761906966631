import { Component, AfterViewInit, OnInit, OnDestroy, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { LocalStorageService } from 'src/app/services/local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { isPlatformBrowser } from '@angular/common';

// Services
import { ConfigService } from '../../../services/config.service';
import { DataService } from '../../../services/data.service';
import { UserService } from '../../../services/user.service';
import { GeneralService } from '../../../services/general.service';
import { GamesService } from '../../../services/games.service';
import { RoutingService } from '../../../services/routing.service';
import { GoogleAnalitycsService } from '../../../services/google-analitycs.service';

declare let jQuery: any;
declare let $zopim: any;

@Component({
    selector: 'app-main-header',
    templateUrl: './main-header.component.html',
    styleUrls: ['./main-header.component.scss']
})

export class MainHeaderComponent implements AfterViewInit, OnInit, OnDestroy {
    // Public Var
    public isLogin = false;
    public loggedUserDetail = <any>{};
    public headerPageAssets: any;
    public searchGamesModel: any;
    public gamesData: any[] = [];
    public isSearchGames: boolean = false
    public timer: any;
    public pageData: any = {};
    public selectGames: any;
    public isSiteBlocked: boolean = false;
    public isMobile: boolean = false;
    public homePageAssets: any = {};
    public currency: any = {};
    public nativeEl: any;
    public withdrawalDetails = <any>{};
    public payoutPageAssets: any;
    public isPlayGame = false;
    public clientEnv: any = '';
    public isBrowser: any;

    // Private Var
    private pageDataEmitter: any = null;
    private isMobileEmitter: any = null;
    private loginEmitter: any = null;
    private playerEmitter: any = null;
    private withdrawDetailsService: any = null;
    private gamePlayEmitterSub: any = null;

    constructor(
        private el: ElementRef,
        public route: ActivatedRoute,
        public router: Router,
        public dialog: MatDialog,
        public _routingService: RoutingService,
        public _dataService: DataService,
        private _userService: UserService,
        private _localStorageService: LocalStorageService,
        private _generalService: GeneralService,
        private _configService: ConfigService,
        private _gamesService: GamesService,
        private _googleAnalitycsService: GoogleAnalitycsService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.isMobile = this._dataService.isMobile();
        this.clientEnv = this._localStorageService.get('clientEnv');
    }

    ngOnInit() {
        this.loadGamePlayEmitter();
        this.pageData = this._dataService.getPagesData();
        this.headerPageAssets = this._dataService.getAssets('headerPage');
        this.homePageAssets = this._dataService.getAssets('homePage');
        this.payoutPageAssets = this._dataService.getAssets('userProfileWithdrawPage');
        this.currency = this._userService.getPlayerCurrency();
        this.isSiteBlocked = false;
        if (this._userService.getIsSiteBlocked()) {
            this.isSiteBlocked = this._userService.getIsSiteBlocked();
        }

        this.isMobileEmitter = this._dataService.isMobileEmitter.subscribe((res) => {
            this.isMobile = res;
        });

        this.pageDataEmitter = this._dataService.pageDataLoadEmitter.subscribe((res) => {
            this.headerPageAssets = this._dataService.getAssets('headerPage');
            this.homePageAssets = this._dataService.getAssets('homePage');
            this.payoutPageAssets = this._dataService.getAssets('userProfileWithdrawPage');
            this.pageData = this._dataService.getPagesData();
            this.isSiteBlocked = this._userService.getIsSiteBlocked();
            if (isPlatformBrowser(this.platformId)) {
                setTimeout(() => {
                    this.loadPlayerDetailPopOver();
                }, 100);
            }
        });

        this.afterUserLoginUpdateData();
        let detail = this._userService.getPlayer();
        if (detail !== undefined && detail !== null) {
            this._userService.notifyUpdatePlayerData(detail);
            this.loggedUserDetail = detail;
            if (isPlatformBrowser(this.platformId)) {
                setTimeout(() => {
                    this._userService.checkTokenExpiryTime();
                }, 5000);
            }
        }

        this.loginEmitterCalled();

        try {
            if (this._dataService.getLoginState() === true) {
                this.isLogin = true;
                if (isPlatformBrowser(this.platformId)) {
                    setTimeout(() => {
                        this._userService.setIdlState();
                    }, 2000);
                }
                this._userService.updatePlayer();
            }
        } catch (e) {
            console.log(e, 'exception caught in AfterUserLogin');
        }
    }

    public get gamePlayRouteUrl(): any {
        if (this.router.url && this.router.url.includes(this._routingService.altRouterLinkActive('game-play'))) {
            return true;
        }
        return false;
    }

    ngAfterViewInit() {
        try {
            if (isPlatformBrowser(this.platformId)) {
                setTimeout(() => {
                    this.loadPlayerDetailPopOver();
                }, 100);
                let prevScrollpos = window.pageYOffset;

                $(window).scroll(function () {
                    let currentScrollPos = window.pageYOffset;
                    if (prevScrollpos > currentScrollPos) {
                        jQuery('.main-header').css('top', '0');
                    } else if (currentScrollPos < 50) {
                        jQuery('.main-header').css('top', '0');
                    } else {
                        jQuery('.main-header').css('top', '-125px');
                    }
                    prevScrollpos = currentScrollPos;
                });
            }
        } catch (e) {
        }
    }

    loadPlayerDetailPopOver() {
        try {
            jQuery('.main-header [data-toggle=popover]').popover({
                html: true,
                container: ".user",
                trigger: 'click',
                title: function () {
                    var title = jQuery(this).attr("data-popover-content");
                    return jQuery(title).children(".popover-heading").html();
                },
                content: function () {
                    jQuery('body').addClass('overflow');
                    var content = jQuery(this).attr("data-popover-content");
                    return jQuery(content).children(".popover-body").html();
                }
            });

            let me = this;
            jQuery('body').on('click', (e: any) => {
                jQuery('[data-toggle="popover"]').each(() => {
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0 && jQuery('.popover').has(e.target).length === 0 || e.target.id === 'popovercloseid') {
                        jQuery(this).popover('hide');
                        jQuery('body').removeClass('overflow');
                    }
                });

                let isMobileSearchTarget = true;
                jQuery('.game-search-section').each(() => {
                    if (!jQuery(this).is(e.target) && jQuery(this).has(e.target).length === 0) {
                        me.searchGamesModel = '';
                        me.clearDataOnEmpty(this.searchGamesModel)
                    }

                    if (jQuery(this).has(e.target).length) {
                        isMobileSearchTarget = false;
                    }
                });

                jQuery('.mobile-game-search').each(() => {
                    if (jQuery(this).has(e.target).length) {
                        isMobileSearchTarget = false;
                    }
                });

                if (isMobileSearchTarget) {
                    jQuery('.game-search-section').addClass('showSearchBar');
                }
            });
        } catch (e) {
        }
    }

    loginEmitterCalled() {
        this.loginEmitter = this._configService.loginEmitter.subscribe((data) => {
            if (data.type === 'login') {
                this.isLogin = true;
                this._userService.setIdlState();
                this._userService.updatePlayer();
                if (isPlatformBrowser(this.platformId)) {
                    setTimeout(() => {
                        this.loadPlayerDetailPopOver();
                    }, 100);

                    setTimeout(() => {
                        this._userService.checkTokenExpiryTime();
                    }, 5000);
                }
            } else {
                this._userService.clearIdleTime();
                this._userService.clearLocalStorage();
                this._userService.clearUpdatePlayerInterval()
                this._userService.disconnectWs();
                let interval = this._userService.getInterval();
                if (interval) {
                    if (isPlatformBrowser(this.platformId)) {
                        clearInterval(interval);
                    }
                }
                this.isLogin = false;
                this._dataService.setLoginState(false);

                let modalData = data.data || {};
                if (!modalData.isDialogOpen) {
                    this.dialog.closeAll();
                    if (isPlatformBrowser(this.platformId)) {
                        jQuery('.modal').modal('hide');
                        jQuery('[data-toggle=popover]').popover('hide');
                    }
                }
                this._generalService.showUseModule(false);
            }
        });
    }

    afterUserLoginUpdateData() {
        this.playerEmitter = this._userService.playerEmitter.subscribe((data) => {
            try {
                if (data !== undefined) {
                    this.loggedUserDetail = data;
                    if (this.gamePlayRouteUrl) {
                        console.log('it is in reset idle timer')
                        this._userService.resetIdleTime();
                    }
                }
            } catch (e) {
                console.log(e, 'exception caught in afterUserLoginUpdateData');
            }
        });
    }

    goToUserModule(event: any, pageName: any) {
        event.preventDefault();
        if (pageName == 'userMenu') {
            console.log(this.router.url.includes(this._routingService.altRouterLinkActive('user/deposit/adyen')));
            if (!this.router.url.includes(this._routingService.altRouterLinkActive('user')) || this.router.url.includes(this._routingService.altRouterLinkActive('user/deposit/adyen'))) {
                this._routingService.altRouterLink('user/overview');
            }
            if (isPlatformBrowser(this.platformId)) {
                setTimeout(() => {
                    this._configService.showHideMenu();
                }, 100);
            }
        } else {
            this._routingService.altRouterLink(pageName);
        }
    }

    openLoginModal() {
        this._googleAnalitycsService.trackingUserAction('event', 'heatmap', { 'dimension': 'login' });
        this._routingService.altRouterLink('login');
    }

    userRegistration() {
        this._googleAnalitycsService.trackingUserAction('event', 'heatmap', { 'dimension': 'login' });
        this._routingService.altRouterLink('register');
    }

    onTapMenu(event: any, pageName: any) {
        this._googleAnalitycsService.trackingUserAction('event', 'heatmap', { 'dimension': pageName });
        event.preventDefault();
        this._routingService.altRouterLink(pageName);
    }

    clearDataOnEmpty(searchText: any) {
        if (!searchText) {
            this.isSearchGames = false;
            this.gamesData = [];
        }
        clearTimeout(this.timer);
    }

    onTapPlayGame(game: any) {
        this.isSearchGames = false;
        if (this._generalService.checkPlayerBanned()) {
            return;
        }

        game['playMode'] = 'real'
        let playerDetails = this._userService.getPlayer();
        this.selectGames = game;
        this.selectGames['routeUrl'] = 'home';
        this._gamesService.setGamePlayData(this.selectGames);
        this._gamesService.gameDataLoadEmitter.emit(true);
        let gamePlayUrl = 'game-play';
        if (playerDetails) {
            this._routingService.altRouterLink(gamePlayUrl);
        } else {
            this._dataService.setGoToLoginFromUrl(gamePlayUrl);
            this._routingService.altRouterLink('login');
        }

        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.searchGamesModel = '';
                this.clearDataOnEmpty(this.searchGamesModel)
            }, 1000);
        }
    }

    onTapFunPlayGame(game: any) {
        this.isSearchGames = false;
        game['playMode'] = 'fun'
        this.selectGames = game;
        this.selectGames['routeUrl'] = this.router.url;
        this._gamesService.setGamePlayData(this.selectGames);
        this._gamesService.gameDataLoadEmitter.emit(true);
        let gamePlayUrl = 'game-play';
        this._routingService.altRouterLink(gamePlayUrl);
        if (isPlatformBrowser(this.platformId)) {
            setTimeout(() => {
                this.searchGamesModel = '';
                this.clearDataOnEmpty(this.searchGamesModel)
            }, 1000);
        }
    }

    closeIframe() {
        this.selectGames = null;
    }

    showSearchBar() {
        if (isPlatformBrowser(this.platformId)) {
            jQuery('.game-search-section').toggleClass('showSearchBar');
        }
    }

    goToLogin() {
        this._dataService.setGoToLoginFromUrl('sport-book');
        this._routingService.altRouterLink('login');
    }

    loadGamePlayEmitter() {
        this.gamePlayEmitterSub = this._gamesService.gamePlayEmitter.subscribe((res) => {
            if (isPlatformBrowser(this.platformId)) {
                setTimeout(() => {
                    this.isPlayGame = res;
                }, 500);
            }
        });
    }
    openLiveChat() {
        $zopim.livechat.window.show();
        this._googleAnalitycsService.trackingUserAction('event', 'use_chat');
    }

    goToHomePage(event) {
        event.preventDefault();
        this._routingService.altRouterLink('home');    
    }

    ngOnDestroy() {
        if (this.loginEmitter) {
            this.loginEmitter.unsubscribe();
        }
        if (this.isMobileEmitter) {
            this.isMobileEmitter.unsubscribe();
        }
        if (this.playerEmitter) {
            this.playerEmitter.unsubscribe();
        }
        if (this.pageDataEmitter) {
            this.pageDataEmitter.unsubscribe();
        }
        if (this.withdrawDetailsService) {
            this.withdrawDetailsService.unsubscribe();
        }
        if (this.gamePlayEmitterSub) {
            this.gamePlayEmitterSub.unsubscribe();
        }
    }
}
