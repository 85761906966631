<footer class="footer" *ngIf="footerPageAssets?.text">
    <div class="payment-options-main" *ngIf="router.url != _routingService.altRouterLinkActive('live-landing')">
        <div class="container">
            <p class="footer-legal-title" [innerHTML]="footerPageAssets.text.footerPage_info_title"></p>
            <p class="footer-payment-text" [innerHTML]="footerPageAssets.text.footerPage_info_text"></p>
            <ul class="social-list">
                <ng-container *ngFor="let socials of socialIconLists; let ind = index">
                    <li>
                        <a [href]="socials.socialURL" target="_blank" rel="nofollow"
                            alt="footerPageAssets.text.footerPage_potential_social_url_alt	">
                            <!-- <span [inlineSVG]="socials.icon" [removeSVGAttributes]="['xmlns']"></span> -->
                            <img [src]="socials.icon">
                        </a>
                    </li>
                </ng-container>
            </ul>
        </div>
        <div class="payment-row">
            <div class="payment-logo-main">
                <div class="payment-item" (click)="onTapIcon('visa')" *ngIf="footerPageAssets.digital.footerPage_logo1">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo1" [removeSVGAttributes]="['xmlns']" style="width: 67px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo1"
                        style="width: 67px;">
                </div>
                <div class="payment-item" (click)="onTapIcon('mastercard')"
                    *ngIf="footerPageAssets.digital.footerPage_logo2">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo2" [removeSVGAttributes]="['xmlns']" style="width: 54px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo2"
                        style="width: 54px;">
                </div>
                <div class="payment-item" (click)="onTapIcon('sofort')"
                    *ngIf="footerPageAssets.digital.footerPage_logo3">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo3" [removeSVGAttributes]="['xmlns']" style="width: 75px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo3"
                        style="width: 75px;">
                </div>
                <div class="payment-item" (click)="onTapIcon('giropay')"
                    *ngIf="footerPageAssets.digital.footerPage_logo4">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo4" [removeSVGAttributes]="['xmlns']" style="width: 90px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo4"
                        style="width: 90px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo5">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo5" [removeSVGAttributes]="['xmlns']" style="width: 70px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo5"
                        style="width: 70px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo6">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo6" [removeSVGAttributes]="['xmlns']" style="width: 70px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo6"
                        style="width: 70px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo7">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo7" [removeSVGAttributes]="['xmlns']" style="width: 140px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo7"
                        style="width: 140px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo8">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo8" [removeSVGAttributes]="['xmlns']" style="width: 70px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo8"
                        style="width: 70px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo9">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo9" [removeSVGAttributes]="['xmlns']" style="width: 170px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo9"
                        style="width: 170px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo10">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo10" [removeSVGAttributes]="['xmlns']" style="width: 120px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo10"
                        style="width: 120px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo11">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo11" [removeSVGAttributes]="['xmlns']" style="width: 100px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo11"
                        style="width: 100px;">
                </div>

                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo12">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo12" [removeSVGAttributes]="['xmlns']" style="width: 120px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo12"
                        style="width: 120px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo13">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo13" [removeSVGAttributes]="['xmlns']" style="width: 100px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo13"
                        style="width: 100px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo14">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo14" [removeSVGAttributes]="['xmlns']" style="width: 120px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo14"
                        style="width: 120px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo15">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo15" [removeSVGAttributes]="['xmlns']" style="width: 120px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo15"
                        style="width: 120px;">
                </div>
                <div class="payment-item" *ngIf="footerPageAssets.digital.footerPage_logo16">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_logo16" [removeSVGAttributes]="['xmlns']" style="width: 120px;">
                    </span> -->
                    <img class="icon" alt="payment method" [src]="footerPageAssets.digital.footerPage_logo16"
                        style="width: 120px;">
                </div>
            </div>
        </div>
    </div>
    <div class="seprater">
        <div class="border-sep"></div>
        <div class="seprater-img">
            <!-- <span class="icon" [inlineSVG]="'assets/images/seprater.svg'" [removeSVGAttributes]="['xmlns']"></span> -->
        </div>
        <div class="border-sep last-border"></div>
    </div>
    <div class="container">
        <div class="link-section">
            <div class="footer-logo">
                <img [src]="footerPageAssets.digital.footerPage_logo_footer"
                    [alt]="footerPageAssets.text.saxon_footer_alt">
            </div>
            <div class="footer-body">
                <div class="footer-list-item">
                    <p class="footer-menu-head" [innerHTML]="footerPageAssets.text.footerPage_s1_menu_title"></p>
                    <ul class="list-unstyled">
                        <li>
                            <a [href]="footerPageAssets.text.footerPage_s1_aboutUs_link"
                                [innerHTML]="footerPageAssets.text.footerPage_s1_aboutUs"
                                alt="footerPageAssets.text.footerPage_s1_about_us_alt"></a>
                        </li>
                        <li>
                            <a [href]="footerPageAssets.text.footerPage_s4_faq_link"
                                [innerHTML]="footerPageAssets.text.footerPage_s4_faq"
                                alt="footerPageAssets.text.footerPage_s4_faq_alt"></a>
                        </li>
                        <li>
                            <a href="{{_routingService.altRouterLinkActive('contact')}}"
                                (click)="onTapFooterLinks($event, 'contact')"
                                [innerHTML]="footerPageAssets.text.footerPage_s1_contactUs"
                                alt="footerPageAssets.text.footerPage_s1_contact_us_alt"></a>
                        </li>
                    </ul>
                </div>
                <div class="footer-list-item">
                    <p class="footer-menu-head" [innerHTML]="footerPageAssets.text.footerPage_s3_menu_title"></p>
                    <ul class="list-unstyled">
                        <li>
                            <a href="{{_routingService.altRouterLinkActive('privacy-notice')}}"
                                (click)="onTapFooterLinks($event, 'privacy-notice')"
                                [innerHTML]="footerPageAssets.text.footerPage_s3_privacy_policy"
                                alt="footerPageAssets.text.footerPage_s3_privacy_policy_alt"></a>
                        </li>
                        <li>
                            <a [href]="footerPageAssets.text.footerPage_s3_responsible_gaming_link"
                                [innerHTML]="footerPageAssets.text.footerPage_s3_responsible_gaming"
                                alt="footerPageAssets.text.footerPage_s3_responsible_gaming_alt"></a>
                        </li>
                        <li>
                            <a href="{{_routingService.altRouterLinkActive('marketing-profiling')}}"
                                (click)="onTapFooterLinks($event, 'marketing-profiling')"
                                [innerHTML]="footerPageAssets.text.footerPage_s2_agb_marketing_communication"
                                alt="footerPageAssets.text.footerPage_s2_agb_marketing_communication_alt"></a>
                        </li>
                        <li>
                            <a href="{{_routingService.altRouterLinkActive('cookie-policy')}}"
                                (click)="onTapFooterLinks($event, 'cookie-policy')"
                                [innerHTML]="footerPageAssets.text.footerPage_s2_cookie_policy"
                                alt="footerPageAssets.text.footerPage_s2_cookie_policy_alt"></a>
                        </li>
                    </ul>
                </div>
                <div class="footer-list-item">
                    <p class="footer-menu-head" [innerHTML]="footerPageAssets.text.footerPage_s2_menu_title"></p>
                    <ul class="list-unstyled">
                        <li>
                            <a href="{{_routingService.altRouterLinkActive('imprint')}}"
                                (click)="onTapFooterLinks($event, 'imprint')"
                                [innerHTML]="footerPageAssets.text.footerPage_s1_imprint"
                                alt="footerPageAssets.text.footerPage_s1_imprint"></a>
                        </li>
                        <li>
                            <a href="{{_routingService.altRouterLinkActive('bonus-terms')}}"
                                (click)="onTapFooterLinks($event, 'bonus-terms')"
                                [innerHTML]="footerPageAssets.text.footerPage_s2_agb_bonus"
                                alt="footerPageAssets.text.footerPage_s2_agb_bonus"></a>
                        </li>
                        <li>
                            <a href="{{_routingService.altRouterLinkActive('terms-and-conditions')}}"
                                (click)="onTapFooterLinks($event, 'terms-and-conditions')"
                                [innerHTML]="footerPageAssets.text.footerPage_s2_agb"
                                alt="footerPageAssets.text.footerPage_s2_agb"></a>
                        </li>
                    </ul>
                </div>

                <div class="footer-list-item">
                    <div class="about-detail-content service-text">
                        <p class="footer-menu-head" [innerHTML]="footerPageAssets.text.footerPage_s4_menu_title"></p>
                        <p class="footer-address" [innerHTML]="footerPageAssets.text.footerPage_address_text"></p>
                    </div>
                    <ul class="language-list">
                        <li *ngFor="let language of languagesArr">
                            <a href="javascript:void()" (click)="changeLanguage(language.languageCode)"
                                [ngClass]="{'active': selectedLanguage == language.languageCode}">
                                <img src="assets/images/flag-{{language.languageCode}}.webp"
                                    [alt]="language.languageCode">
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div class="about-logo">
                <div class="about-item">
                    <!-- <span class="icon" [inlineSVG]="footerPageAssets.digital.footerPage_ga_footerIcon" [removeSVGAttributes]="['xmlns']" style="width: 45px;">
                    </span> -->
                    <img class="icon" alt="ga" [src]="footerPageAssets.digital.footerPage_ga_footerIcon"
                        style="width: 45px;">
                </div>
                <div class="about-item">
                    <a class="icon" target="_blank" href="https://www.gluecksspiel-behoerde.de/de" rel="nofollow"
                        title="Gemeinsame Glücksspielbehörde der Länder"
                        alt="footerPageAssets.text.footerPage_gameCare_footerIcon_alt">
                        <img class="icon" alt="Gemeinsame"
                            [src]="footerPageAssets.digital.footerPage_gameCare_footerIcon" style="width: 45px;">
                    </a>
                </div>
                <div class="about-item">
                    <img class="icon" alt="18 plus" [src]="footerPageAssets.digital.footerPage_18plus_footerIcon"
                        style="width: 45px;">
                </div>
            </div>
            <div class="logo-footer">
                <ng-container>
                    <a (click)="redirectToUrl($event, footerPageAssets.banner?.footerPage_potential_logo?.bannerUrl)"
                        [href]="(footerPageAssets.banner?.footerPage_potential_logo?.bannerUrl)?footerPageAssets.banner?.footerPage_potential_logo?.bannerUrl:''"
                        alt="footerPageAssets.text.footerPage_banner_logo_alt">
                        <img [src]="(footerPageAssets.banner?.footerPage_potential_logo?.bannerPath)?footerPageAssets.banner?.footerPage_potential_logo?.bannerPath:footerPageAssets.digital.footerPage_potential_logo"
                            [alt]="footerPageAssets.text.footerPage_potential_logo_alt">
                    </a>
                </ng-container>
            </div>
            <div class="footer-info-main">
                <p [innerHTML]="footerPageAssets.text.footerPage_info_bottom_text"></p>
            </div>
            <div class="game-logo">

            </div>
        </div>
    </div>
</footer>