import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Observable } from 'rxjs/Rx';

//Services
import { UserService } from './user.service';
import { ApiService } from './api.service';

declare let window: any;
declare let jQuery: any;

@Injectable()

export class AppVerifyUService {
    // Public Var
    public configuration: any;
    public authorizationHandler: any;
    public notifier: any;
    public code: any;
    public tokenHandler: any;
    public request: any;
    public response: any;
    public tokenResponse: any;
    public appAuthUrl: any;
    public redirectUri: any;
    public verifyUState: string = '';

    constructor(
        public _userService: UserService,
        public dialog: MatDialog,
        public _apiService: ApiService
    ) { }

    loadVerifyUIdentification(token: any, assets: any) {
        // let player = this._userService.getPlayer();
        // if (!player) {
        //     let infoModel = this._generalService.infomodel(assets.errormsg.homePage_success_verify_indentity, assets.errormsg.homePage_error_player_not_logged_msg, assets.text.homePage_confirm_email_modal_login_btn_label);
        //     infoModel.afterClosed().subscribe((result: any) => {
        //         this._playerProfileService.setPlayerQueryParamsToken({ 'code': token });
        //         this._routingService.altRouterLink('login');
        //     });
        //     return;
        // }
        // this.getVerifyIdentification(token).subscribe((res: any) => {
        //     if (res.success) {
        //         this._generalService.toastSuccess(assets.errormsg.homePage_success_verification_successfully,
        //             assets.errormsg.homePage_success_verify_indentity,
        //             {
        //                 positionClass: 'toast-bottom-right',
        //                 enableHtml: true,
        //                 closeButton: true,
        //                 timeOut: 5000,
        //                 extendedTimeOut: 5000
        //             });

        //         this._googleAnalitycsService.trackingUserAction('event', 'regStep4Successful');
        //         this._routingService.altRouterLink('home');

        //     } else {
        //         if (res.errorCode == 9528) {
        //             this._generalService.infomodel(assets.errormsg.homePage_error_verifyu_failed_title, assets.errormsg.homePage_error_verifyu_details_msg_text, assets.errormsg.homePage_common_ok_btn);
        //         } else if (res.errorCode == 1111) {
        //             this._generalService.infomodel(assets.errormsg.homePage_error_verifyu_failed_title, assets.errormsg.homePage_error_verifyu_rejected_msg_text, assets.errormsg.homePage_common_ok_btn);
        //         } else if (res.errorCode == 1002) {
        //             this._generalService.infomodel(assets.errormsg.homePage_error_verifyu_failed_title, assets.errormsg.homePage_error_verifyu_not_exist_details_msg_text, assets.errormsg.homePage_common_ok_btn);
        //         } else {
        //             let msg = res.message || assets.errormsg.homePage_error_common_msg_text;
        //             this._generalService.infomodel(assets.errormsg.homePage_error_verifyu_failed_title, msg, assets.errormsg.homePage_common_ok_btn);
        //         }

        //         let obj = {
        //             errorCode: res.errorCode,
        //             errorMessage: res.message || assets.errormsg.homePage_error_common_msg_text
        //         }
        //         this._googleAnalitycsService.trackingUserAction('event', 'regSep4Unsuccessful', obj);
        //     }
        //     this._playerProfileService.setPlayerQueryParamsToken(null);
        // }, (err: any) => {
        //     this._playerProfileService.setPlayerQueryParamsToken(null);
        //     if (err.errorCode == 9528) {
        //         this._generalService.infomodel(assets.errormsg.homePage_error_verifyu_failed_title, assets.errormsg.homePage_error_verifyu_details_msg_text, assets.errormsg.homePage_common_ok_btn);
        //     } else {
        //         let msg = err.message || assets.errormsg.homePage_error_common_msg_text;
        //         this._generalService.infomodel(assets.errormsg.homePage_error_verifyu_failed_title, msg, assets.errormsg.homePage_common_ok_btn);
        //     }
        // })
    }


    // GET Doc Ass Detail Per Country API
    getVerifyIdentification(token: any): Observable<any> | any {
        let apiUrl = 'verifyu/identification/' + token;
        return this._apiService.request('get', apiUrl, '', '', true);
    }
}
