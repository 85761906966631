import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ApiService } from './api.service';

@Injectable()

export class AccountDataService {
    constructor(
        private _apiService: ApiService
    ) { }

    // eKycPlayerData API
    getSecurityQuestionKeys(): Observable<any> | any {
        let apiUrl = 'securityQuestion-v1';
        return this._apiService.request('get', apiUrl, '', '', false);
    }

    // Get Security Question API
    getSecurityQuestion(): Observable<any> | any {
        let apiUrl = 'securityQuestion-v1/getSecurityQuestion';
        return this._apiService.request('get', apiUrl, '', '', false);
        
    }

    // Get Security Question Answer API
    getSecurityQueAns(): Observable<any> | any {
        let apiUrl = 'securityQuestion-v1/getSecurityQueAns';
        return this._apiService.request('get', apiUrl, '', '', false);
        
    }

    // Resend Email API
    resendEmail(data: any): Observable<any> | any {
        let apiUrl = 'resendEmail';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // change Email API
    changeEmail(data: any): Observable<any> | any {
        let apiUrl = 'changeEmail';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // Change Password API
    changePassword(data: any): Observable<any> | any {
        let apiUrl = 'v2/profile/reset/password';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // Change Password API
    sendForgotPasswordEmail(data: any): Observable<any> | any {
        let apiUrl = 'v2/profile/sendForgotPasswordEmail';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // Eeset Password API
    verifyForgotPassword(data: any): Observable<any> | any {
        let apiUrl = 'v2/profile/verifyForgotPassword';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }
    
    // Reset Password API
    resetPassword(data: any): Observable<any> | any {
        let apiUrl = 'resetPassword/sendEmail';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }
}
