import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import {ApiService} from './api.service';

@Injectable()

export class LoginService {
    constructor(
        private _apiService: ApiService
    ) { }

    // Login API
    saveLogin(data: any): Observable<any> | any {
        let apiUrl = 'user/login';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // Logout API
    logOut(): Observable<any> | any {
        let apiUrl = 'logout';
        return this._apiService.request('post', apiUrl, '', {}, true);
    }
}
