import {Injectable} from '@angular/core';
import {Observable, throwError} from 'rxjs';

import {ApiService} from './api.service';
import { DataService } from './data.service';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable()
export class AppService {
    public langCode = '';

    constructor(
        public apiService: ApiService,
        public dataService: DataService,
        private http: HttpClient,
        private _configService: ConfigService
    ) {
    }

    init(isLoader: boolean = false): Observable<any> | any {
        let apiUrl = '';
        return this.apiService.request('get', apiUrl, '', '', isLoader, false);
    }

    altInit(): Observable<any> | any {
        let apiUrl = 'init';
        return this.apiService.request('get', apiUrl, '', '', false, false);
    }

    contentAPI(params: any): Observable<any> | any {
        const apiUrl = 'content';
        return this.apiService.request('post', apiUrl, params, '', false, false);
    }

    fetchData(url: any = '', body): Observable<any> {
        let endPoint = this._configService.getApiEndPoint();
        let apiURL = endPoint + 'content';
        // let apiURL: string = 'http://localhost:80/assets/init_api_json.json';
        
        // let apiURL = 'http://altcmsapi.dev.svc.cluster.local:8088/alt/api/';
        let headerConfig = { 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' };
        const headers = new HttpHeaders(headerConfig);
        const options = { withCredentials: false, headers };
        return this.http.post(apiURL, body, options);
      }
}
