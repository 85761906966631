import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from './local-storage.service';
import { MatDialog } from '@angular/material/dialog';

// Services
import { ApiService } from './api.service';
import { ConfigService } from './config.service';

import {
	TransactionOverviewModalComponent
} from '../directive/transaction-overview-modal/transaction-overview-modal.component';

@Injectable()

export class GamesService {
	// Public Var
	public virtualAuthToken: string = '';
	public gamePlayEmitter: EventEmitter<any> = new EventEmitter<any>();
	public gameDataLoadEmitter: EventEmitter<any> = new EventEmitter<any>();
	public gameDetails: any;
	public searchGameList: any = [];
	public isGamePlaying = false;
	public gamesApiData: any = null;
	public gamesPayloadArray: any = [];

	constructor(
		public dialog: MatDialog,
		private _apiService: ApiService,
		private _configService: ConfigService,
		private _localStorageService: LocalStorageService
	) { }

	// Games - Played Game Details API
	getPlayedGameDetails(data: any): Observable<any> | any {
		let apiUrl = 'playertransactions/getGameSessions';
		return this._apiService.request('post', apiUrl, data, {}, true);
	}

	// Games - Played Game Details API
	getGameRating(gameId: any): Observable<any> | any {
		const apiUrl = 'gamesOverview/gameRating/' + gameId;
		return this._apiService.request('get', apiUrl, {}, {}, false);
	}

	// get updateGameRating Data
	updateGameRating(data: any): Observable<any> | any {
		const apiUrl = 'gamesOverview/updateGameRating';
		return this._apiService.request('post', apiUrl, data, {}, true);
	}

	// get GT Game launch Data
	getGTGamesData(data: any, apiEndpoint: any): Observable<any> | any {
		let apiUrl = apiEndpoint;
		return this._apiService.request('post', apiUrl, data, {}, false);
	}

	// get virtual bets authenticate token
	playerActionEnablePanic(param: any): Observable<any> | any {
		let apiUrl = 'playerAction/enablePanic';
		return this._apiService.request('get', apiUrl, {}, param, true);
	}

	// get addGameFavorite Data
	addGameFavorite(gameId: any): Observable<any> | any {
		let apiUrl = 'gamesOverview/addGameFavorite/' + gameId;
		return this._apiService.request('post', apiUrl, {}, {}, true);
	}

	// get addGameFavorite Data
	lastPlayerGamesApi(param: any): Observable<any> | any {
		let apiUrl = 'gamesOverview/lastPlayerGames';
		return this._apiService.request('get', apiUrl, {}, param, false);
	}

	// get Game category List
	getGameCategory(isLoader: boolean = true): Observable<any> | any {
		let apiUrl = 'gamesOverview/getCategoryList';
		return this._apiService.request('get', apiUrl, {}, {}, isLoader);
	}

	// get addGameFavorite Data
	checkGameFavorite(gameId: any): Observable<any> | any {
		const apiUrl = 'gamesOverview/checkFavourite/' + gameId;
		// console.log('gameId==', gameId);
		return this._apiService.request('post', apiUrl, {}, {}, false);
	}

	// getFavouriteGames Data
	getFavouriteGames(params: any): Observable<any> | any {
		const apiUrl = 'gamesOverview/getFavouriteGames';
		return this._apiService.request('get', apiUrl, {}, params, false);
	}

	// getGamesApi Data
	getGamesApi(payload: any, loader: boolean = false): Observable<any> | any {
		const apiUrl = 'games';
		return this._apiService.request('post', apiUrl, payload, {}, loader);
	}

	setGamesApiData(arg: any = null) {
		this.gamesApiData = arg;
	}

	getGamesApiData() {
		return this.gamesApiData;
	}

	loadGamesApi(paramArg: any, paramArg2: any = {}): Observable<any> | Promise<any> | any {

		let resultPromise = new Promise((resolve, reject) => {
			let savedData = this.getGamesApiData();
			console.log('savedData====', savedData)
		
			const notContentAvailableCat = paramArg.filter((val: any) => {
				return !this.gamesPayloadArray.includes(val);
			});

			if (!notContentAvailableCat.length && savedData) {
				resolve(savedData);
				return;
			}

			let params = {
				'identifier': ['category'],
				'content': notContentAvailableCat
			}

			if (paramArg2.list) {
				params['list'] = paramArg2.list;
			} else {
				this.gamesPayloadArray.push(...paramArg);
			}

			let loader = paramArg2['isLoader'] || false;
			this.getGamesApi(params, loader).subscribe((res: any) => {
				let data = res.message || '';
				let gamesData = JSON.parse(data);

				if (!savedData) {
					savedData = {};
				}

				for (let key in gamesData) {
					if (!savedData[key]) {
						savedData[key] = {};
					}
					savedData[key] = gamesData[key];
				}

				this.setGamesApiData(savedData);
				resolve(savedData);
			}, (err: any) => {
				reject(err);
			})
		});
		return resultPromise;
	}


	public setGamePlayData(game: any) {
		if (game) {
			this._localStorageService.set('gamePlayData', game);
		} else {
			this._localStorageService.remove('gamePlayData');
		}
	}

	// get Game category List
	getGameCategoryList(assets: any) {
		let categoryList: any = [{
			categoryName: assets.text.categoriesPage_all_slots_label,
			classificationName: 'all'
		},
		{
			categoryName: assets.text.categoriesPage_your_favorites_label,
			classificationName: 'favorites_games'
		},
		{
			categoryName: assets.text.categoriesPage_last_played_games_title,
			classificationName: 'last_played_games'
		},
		{
			categoryName: assets.text.categoriesPage_staff_picked_games_label,
			classificationName: 'staff_picked'
		},
		{
			categoryName: assets.text.categoriesPage_classic_slots_games_label,
			classificationName: 'classic_slots'
		},
		{
			categoryName: assets.text.categoriesPage_provider_new_games_added_label,
			classificationName: 'new_games'
		},
		{
			categoryName: assets.text.categoriesPage_megaways_games_label,
			classificationName: 'megaways'
		},
		{
			categoryName: assets.text.categoriesPage_provider_greentube_games_label,
			classificationName: 'provider_greentube'
		},
		{
			categoryName: assets.text.categoriesPage_provider_pragmatic_games_label,
			classificationName: 'provider_pragmatic'
		},
		{
			categoryName: assets.text.categoriesPage_provider_playngo_games_label,
			classificationName: 'provider_playngo'
		},
		{
			categoryName: assets.text.categoriesPage_provider_merkur_games_label,
			classificationName: 'provider_merkur'
		},
		{
			categoryName: assets.text.categoriesPage_holle_games_label,
			classificationName: 'provider_hoelle_games'
		}, {
			categoryName: assets.text.categoriesPage_provider_relax_games_label,
			classificationName: 'provider_relax'
		}
		];

		let clientEnv = this._localStorageService.get('clientEnv');
		if (clientEnv == 'staging' || clientEnv == 'dev') {
			let testObj = {
				categoryName: 'Test Games',
				classificationName: 'test_games',
				categoryImg: assets.digital.categoriesPage_gamomat_games
			};
			categoryList.push(testObj);
		}
		return categoryList;
	}

	// get Game category List
	getGameInitCategoryList(assets: any) {
		return [{
			categoryName: assets.text.categoriesPage_all_slots_label,
			classificationName: 'all'
		}, {
			categoryName: assets.text.categoriesPage_your_favorites_label,
			classificationName: 'favorites_games'
		},
		{
			categoryName: assets.text.categoriesPage_last_played_games_title,
			classificationName: 'last_played_games'
		},
		{
			categoryName: assets.text.categoriesPage_staff_picked_games_label,
			classificationName: 'staff_picked'
		},
		{
			categoryName: assets.text.categoriesPage_classic_slots_games_label,
			classificationName: 'classic_slots'
		}, {
			categoryName: assets.text.categoriesPage_megaways_games_label,
			classificationName: 'megaways'
		}, {
			categoryName: assets.text.categoriesPage_provider_greentube_games_label,
			classificationName: 'provider_greentube'
		}, {
			categoryName: assets.text.categoriesPage_provider_pragmatic_games_label,
			classificationName: 'provider_pragmatic'
		}, {
			categoryName: assets.text.categoriesPage_provider_playngo_games_label,
			classificationName: 'provider_playngo'
		}, {
			categoryName: assets.text.categoriesPage_provider_merkur_games_label,
			classificationName: 'provider_merkur'
		}, {
			categoryName: assets.text.categoriesPage_holle_games_label,
			classificationName: 'provider_hoelle_games'
		}, {
			categoryName: assets.text.categoriesPage_provider_relax_games_label,
			classificationName: 'provider_relax'
		}
		];
	}

	public getGamePlayData(queryParamUrl: any = '') {
		const gameData = this._localStorageService.get('gamePlayData');
		if (gameData) {
			if (queryParamUrl) {
				if (gameData.gameUrlName != queryParamUrl) {
					this.setGamePlayData(null);
					return null;
				}
			}
			return gameData;
		} else {
			return null;
		}
	}

	isGameContent(gameData: any, paramName: any, byDefaultVal: any = '') {
		let content = byDefaultVal;
		if (gameData.textAssets) {
			if (gameData.textAssets.TEXT) {
				content = gameData.textAssets.TEXT[paramName] || '';
			}
		}
		return content;
	}

	getAllGames(gamesClassification: any = {}, queryParam: any = '') {
		let tmpArr = gamesClassification || [];
		let tmpObj = {};

		if (tmpArr.length && queryParam) {
			tmpArr.map((val: any) => {
				if (val.gameUrlName) {
					if (val.gameUrlName == queryParam) {
						tmpObj = val;
					}
				}
			});
		}

		let playerDetails = this._localStorageService.get('player');
		if (playerDetails) {
			tmpObj['playMode'] = 'real';
		} else {
			tmpObj['playMode'] = 'fun';
		}
		tmpObj['isDetailPage'] = true;
		return tmpObj;
	}

	public setSearchGameList(val: any) {
		this.searchGameList = val;
	}

	public getSearchGameList() {
		return this.searchGameList;
	}

	// get GT Game launch Data
	getRealityStatus(): Observable<any> | any {
		const apiUrl = 'reality/v1/status';
		return this._apiService.request('get', apiUrl, {}, {}, false);
	}

	// startRealityCounterApi
	startRealityCounterApi(param: any): Observable<any> | any {
		const apiUrl = 'reality/startCounter';
		return this._apiService.request('get', apiUrl, {}, param, true);
	}

	public setIsGamePlaying(val: any) {
		this.gamePlayEmitter.emit(val);
		this.isGamePlaying = val;
	}

	public getIsGamePlaying() {
		return this.isGamePlaying;
	}

	getClassificationWithMobile(isMobile: any, pageName: any, classification: any) {
		if (isMobile) {
			return pageName[classification + '_mobile'] || [];
		}
		return pageName[classification] || [];
	}

	modifyGameArray(games: any) {
		for (let i in games) {
			let gameArr = games[i];
			gameArr.map((val: any) => {
				if (!val.classficationGridSize) {
					val['classficationGridSize'] = '6';
				}

				if (!val.gameImageResolutionValue) {
					val['gameImageResolutionValue'] = '1x1';
				}
			});
		}
		return games;
	}

	getProvidersArr() {
		let gameProvider = this._configService.getAllowedGameProviders();
		let arr = [];
		for (let i in gameProvider) {
			let provider = gameProvider[i];
			if (provider == '1x2Gaming') {
				arr.push({ title: '1x2 Games', provider: provider })
			} else if (provider == 'Merkur') {
				arr.push({ title: 'Merkur Games', provider: provider })
			} else if (provider == 'Greentube') {
				arr.push({ title: 'Greentube Games', provider: provider })
			} else if (provider == 'Relax') {
				arr.push({ title: 'Relax Gaming', provider: provider })
			} else if (provider == 'OryxGaming') {
				arr.push({ title: 'Oryx Gaming', provider: provider })
			} else if (provider == 'Pragmatic') {
				arr.push({ title: 'Pragmatic', provider: provider })
			} else if (provider == 'Playngo') {
				arr.push({ title: 'Playngo', provider: provider })
			}
		}
		return arr;
	}

	gameLunchErrorCode(code: any, assets: any) {
		switch (code) {
			case 404:
				return assets.errormsg.gamePlay_launch_error_game_not_known;
			case 1079:
				return assets.errormsg.gamePlay_launch_error_invalid_game_provider;
			case 2000:
				return assets.errormsg.gamePlay_launch_error_json_null_or_malformed;
			case 2006:
				return assets.errormsg.gamePlay_launch_error_configuration_off_or_not_found;
			case 2011:
				return assets.errormsg.gamePlay_launch_error_gameid_not_provided;
			case 8242:
				return assets.errormsg.gamePlay_launch_error_game_missing_or_invalid_provider;
			case 8266:
				return assets.errormsg.gamePlay_launch_error_games_related_flags_disabled;
			case 9240:
				return assets.errormsg.gamePlay_launch_error_player_should_over_21_year;
			case 8300:
				return assets.errormsg.gamePlay_launch_error_game_service_unavailable;
			case 8301:
				return assets.errormsg.gamePlay_launch_error_player_email_not_confirmed;
			case 8296:
				return assets.errormsg.gamePlay_launch_error_cannot_access_area_as_your_account_banned;
			case 9512:
				return assets.errormsg.gamePlay_launch_error_you_are_enabled_the_panic;
			case 9527:
				return assets.errormsg.gamePlay_launch_error_duplicate_tab;
			case 9857:
				return assets.errormsg.gamePlay_launch_error_cruks_service_not_allowed;
			case 8291:
				return assets.errormsg.gamePlay_launch_kyc_lavel_error;
			case 9524:
				return assets.errormsg.gamePlay_launch_error_reached_reality_check;
			case 9225:
				return assets.errormsg.gamePlay_launch_error_action_not_allowed_aml_flag;
			case 1009:
				return assets.errormsg.gamePlay_launch_error_oasis_service_down;
		}
	}

	transactionOverviewModel() {
		let transModal = this.dialog.open(TransactionOverviewModalComponent, {
			backdropClass: 'popupBackdropClass',
			data: {},
			disableClose: true
		});
		transModal.afterClosed().subscribe(result => {
			window.scrollTo(0, 0);
		});
		return transModal;
	}

	checkGamesScrollerOverflow(element: HTMLElement) {
		if (!element) {
			return false;
		}
		return element.scrollWidth > element.clientWidth;
	}

	canGamesScrollStart(element: HTMLElement): any {
		return element.scrollLeft > 0;
	}

	canGamesScrollEnd(element: HTMLElement): any {
		return element.scrollLeft + element.clientWidth != element.scrollWidth;
	}

	scrollGamesOnClick(element: HTMLElement, direction: number): any {
		element.scrollLeft += 254 * direction;
	}

	replaceGameNameInUrl(url: any): any {
		const displayName = url || '';
		return displayName.toLowerCase().replace(/ /g, '-').replace(/'/g, '');
	}


}
