import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Rx';

// Services
import { ApiService } from './api.service';
import { ConfigService } from './config.service';

@Injectable()

export class BankDetailsService {
    constructor(
        private _apiService: ApiService
    ) { }

    // Get Bank Details API
    getBankDetails(data: any): Observable<any> | any {
        const apiUrl = 'playerProfile/getBankDetails';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // Save Bank Details API
    saveBankDetails(data: any): Observable<any> | any {
        const apiUrl = 'v2/profile/saveBankDetails';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // Initiate IBAN Verification API
    initiateIBANVerificationAPi(): Observable<any> | any {
        const apiUrl = 'payment/initiateIBANVerification';
        return this._apiService.request('get', apiUrl, {}, {}, true);
    }

    // Verify IBAN API
    verifyIBANApi(data: any): Observable<any> | any {
        const apiUrl = 'payment/verifyIBAN';
        return this._apiService.request('get', apiUrl, {}, data, true);
    }

    // Verify IBAN API
    enableManualIBANVerificationApi(): Observable<any> | any {
        const apiUrl = 'payment/enableManualIBANVerification';
        return this._apiService.request('get', apiUrl, {}, {}, true);
    }

    // Get Player Active/Inactive BankDetails API
    getPlayerActiveInactiveBankDetailsApi(): Observable<any> | any {
        const apiUrl = 'payment/getPlayerActiveInactiveBankDetails';
        return this._apiService.request('get', apiUrl, {}, {}, true);
    }
}
