import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';

// Services
import { ApiService } from './api.service';
import { GeneralService } from './general.service';
import { LanguageService } from './language.service';

// Modals
import { VerificationModalComponent } from '../directive/verification-modal/verification-modal.component';
import { LugasLimitModalComponent } from '../directive/lugas-limit-modal/lugas-limit-modal.component';


@Injectable()

export class RegistrationService {
    public regStep1Data: any = {};
    public regStep2Data: any = {};
    public regStep3Data: any = {};
    public regStep4Data: any = {};
    public regStepEnabled: any = {};
    public countryDataEmitter: EventEmitter<any> = new EventEmitter();
    public countryPrefixData: any = [];
    public countryData: any = [];

    constructor(
        public dialog: MatDialog,
        private _apiService: ApiService,
        private _generalService: GeneralService,
        private _languageService: LanguageService
    ) { }

    // saveRegStep1 API
    saveRegStep1(data: any): Observable<any> | any {
        let apiUrl = 'v1/register/v2/regOne';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // saveRegStep2 API
    saveRegStep2(data: any): Observable<any> | any {
        let apiUrl = 'v1/register/v2/regTwo';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }
    // saveRegStep3 API
    saveRegStep3(data: any): Observable<any> | any {
        let apiUrl = 'v1/register/v2/regThree';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // eKycPlayerData API
    saveeKycPlayerData(data: any): Observable<any> | any {
        let apiUrl = 'register/geteKycPlayerData';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // eKycPlayerData API
    saverm3(data: any): Observable<any> | any {
        let apiUrl = 'register/rm3';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // glreg API
    glregApi(data: any): Observable<any> | any {
        let apiUrl = 'v1/register/glreg';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // countrylocalisation API
    getCountryLocalisations(data: any): Observable<any> | any {
        const apiUrl = 'countryLanguageLocalisation';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // countrylocalisation API
    getCityZipCodeApi(data: any): Observable<any> | any {
        const apiUrl = 'getcityzipcode';
        return this._apiService.request('get', apiUrl, {}, data, true, false);
    }

    // German Autocomplete API
    germanAutocompletePostAuthApi(): Observable<any> | any {
        let apiUrl = 'postdirect/postauth';
        return this._apiService.request('get', apiUrl, {}, {}, true, false);
    }

    // Get PXL Transaction API
    getPxlTransactionUrlApi(): Observable<any> | any {
        let apiUrl = 'pxlData/getPxlTransactionUrl';
        return this._apiService.request('get', apiUrl, {}, {}, true, true);
    }

    // getSmsCode API
    getSmsCodeApi(data: any): Observable<any> | any {
        const apiUrl = 'v1/register/send/smsCode';
        return this._apiService.request('post', apiUrl, data, {}, true, false);
    }

    // German Autocomplete API
    germanAutocompleteApi(apiUrl: any, params: any, queryParam: any): Observable<any> | any {
        return this._apiService.germanAutocompleteApiRequest('get', apiUrl, {}, queryParam, true, false);
    }

    setRegStepData(stepVarName: any, val: any) {
        this[stepVarName] = val;
    }

    getRegStepData(stepVarName: any) {
        if (!this[stepVarName]) {
            return;
        }
        if (Object.keys(this[stepVarName]).length <= 0) {
            this[stepVarName] = null;
        }
        return this[stepVarName];
    }

    setRegStepEnabled(data: any) {
        this.regStepEnabled = data;
    }

    getRegStepEnabled() {
        return this.regStepEnabled;
    }

    // Country Data
    setCountryData(val: any) {
        this.countryData = val;
    }

    getCountryData() {
        let tmpArr = this.countryData;
        tmpArr.sort((a: any, b: any) => {
            return a.countryName.localeCompare(b.countryName);
        });

        return tmpArr;
    }

    // SET GET CountryPrefix Data
    setCountryPrefixData(val: any) {
        this.countryPrefixData = val;
    }

    getCountryPrefixData() {
        return this.countryPrefixData;
    }

    dateFormat(date: any) {
        let dateObj = new Date(date);
        let day: any = dateObj.getDate();
        let month: any = dateObj.getMonth() + 1;
        let year = dateObj.getFullYear();
        let dateFormat = this.addZeroBefore(day) + '/' + this.addZeroBefore(month) + '/' + year;
        return dateFormat;
    }

    addZeroBefore(n: any) {
        return (n < 10 ? '0' : '') + n;
    }

    getRegStep1Data(playerProfileDetail: any) {
        let step1Data = {
            emailId: playerProfileDetail.emailAddress,
            password: playerProfileDetail.password,
            isQuestionChecked: playerProfileDetail.isQuestionChecked,
            securityQuestion: playerProfileDetail.securityQuestion,
            securityAnswer: playerProfileDetail.securityAnswer
        };
        return step1Data;
    }

    getRegStep2Data(playerProfileDetail: any) {
        let step2Data = {
            gender: playerProfileDetail.gender,
            firstName: playerProfileDetail.firstName,
            lastName: playerProfileDetail.lastName,
            isLastNameChanged: playerProfileDetail.isLastNameChanged,
            birthName: playerProfileDetail.birthName,
            dob: this.dateFormat(playerProfileDetail.dob),
            placeOfBirth: playerProfileDetail.placeOfBirth,
            phonePrefix: playerProfileDetail.phonePrefix,
            phoneNumber: playerProfileDetail.playerPhone,
            street: playerProfileDetail.street,
            houseNumber: playerProfileDetail.houseNumber,
            postCode: playerProfileDetail.postCode,
            townCity: playerProfileDetail.townCity,
            countryCode: '',
            newsletterOptIn: false,
            confirmEmailPageUrl: '',
            tnc: true,
            privacyPolicy: true
        };
        return step2Data;
    }

    getRegStep3Data(playerProfileDetail: any) {
        let step3Data = {
            emailId: playerProfileDetail.emailId,
            accountHolder: playerProfileDetail.accountHolder,
            ibanNumber: playerProfileDetail.ibanNumber,
            limitAmount: playerProfileDetail.limitAmount,
            newsletterOptIn: playerProfileDetail.newsletterOptIn
        };
        return step3Data;
    }

    loadCountryLocalisation(orderBy: string = 'countryName') {
        let params = {
            languageCode: this._languageService.getSelectedLanguage(),
            orderBy
        };

        this.getCountryLocalisations(params).subscribe((res: any) => {
            try {
                let data = JSON.parse(res.message) || [];
                if (params.orderBy === 'phonePrefix') {
                    this.setCountryPrefixData(data);
                }
                this.setCountryData(data);
                this.countryDataEmitter.emit(true);
            } catch (e) {
                console.log('exception country localisation', e);
            }
        }, (err: any) => {
            let errorBodyMsg = '';
            if (err && err.errors) {
                if (err && err.errors.length) {
                    let errorObj = err.errors[0];
                    errorBodyMsg = errorObj.errorMessage;
                } else {
                    errorBodyMsg = err.message;
                }
            } else {
                errorBodyMsg = err.message;
            }

            if (!errorBodyMsg) {
                errorBodyMsg = 'etwas ist schief gelaufen, bitte versuchen Sie es später noch einmal';
            }
            this._generalService.toastError(errorBodyMsg);
        });
    }

    public registrationErrorHandler(error: any, assets: any) {
        let code = null
        let errorCode = null;
        let errorBodyMsg = '';
        if (error && error.errors.length) {
            code = error.errors[0];
            errorCode = +code.errorCode;
            errorBodyMsg = this.regErrorCode(errorCode, assets);
        } else {
            errorCode = +error.errorCode;
            errorBodyMsg = this.regErrorCode(errorCode, assets);
        }

        if (!errorBodyMsg) {
            errorBodyMsg = assets.errormsg.headerPage_error_common_msg_text;
        }
        this._generalService.toastError(errorBodyMsg);
    }

    regErrorCode(code: any, assets: any) {
        switch (code) {
            case 1089:
                return assets.errormsg.registerPage_error_password_pattern_invalid;
            case 1073:
                return assets.errormsg.registerPageAssets_email_error_email_not_unique;
            case 1074:
                return assets.errormsg.registerPage_error_username_not_unique;
            case 1075:
                return assets.errormsg.registerPage_error_fname_lname_address_not_unique;
            case 1076:
                return assets.errormsg.registerPage_error_fname_lname_dob_not_unique;
            case 1077:
                return assets.errormsg.registerPageAssets_about_error_phoneNo_unique;
            case 1082:
                return assets.errormsg.registerPage_error_security_number_already_used;
            case 1092:
                return assets.errormsg.registerPage_error_email_invalid;
            case 1096:
                return assets.errormsg.registerPage_error_username_format_invalid;
            case 1097:
                return assets.errormsg.registerPage_error_postalCode_invalid;
            case 2000:
                return assets.errormsg.registerPage_error_json_malformed;
            case 3003:
                return assets.errormsg.registerPage_error_confirm_email_url_required;
            case 9220:
                return assets.errormsg.registerPage_error_document_fileSize_limit_exceeded;
            case 1041:
                return assets.registerPage_error_limit_data_required;
            case 9241:
                return assets.errormsg.registerPage_error_document_fileExtension_invalid;
            case 1053:
                return assets.errormsg.registerPage_error_limit_not_set;
            case 9265:
                return assets.errormsg.registerPage_error_firstName_invalid;
            case 9266:
                return assets.errormsg.registerPage_error_lastName_invalid;
            case 1045:
                return assets.errormsg.registerPageAssets_email_error_dob_not_valid;
            case 1080:
                return assets.errormsg.registerPageAssets_email_error_18_years_old;
            case 8192:
                return assets.errormsg.registerPage_error_document_required;
            case 8283:
                return assets.errormsg.registerPageAssets_error_limit_min_max_range;
            case 1184:
                return assets.registerPage_error_social_security_number_invalid;
            case 9529:
                return assets.registerPage_error_birth_place_format_invalid;
            case 9878:
                return assets.errormsg.registerPageAssets_error_already_used;
            case 9509:
                return assets.errormsg.registerPage_error_risky_country_msg;
            case 1009:
                return assets.errormsg.registerPage_error_oasis_service_down_msg;
            case 2006:
                return assets.errormsg.registerPageAssets_error_msg_2006;
            case 1015:
                return assets.errormsg.registerPageAssets_error_msg_1015;
            case 5010:
                return assets.errormsg.registerPageAssets_error_msg_5010;
            case 9559:
                return assets.errormsg.registerPageAssets_error_msg_9559;
            case 9560:
                return assets.errormsg.registerPageAssets_error_msg_9560;
            case 82503:
                return assets.errormsg.registerPageAssets_error_msg_82503;
            case 8213:
                return assets.errormsg.registerPageAssets_error_msg_8213;
            case 9244:
                return assets.errormsg.registerPageAssets_error_msg_9244;
            case 9782:
                return assets.errormsg.registerPageAssets_error_msg_9782;
            case 9235:
                return assets.errormsg.registerPageAssets_error_msg_9235;
            case 8252:
                return assets.errormsg.registerPageAssets_error_msg_8252;
        }
    }

    verificationmodel(title: string, msg: string, okBtn: any = null, cancelBtn: any = null) {
        return this.dialog.open(VerificationModalComponent, {
            backdropClass: 'popupBackdropClass',
            data: {
                msgTitle: title,
                msgBody: msg,
                msgOkBtn: okBtn,
                msgCancelBtn: cancelBtn
            },
            disableClose: true
        });
    }

    openLugasLimitModal() {
        this.dialog.closeAll();
        return this.dialog.open(LugasLimitModalComponent, {
            backdropClass: 'popupBackdropClass',
            data: {},
            disableClose: true
        });
    }
}
