import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

// Services
import { RoutingService } from 'src/app/services/routing.service';
import { DataService } from 'src/app/services/data.service';
import { GeneralService } from 'src/app/services/general.service';
import { PlayerProfileService } from 'src/app/services/player-profile.service';
import { RegistrationService } from '../../services/registration.service';
import { GoogleAnalitycsService } from '../../services/google-analitycs.service';

declare let jQuery: any;

@Component({
    selector: 'verification-modal',
    templateUrl: './verification-modal.component.html',
    styleUrls: ['./verification-modal.component.scss']
})

export class VerificationModalComponent implements OnInit, AfterViewInit {
    // Public Var
    public infoData;
    public registerPageAssets: any;
    public playerCurrencySymbol: any;
    public transactionData: any = {};
    public regulationsStatus = <any>{};
    public schufaAgreeIsChecked: boolean = false;
    public userProfileIdVerificationAssets: any;

    // Private Var
    private pageDataEmitter: any = null;
    private regulationsStatusService: any = null;
    private getPostIdentDocService: any = null;
    private schufaVerificationService: any = null;
    private pxlTransactionUrlApiSub: any = null;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public dialogRef: MatDialogRef<VerificationModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private _routingService: RoutingService,
        private _dataService: DataService,
        private _generalService: GeneralService,
        private _playerProfileService: PlayerProfileService,
        private _registrationService: RegistrationService,
        private _googleAnalitycsService: GoogleAnalitycsService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.infoData = data;
    }

    ngOnInit() {
        this.registerPageAssets = this._dataService.getAssets('registerPage');
        this.userProfileIdVerificationAssets = this._dataService.getAssets('userProfileIdVerificationPage');
        this.pageDataEmitter = this._dataService.pageDataLoadEmitter.subscribe((res) => {
            this.registerPageAssets = this._dataService.getAssets('registerPage');
            this.userProfileIdVerificationAssets = this._dataService.getAssets('userProfileIdVerificationPage');
        });
        this.loadRegulationsStatus();
    }

    loadRegulationsStatus() {
        this.regulationsStatusService = this._playerProfileService.getRegulationsStatus().subscribe((res: any) => {
            let data = res || {};
            let message = res.message || {};
            if (message) {
                this.regulationsStatus = message;
                if (isPlatformBrowser(this.platformId)) {
                    jQuery('[data-toggle="tooltip"]').tooltip();
                }
            }
        }, (err: any) => {
            console.log('err---', err)
        });
    }

    ngAfterViewInit() {
        if (isPlatformBrowser(this.platformId)) {
            jQuery('body').addClass('modal-open');
            setTimeout(() => {
                jQuery('[data-toggle="tooltip"]').tooltip();
            }, 1000);
        }
    }

    checkSchufaStatus() {
        this.schufaVerificationService = this._playerProfileService.schufaVerificationApi().subscribe((res: any) => {
            let message = res.message || {};
            if (message.identityStatus) {
                this._googleAnalitycsService.trackingUserAction('event', 'confirm_id');
                this._generalService.infomodel(this.userProfileIdVerificationAssets.errormsg.userIdVerificationPage_schufaVerifyModal_title, this.userProfileIdVerificationAssets.errormsg.userIdVerificationPage_schufaVerifyModal_success_msg, this.userProfileIdVerificationAssets.errormsg.userIdVerificationPage_schufaVerifyModal_ok_btn);
            } else {
                this._generalService.infomodel(this.userProfileIdVerificationAssets.errormsg.userIdVerificationPage_schufaVerifyModal_title, this.userProfileIdVerificationAssets.errormsg.userIdVerificationPage_schufaVerifyModal_error_msg, this.userProfileIdVerificationAssets.errormsg.userIdVerificationPage_schufaVerifyModal_ok_btn);
            }
            this.loadRegulationsStatus();
        }, (err: any) => {
            console.log('err---', err)
        });
    }

    formularDownload() {
        this.getPostIdentDocService = this._playerProfileService.getPostIdentDocApi().subscribe((res: any) => {
            let data = JSON.parse(res.message) || {};
            if (res.success) {
                this._generalService.pdfDownload(data.pdfStream, data.pdfFileName);
            } else {
                if (res.errors.length) {
                    let errArr = res.errors;
                    errArr.map((val: any) => {
                        this._generalService.toastError(val.errorMessage);
                    });
                } else {
                    this._generalService.toastError(res.message);
                }
            }
        }, (err: any) => {
            if (err.errors.length) {
                let errArr = err.errors;
                errArr.map((val: any) => {
                    this._generalService.toastError(val.errorMessage);
                });
            } else {
                this._generalService.toastError(err.message);
            }
        });
    }

    modalClose() {
        if (isPlatformBrowser(this.platformId)) {
            jQuery('body').removeClass('modal-open');
        }
        this.dialogRef.close();
    }

    verifyLater() {
        let text = this.userProfileIdVerificationAssets.text.userIdVerificationPage_modal_verify_later_msg_text1 +
            this.userProfileIdVerificationAssets.text.userIdVerificationPage_modal_verify_later_msg_text2;
        let verifyLaterModel = this._generalService.infomodel(
            this.userProfileIdVerificationAssets.text.userIdVerificationPage_modal_verify_later_title,
            text,
            this.userProfileIdVerificationAssets.text.userIdVerificationPage_modal_verify_later_back_btn_label,
            this.userProfileIdVerificationAssets.text.userIdVerificationPage_modal_verify_later_yes_btn_label,
            'verifyLaterModal'
        );
        verifyLaterModel.afterClosed().subscribe((result: any) => {
            if (!result) {
                this.modalClose();
                this._routingService.altRouterLink('home');
            }
        });
    }

    loadPXLVerificationApi() {
        this.pxlTransactionUrlApiSub = this._registrationService.getPxlTransactionUrlApi().subscribe((res: any) => {
            let data = res || {};
            if (data.success) {
                let message = JSON.parse(data.message);
                if (message.pxlURL) {
                    window.open(message.pxlURL, '_blank');
                    this.modalClose();
                }
            } else {
                if (data.message) {
                    this._generalService.toastError(data.message);
                }

            }
        }, (err: any) => {
            if (err.errors) {
                if (err.errors.length) {
                    let errArr = err.errors;
                    errArr.map((val: any) => {
                        this._generalService.toastError(val.errorMessage);
                    });
                } else {
                    this._generalService.toastError(err.message);
                }
            }
        });
    }

    ngOnDestroy() {
        if (isPlatformBrowser(this.platformId)) {
            jQuery('body').removeClass('modal-open');
        }
        if (this.pageDataEmitter) {
            this.pageDataEmitter.unsubscribe();
        }
        if (this.regulationsStatusService) {
            this.regulationsStatusService.unsubscribe();
        }
        if (this.getPostIdentDocService) {
            this.getPostIdentDocService.unsubscribe();
        }
        if (this.pxlTransactionUrlApiSub) {
            this.pxlTransactionUrlApiSub.unsubscribe();
        }
        if (this.schufaVerificationService) {
            this.schufaVerificationService.unsubscribe();
        }
    }

}
