<header class="main-header sticky-top mobile-top-menu"
    [ngClass]="{'mb-0': (isPlayGame || (router.url == _routingService.altRouterLinkActive('home')) || (router.url == _routingService.altRouterLinkActive('about')) || (router.url == _routingService.altRouterLinkActive('faq')) || (router.url == _routingService.altRouterLinkActive('game-list')) || (router.url == _routingService.altRouterLinkActive('search')) || (router.url == _routingService.altRouterLinkActive('live-landing')) || (router.url == _routingService.altRouterLinkActive('pre-registration-identification-successful')) || (router.url == _routingService.altRouterLinkActive('pre-registration-email-successfully-confirmed')))}">
    <div class="container" *ngIf="headerPageAssets">
        <nav class="navbar navbar-expand-lg navbar-light">
            <!-- <a class="back-header d-none">
                <span class="icon" [inlineSVG]="homePageAssets?.digital.homePage_left_arrow_back"
                    [removeSVGAttributes]="['xmlns']"></span>
            </a> -->
            <a class="navbar-brand" href="{{_routingService.altRouterLinkActive('home')}}"
                (click)="goToHomePage($event)">
                <img class="logomain" alt="logo" [src]="headerPageAssets.digital.headerPage_logo">
                <img class="mobilelogo" alt="logo" [src]="headerPageAssets.digital.headerPage_logo_icon">
            </a>
            <ng-container>
                <div class="right-menu">
                    <ul class="nav header-item d-none-device d-xl-flex headermenu">
                        <li class="nav-item"
                            [hidden]="this.router.url == this._routingService.altRouterLinkActive('live-landing')">
                            <a class="nav-link" (click)="onTapMenu($event, 'game-list')"
                                href="{{_routingService.altRouterLinkActive('game-list')}}"
                                [ngClass]="{'is-active':(router.url == _routingService.altRouterLinkActive('game-list'))}">
                                <span [innerHTML]="headerPageAssets.text.headerPage_all_games_menu"></span>
                            </a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" [href]="headerPageAssets.text.headerPage_support_menu_link">
                                <span [innerHTML]="headerPageAssets.text.headerPage_support_menu"></span>
                            </a>
                        </li>
                    </ul>
                    <ul class="nav header-item align-items-center">
                        <li class="nav-item d-xl-flex"
                            [hidden]="this.router.url == this._routingService.altRouterLinkActive('live-landing')">
                            <a class="nav-link search-icon" (click)="onTapMenu($event, 'search')"
                                href="{{_routingService.altRouterLinkActive('search')}}"
                                [ngClass]="{'is-active':(router.url == _routingService.altRouterLinkActive('search'))}">
                                <!-- <span class="icon mr-0" [inlineSVG]="'assets/images/icons/search-footer.svg'" [removeSVGAttributes]="['xmlns']">
                                </span> -->
                                <span></span>
                            </a>
                        </li>
                        <li *ngIf="!isLogin && isBrowser">
                            <a class="btn-main" (click)="onTapMenu($event, 'register')"
                                href="{{_routingService.altRouterLinkActive('register')}}">
                                <span [innerHTML]="homePageAssets.text.homePage_register_btn_lebel"></span>
                            </a>
                        </li>
                        <li class="start-playing" *ngIf="!isLogin && isBrowser">
                            <a class="btn-theme-outline" (click)="onTapMenu($event, 'login')"
                                href="{{_routingService.altRouterLinkActive('login')}}">
                                <span [innerHTML]="headerPageAssets.text.headerPage_login_menu"></span>
                            </a>
                        </li>
                        <li class="nav-item user-menu with-count" *ngIf="isLogin">
                            <a class="nav-link" href="{{_routingService.altRouterLinkActive('user/deposit')}}"
                                (click)="goToUserModule($event, 'user/deposit')">
                                <span class="count-text" *ngIf="!gamePlayRouteUrl">{{currency.currencySign}}
                                    {{_dataService.showCommaVal(loggedUserDetail.totalMoney)}} </span>
                                <!-- <span class="icon-deposit" [inlineSVG]="headerPageAssets?.digital.headerPage_userDeposit_icon" [removeSVGAttributes]="['xmlns']"></span> -->
                                <img class="icon-deposit" [src]="headerPageAssets?.digital.headerPage_userDeposit_icon">
                            </a>
                        </li>
                        <li class="nav-item user-menu with-count account" *ngIf="isLogin">
                            <a class="nav-link" href="{{_routingService.altRouterLinkActive('user/overview')}}"
                                (click)="goToUserModule($event, 'userMenu')">
                                <!-- <span class="icon" [inlineSVG]="headerPageAssets?.digital.headerPage_user_round_icon" [removeSVGAttributes]="['xmlns']"></span> -->
                            </a>
                        </li>
                    </ul>
                </div>
            </ng-container>
        </nav>
    </div>
    <div class="chat-main" (click)="openLiveChat()">
        <!-- <span class="icon" [inlineSVG]="headerPageAssets?.digital.headerPage_chat_icon" [removeSVGAttributes]="['xmlns']"></span> -->
    </div>
</header>