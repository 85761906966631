import { Component, AfterViewInit, OnInit, OnDestroy, ElementRef, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

// Services
import { DataService } from '../../../services/data.service';
import { RoutingService } from '../../../services/routing.service';
import { ConfigService } from 'src/app/services/config.service';
import { LanguageService } from 'src/app/services/language.service';
import { GoogleAnalitycsService } from '../../../services/google-analitycs.service';

declare let jQuery: any;
declare let $zopim: any;

@Component({
    selector: 'app-main-footer',
    templateUrl: './main-footer.component.html',
    styleUrls: ['./main-footer.component.scss']
})

export class MainFooterComponent implements AfterViewInit, OnInit, OnDestroy {
    // Public Var
    public pageData: any = {};
    public footerPageAssets: any;
    public languagesArr: any = [];
    public selectedLanguage: any;
    public isMobile: boolean = false;
    public socialIconLists: any = [];

    // Private Var
    private loginEmitter: any = null;
    private pageDataEmitter: any = null;
    private isMobileEmitter: any = null;

    constructor(
        public el: ElementRef,
        public route: ActivatedRoute,
        public router: Router,
        public _routingService: RoutingService,
        private _dataService: DataService,
        private _languageService: LanguageService,
        private _configService: ConfigService,
        private _googleAnalitycsService: GoogleAnalitycsService,
        @Inject(PLATFORM_ID) private platformId: Object,
    ) {
        this.isMobile = this._dataService.isMobile();
    }

    ngOnInit() {
        this.languagesArr = this._configService.getLanguages();
        this.selectedLanguage = this._languageService.getSelectedLanguage();
        this.pageData = this._dataService.getPagesData();
        this.footerPageAssets = this._dataService.getAssets('footerPage');
        this.pageDataEmitter = this._dataService.pageDataLoadEmitter.subscribe((res) => {
            this.footerPageAssets = this._dataService.getAssets('footerPage');
            this.loadInitData();
        });
        this.loginEmitterCalled();
        this.isMobileEmitter = this._dataService.isMobileEmitter.subscribe((res) => {
            this.isMobile = res;
        });
    }

    loadInitData() {
        if(!this.footerPageAssets) {
            return;
        }
        let digital = this.footerPageAssets?.banner;
        let socialIconArray = [];
        for (let i = 1; i <= 50; i++) {
            let icon = 'socialicon' + i;
            if (digital[icon]) {
                let bannerName = digital[icon]['bannerUrl'];
                let tmp: any = {
                    icon: digital[icon]['bannerPath'] || '',
                    socialURL: bannerName,
                    isExpand: false
                };
                socialIconArray.push(tmp);
            }
        }
        this.socialIconLists = socialIconArray;
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.clickToEle();
        }, 2000);
    }

    loginEmitterCalled() {
        this.loginEmitter = this._configService.loginEmitter.subscribe((data) => {
            this.selectedLanguage = this._languageService.getSelectedLanguage();
        });
    }

    changeLanguage(languageCode: any) {
        this._languageService.setSelectedLanguage(languageCode);
        this._languageService.loadLanguageData(true);
        this.selectedLanguage = this._languageService.getSelectedLanguage();
    }

    goToGameList(subProviderName: any) {
        this._routingService.setGameListPrevRoute({ url: 'home' });
        this._routingService.altRouterLink('game-list', { categorie: subProviderName });
        this.onTapIcon(subProviderName);
    }

    onTapIcon(arg: any) {
        this._googleAnalitycsService.trackingUserAction('event', 'heatmap', { 'dimension': arg });
    }

    openLiveChat() {
        $zopim.livechat.window.show();
        this._googleAnalitycsService.trackingUserAction('event', 'use_chat');
    }

    clickToEle() {
        if (isPlatformBrowser(this.platformId)) {
            try {
                const me = this;
                const openLiveChat = jQuery('.openChat');
                if (openLiveChat.length) {
                    openLiveChat.click(() => {
                        me.openLiveChat()
                    });
                }
            } catch (e) {

            }
        }
    }

    redirectToUrl(event: any, arg: any) {
        event.preventDefault();
        if (!arg) {
            return;
        }
        if (arg.includes('https')) {
            if (isPlatformBrowser(this.platformId)) {
                window.open(arg, '_blank');
            }
        } else {
            this._routingService.altRouterLink(arg);
        }
    }

    onTapFooterLinks(event: any, arg: any) {
        if (isPlatformBrowser(this.platformId)) {
            event.preventDefault();
            this._routingService.altRouterLink(arg);
        }
    }

    ngOnDestroy() {
        if (this.loginEmitter) {
            this.loginEmitter.unsubscribe();
        }
        if (this.isMobileEmitter) {
            this.isMobileEmitter.unsubscribe();
        }
        if (this.pageDataEmitter) {
            this.pageDataEmitter.unsubscribe();
        }
    }

}
