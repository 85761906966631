import { Pipe, PipeTransform } from '@angular/core';
import { ConfigService } from '../services/config.service';
import { DataService } from '../services/data.service';

@Pipe({ name: 'gameFilter', pure: false })
export class GameFilterPipe implements PipeTransform {
  constructor(public _configService: ConfigService,
    public _dataService: DataService) {
  }
  transform(games: any[]): any {
    if (games) {
      let gameProvider = this._configService.getAllowedGameProviders();
      const isMobile = this._dataService.isMobile();
      return games.filter(game => {
        if (!game.gameImageResolutionValue) {
          game.gameImageResolutionValue = '1x1';
        }
        // game.gameImageResolutionValue = '2x2';

        // let isMobile = this._dataService.isMobile();
        // if(!isMobile) {
        //   return ((gameProvider.indexOf(game.providerName) !== -1) && (game.gamePlatform == 'Desktop' || game.gamePlatform == 'Mobile'));
        // } else {
        //   return ((gameProvider.indexOf(game.providerName) !== -1) && (game.isMobile) && (game.gamePlatform == 'Desktop' || game.gamePlatform == 'Mobile'));
        // }

        // if (game.providerName == 'Greentube' || game.providerName == 'OryxGaming') {
        //   return ((gameProvider.indexOf(game.providerName) !== -1) && (game.gamePlatform == 'Desktop' || game.gamePlatform == 'Mobile'));
        // }

        // if (game.providerName == 'Greentube' || game.providerName == 'OryxGaming') {
        //   return ((gameProvider.indexOf(game.providerName) !== -1));
        // }

        // if (isMobile == true) {
        //   return ((gameProvider.indexOf(game.providerName) !== -1) && (game.gamePlatform == 'Mobile'));
        // } else {
        //   return ((gameProvider.indexOf(game.providerName) !== -1) && (game.gamePlatform == 'Desktop'));
        // }

        return ((gameProvider.indexOf(game.providerName) !== -1));
        
        // if (isMobile == true) {
        //   return ((gameProvider.indexOf(game.providerName) !== -1));
        // } else {
        //   return ((gameProvider.indexOf(game.providerName) !== -1));
        // }

        // return ((gameProvider.indexOf(game.providerName) !== -1) && (game.gamePlatform == 'Desktop' || game.gamePlatform == 'Mobile'));

      });
    }
  }
}
