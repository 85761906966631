<div class="modal info-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 class="modal-title headline m-0" id="myModalLabel" [innerHTML]="modalData.title"></h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'"
                            [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body pb-4">
                <div class="col-sm-12">
                    <p *ngIf="isApiProcess && !regulatoryData.length" class="text-center" [innerHTML]="registerPageRegModalAssets.text.registerPage_regulatory_modal_loading_text"></p>
                    <ng-container *ngFor="let regulatory of regulatoryData; let ind=index">
                        <p class="privacy-policy-date mb-0" *ngIf="(ind == 0) && (modalData.consentName =='DPP')"> <span [innerHTML]="registerPageRegModalAssets.text.registerPage_regulatory_modal_text"></span> &nbsp;{{regulatory.createdON | date: 'dd.MM.yyyy'}} </p>
                        <p [innerHTML]="regulatory.body"></p>
                    </ng-container>
                </div>
            </div>
            <div class="modal-footer" *ngIf="modalData.isAcceptBtn">
                <button class="btn btn-secondary btn-md" [disabled]="isApiProcess" (click)="acceptRegulatoryConsent()" [innerHTML]="registerPageRegModalAssets.text.registerPage_regulatory_modal_btn_text"></button>
            </div>
        </div>
    </div>
</div>