import { Component, OnInit, Inject } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { DataService } from 'src/app/services/data.service';
import { PlayerAccountService } from '../../services/player-account.service';
import { UserService } from "../../services/user.service";
import { GoogleAnalitycsService } from '../../services/google-analitycs.service';

declare let jQuery: any;

@Component({
    selector: 'transaction-overview-modal',
    templateUrl: './transaction-overview-modal.component.html',
    styleUrls: ['./transaction-overview-modal.component.scss']
})

export class TransactionOverviewModalComponent implements OnInit {
    // Public Var
    public infoData;
    public headerPageAssets: any;
    public transactionOverviewAssets: any;
    public playerCurrencySymbol: any;
    public playerTransSub: any = null;
    public transactionData: any = {};

    // Private Var
    private pageDataEmitter: any = null;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public dialogRef: MatDialogRef<TransactionOverviewModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        public _dataService: DataService,
        private _userService: UserService,
        private _playerAccountService: PlayerAccountService,
        private _googleAnalitycsService: GoogleAnalitycsService
    ) {
        this.infoData = data;
    }

    ngOnInit() {
        this.headerPageAssets = this._dataService.getAssets('headerPage');
        this.transactionOverviewAssets = this._dataService.getAssets('transactionOverviewModal');
        this.playerCurrencySymbol = this._userService.getPlayerCurrency();
        this.pageDataEmitter = this._dataService.pageDataLoadEmitter.subscribe((res) => {
            this.transactionOverviewAssets = this._dataService.getAssets('transactionOverviewModal');
        });
        this.loadtransacationOverview();
    }

    loadtransacationOverview() {
        this.playerTransSub = this._playerAccountService.loadtransacationOverview().subscribe((res: any) => {
            let data = res.message;
            if (data) {
                this.transactionData = JSON.parse(data);
                this._googleAnalitycsService.trackingUserAction('event', 'turnover', {stake: this._dataService.showCommaVal(this.transactionData.betAmount)});
            }
        }, (err: any) => {
            console.log('Error in loadtransacationOverview', err);
        });
    }

    mathAbs(val: any) {
        let convertedVal = Math.abs(val);
        return this._dataService.showCommaVal(convertedVal)
    }
    
    modalClose() {
        this.dialogRef.close();
    }

    ngOnDestroy() {
        if (this.playerTransSub) {
            this.playerTransSub.unsubscribe();
        }
        if (this.pageDataEmitter) {
            this.pageDataEmitter.unsubscribe();
        }
    }

}
