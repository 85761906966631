import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';

// Services
import { ApiService } from './api.service';

@Injectable()
export class AmlService {
    constructor(
        private _apiService: ApiService,
        public route: ActivatedRoute,
        public router: Router
    ) { }

    uploadDocumentAML(data: any): Observable<any> | any {
        let apiUrl = 'playerProfile/docs/compliance/uploadDocumentAML';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    uploadDocErrorCode(code: any, assets: any) {
        switch (code) {
            case 8512:
                return assets.errormsg.amlKycPage_error_document_already_exceeds;
            case 9242:
                return assets.errormsg.amlKycPage_error_uploaded_idProof_isnot_apporved;
            case 9243:
                return assets.errormsg.amlKycPage_error_uploaded_addressProof_isnot_apporved;
            case 1075:
                return assets.errormsg.amlKycPage_error_fname_lname_address_not_unique;
            case 1076:
                return assets.errormsg.amlKycPage_error_fname_lname_dob_not_unique;
            case 1077:
                return assets.errormsg.amlKycPage_error_phoneNumber_not_unique;
        };
    }
}
