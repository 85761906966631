import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LocalStorageService } from './local-storage.service';
import { CookieService } from 'ngx-cookie-service';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { ToastrModule } from 'ngx-toastr';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

// Services
import { ApiService } from './api.service';
import { AppService } from './app.service';
import { ConfigService } from './config.service';
import { GeneralService } from './general.service';
import { DataService } from './data.service';
import { AppResolver, DefaultClientLangResolver, checkRootRoutingResolver, SiteBlockResolver, CheckGamesIsAvailableResolver, AppLoginResolver, CheckPlayerLimitSettingResolver, AppDepositGuard, AdyenGuard, DepositAllowedGuard, WithdrawAllowedGuard, DuplicateTabResolver } from './app-resolve.service';
import { GamesService } from './games.service';
import { GameFilterPipe } from '../pipes/games-filter.pipe';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { UserService } from './user.service';
import { LanguageService } from './language.service';
import { AccountDataService } from './account-data.service';
import { PlayerProfileService } from './player-profile.service';
import { RoutingService } from './routing.service';
import { GoogleAnalitycsService } from './google-analitycs.service';
import { DocumentService } from './document.service';
import { SeoService } from './seo.service';
import { AmlService } from './aml.service';
import { DepositService } from './deposit.service';
import { AppVerifyUService } from './app-verifyu.service';
import { RegistrationService } from './registration.service';
import { LoginService } from './login.service';
import { GdprService } from './gdpr.service';
import { PlayerAccountService } from './player-account.service';
import { BankDetailsService } from './bank-details.service';
import { PromoService } from './promo.service';
import { AdyenDepositService } from './adyen-deposit.service';
import { AdyenWithdrawService } from './adyen-withdraw.service';
import { AmlKycModule } from '../directive/aml-kyc-modal/aml-kyc.module';

const MODULES = [
    // Do NOT include UniversalModule, HttpModule, or JsonpModule here
    CommonModule,
    RouterModule,
    FormsModule,
    ReactiveFormsModule,
    InlineSVGModule.forRoot(),
    ToastrModule.forRoot(),
    InfiniteScrollModule,
    AmlKycModule
];

const PIPES: any = [
    // put pipes here
    GameFilterPipe,
    SafeHtmlPipe
];

const COMPONENTS: any = [
    // put shared components here
];

const PROVIDERS = [
    ApiService,
    AppService,
    ConfigService,
    GeneralService,
    CookieService,
    DataService,
    AppResolver,
    DefaultClientLangResolver,
    checkRootRoutingResolver,
    SiteBlockResolver,
    AppLoginResolver,
    CheckPlayerLimitSettingResolver,
    CheckGamesIsAvailableResolver,
    AppDepositGuard,
    AdyenGuard,
    DepositAllowedGuard,
    WithdrawAllowedGuard,
    DuplicateTabResolver,
    LocalStorageService,
    GamesService,
    UserService,
    LanguageService,
    AccountDataService,
    PlayerProfileService,
    SafeHtmlPipe,
    RoutingService,
    GoogleAnalitycsService,
    DocumentService,
    SeoService,
    AmlService,
    DepositService,
    AppVerifyUService,
    RegistrationService,
    LoginService,
    GdprService,
    PlayerAccountService,
    BankDetailsService,
    PromoService,
    AdyenDepositService,
    AdyenWithdrawService
]

@NgModule({
    imports: [
        ...MODULES
    ],
    declarations: [
        ...PIPES,
        ...COMPONENTS
    ],
    exports: [
        ...MODULES,
        ...PIPES,
        ...COMPONENTS
    ]
})

export class SharedModule {
    static forRoot(): ModuleWithProviders<SharedModule> {
        return {
            ngModule: SharedModule,
            providers: [
                ...PROVIDERS
            ]
        };
    }
}
