import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';

// Services
import { UserService } from '../../../../../services/user.service';
import { GoogleAnalitycsService } from 'src/app/services/google-analitycs.service';
import { RoutingService } from 'src/app/services/routing.service';

declare let jQuery: any;

@Component({
    selector: 'adyen-message-modal',
    templateUrl: './message-modal.component.html',
    styleUrls: ['./message-modal.component.scss']
})

export class MessageModalComponent implements OnInit, OnDestroy {
    // Public Var
    public depositData;

    // Private Var
    private depositUpdateStatusAnaliticService: any = null;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        public dialogRef: MatDialogRef<MessageModalComponent>,
        @Inject(MAT_DIALOG_DATA) public data: any,
        public dialog: MatDialog,
        private _userService: UserService,
        private _routingService: RoutingService,
        private _googleAnalitycsService: GoogleAnalitycsService
    ) {
        this.depositData = data;
    }

    ngOnInit() {
        if (this.depositData.obj) {
            if (this.depositData.obj.messageType == 'validateMessage') {
                return;
            }
        }

        if (this.depositData.modelName == 'validateApiModal') {
            return;
        }

        if (this.depositData.msgType == 'success') {
            this.depositUpdateStatusAnalitic({ identifier: 'success' });
        } else if (this.depositData.msgType == 'error') {
            this.depositUpdateStatusAnalitic({ identifier: 'failed' });
        }
    }

    depositUpdateStatusAnalitic(params: any) {
        let dObj = this.depositData.obj || {};
        if (dObj.depositStatus == 'pending') {
            params.identifier = 'pending';
        }

        this.depositUpdateStatusAnaliticService = this._userService.playerDepositDeatailsApi().subscribe((res: any) => {
            let data = res || {};
            if (data.success) {
                let msgData = JSON.parse(data.message);
                let obj = {
                    transactionID: msgData.transactionId,
                    product: msgData.lastDepositAmount,
                    numberOfDeposits: msgData.totalDepositCount,
                    paymentProvider: (msgData.transactionType).toLowerCase(),
                    bonusesSelected: msgData.bonusId
                };

                if (msgData.bonusId) {
                    obj['bonusesSelected'] = msgData.bonusId;
                }

                let currency = this._userService.getPlayerCurrency();
                let tranStatus = '';
                if (msgData.transactionStatus) {
                    tranStatus = msgData.transactionStatus.toLowerCase();
                }

                if (params.identifier == 'failed') {
                    let eventObj = this.depositData.obj || {};
                    if (msgData.lastDepositAmount) {
                        eventObj['product'] = msgData.lastDepositAmount;
                    }
                    if (msgData.transactionType) {
                        eventObj['paymentProvider'] = (msgData.transactionType).toLowerCase();
                    }
                    if (msgData.bonusId) {
                        eventObj['bonusesSelected'] = msgData.bonusId;
                    }

                    eventObj['transactionCurrency'] = currency.currencyCode;
                    this._googleAnalitycsService.trackingUserAction('event', 'depositUnsuccessful', eventObj);
                } else {
                    this._googleAnalitycsService.trackingUserAction('event', 'depositSuccessful', obj);
                    let depositEcomObj = {};
                    try {
                        let sku = 'firstDeposit'
                        if (parseInt(msgData.totalDepositCount) > 1) {
                            sku = 'allDeposits';
                        } else {
                            this._googleAnalitycsService.trackingUserAction('event', 'first_deposit', obj);
                        }

                        depositEcomObj = {
                            'transactionId': msgData.transactionId,
                            'transactionAffiliation': (msgData.transactionType).toLowerCase(),
                            'transactionTotal': msgData.lastDepositAmount,
                            'transactionCurrency': currency.currencyCode,
                            'transactionProducts': [{
                                'sku': sku, // If it is a first deposit, set the firstDeposit. If it is 2nd, 3rd... deposit, set allDeposits.
                                'name': msgData.lastDepositAmount,
                                'category': 'Deposit',
                                'price': msgData.lastDepositAmount,
                                'quantity': 1
                            }],
                            'numberOfDeposits': msgData.totalDepositCount
                        };

                        if (msgData.bonusId) {
                            depositEcomObj['bonusesSelected'] = msgData.bonusId;
                        }
                    } catch (e) {
                        console.log('e--', e);
                    }
                    this._googleAnalitycsService.trackingUserAction('event', 'depositEcommerce', depositEcomObj);
                }
            }
        }, (error: any) => {
            console.log(error);
        });
    }

    modalClose(arg: string = '') {
        if (this.depositData.obj.method = 'adyen') {
            this._routingService.altRouterLink('user/deposit');
        }
        this.dialogRef.close(arg);
    }

    ngOnDestroy() {
        if (this.depositUpdateStatusAnaliticService) {
            this.depositUpdateStatusAnaliticService.unsubscribe();
        }
    }
}
