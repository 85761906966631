import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ApiService } from './api.service';
import { LanguageService } from './language.service';

@Injectable()
export class PromoService {
    public activePromotion: any = null;
    public activeMyPromotion: any = null
    public activePromotCount: any;
    public comeFromMyPromotionPage = false;
    public actibeBonusTabEmmiter: EventEmitter<any> = new EventEmitter();

    constructor(
        private _apiService: ApiService,
        private _languageService: LanguageService
    ) { }

    getAvailablePromotions(data: any): Observable<any> | any {
        let apiUrl = 'awardBonus/getBonusForPlayer';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    getActivePromotions(data: any): Observable<any> | any {
        let apiUrl = 'awardBonus/getActiveBonus';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    getPromotionHistory(data: any): Observable<any> | any {
        let apiUrl = 'awardBonus/claimedBonusData';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    getDepositAvailableBonus(data: any): Observable<any> | any {
        let apiUrl = 'awardBonus/v1/availabledepositbonuses';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    getPlayerTnc(data: any): Observable<any> | any {
        let apiUrl = 'playerTnc/view';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    cancelPromo(data: any): Observable<any> | any {
        let apiUrl = 'awardBonus/cancelBonusForPlayer';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    awardBonusApi(data: any): Observable<any> | any {
        const apiUrl = 'awardBonus';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    cancelActiveBonusWithdraw(): Observable<any> | any {
        let apiUrl = 'awardBonus/cancelActiveBonusWithdraw';
        return this._apiService.request('post', apiUrl, {}, {}, true);
    }

    setActivatePromotion(val: any) {
        this.activePromotion = val;
    }

    getActivatePromotion() {
        return this.activePromotion;
    }
    showActiveBonusTab(arg: string = 'activeBonus') {
        this.actibeBonusTabEmmiter.emit(arg);
    }

    getAvailableBonusForDepositMethod(methodName: any): Observable<any> | Promise<any> | any {
        let langCode = this._languageService.getSelectedLanguage();
        let resultPromise = new Promise((resolve, reject) => {
            let params = {
                languageCode: langCode
            };
            this.getDepositAvailableBonus(params).subscribe((res: any) => {
                let data = res.message || '';
                let bonusData = JSON.parse(data);
                let campaigns = bonusData.campaigns || [];
                campaigns.map((val: any) => {
                    if (val.name == methodName) {
                        resolve(val);
                    }
                });
            }, (err: any) => {
                reject(err);
            })
        });
        return resultPromise;
    }
}
