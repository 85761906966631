import { Component, OnInit, AfterViewInit, OnDestroy, Inject, PLATFORM_ID } from '@angular/core';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

// Services
import { ApiService } from './services/api.service';
import { ConfigService } from './services/config.service';
import { DataService } from './services/data.service';
import { UserService } from './services/user.service';
import { GeneralService } from './services/general.service';
import { RoutingService } from './services/routing.service';
import { LocalStorageService } from './services/local-storage.service';
import { LanguageService } from './services/language.service';
import { GoogleAnalitycsService } from './services/google-analitycs.service';

declare let window: any;
declare let document: any;
declare let jQuery: any;
declare let Cookiebot: any;
declare let UC_UI_SUPPRESS_CMP_DISPLAY: any;
declare function hotjarScript(): any;

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
    public routeUrl!: string;
    private navBottomMenu: any;
    public showNavBottom: boolean = false;
    public loading: boolean = false;
    public homePageAssets: any = {};
    public footerPageAssets: any;
    public isUserLogged: boolean = false;
    public isClient: boolean = false;
    public isMobile: boolean = false;
    public pageLoadCount = 0;

    public isMobileEmitter: any = null;
    public pageDataEmitter: any = null;
    public loginEmitter: any = null;
    public routerSubscribe: any = null;
    private appLoadEmitter: any = null;
    public isBrowser: any;
    public isServer: any;

    constructor(
        public route: ActivatedRoute,
        public router: Router,
        private _apiService: ApiService,
        private _dataService: DataService,
        private _userService: UserService,
        private _configService: ConfigService,
        public _generalService: GeneralService,
        public _routingService: RoutingService,
        public _localStorageService: LocalStorageService,
        public _languageService: LanguageService,
        private _googleAnalitycsService: GoogleAnalitycsService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) {
        this.isBrowser = isPlatformBrowser(this.platformId);
        this.isServer = isPlatformServer(this.platformId);

        this._generalService.getBrowserTabId();
        // this._generalService.addCustomCssViaAws();
        this.iFrameCommunication();
        this.routerEventSubscribe();
        this._apiService.request('', '', '', '', '', false);
        this.isClient = this._configService.getClient() as any;
        this.isMobile = this._dataService.isMobile();
        if (isPlatformBrowser(this.platformId)) {
            const eventObj = { originalLocation: document.location.protocol + '//' + document.location.hostname + document.location.pathname + document.location.search };
            this._googleAnalitycsService.trackingUserAction('', '', eventObj);

            const env = this._localStorageService.get('clientEnv');
            if (env === 'production') {
                if (window) {
                    window.console.log = function () { };
                }
            }
        }
    }

    ngOnInit() {
        this.loadInitData();
        if (this.isBrowser) {
            console.log('app loaded on browser')
        } else if (this.isServer) {
            console.log('app loaded on server')
        } else {
            console.log('app loaded on normal')
        }

    }

    loadInitData() {
        this.navBottomEmitterEvent();
        this.appLoadEmitterEvent();
        this.loginEmitterCalled();
        this.checkUserLogin();
        this.loadWindowResize();
        this.isMobileEmitter = this._dataService.isMobileEmitter.subscribe((res) => {
            this.isMobile = res;
        });

        // if (isPlatformBrowser(this.platformId)) {
        //     let timeInterval = setInterval((event: any) => {
        //         this._localStorageService.set('lastUpdateDate', new Date());
        //     }, 1500);
        // }
    }

    ngAfterViewInit() {
        this._generalService.cookieBotScriptLink();
        this._generalService.setPWAMainfestLink();
        this._generalService.setCommonJsLink();
        // this.marketingCookieBot();
    }

    marketingCookieBot() {
        if (isPlatformBrowser(this.platformId)) {
            window.addEventListener('CookiebotOnAccept', (event: any) => {
                try {
                    // console.log('Cookiebot====', Cookiebot)
                    if (Cookiebot.consent.marketing) {
                        //Execute code that sets marketing cookies
                        this._generalService.marketingCookieJsLink();
                        hotjarScript();
                    }
                } catch (e) {
                    console.log('error in CookiebotOnAccept')
                }
            }, false);
            window.addEventListener('CookiebotOnDecline', (event: any) => {
                console.log('CookiebotOnDecline====', Cookiebot)
                let head = document.getElementsByTagName('head')[0];
                let hotjarLink = document.getElementById('hotjar-link');
                if (hotjarLink != null) {
                    head.removeChild(hotjarLink);
                }
            }, false);
        }
    }

    loadWindowResize() {
        let me = this;
        try {
            if (isPlatformBrowser(this.platformId)) {
                jQuery(window).resize(function () {
                    setTimeout(() => {
                        me._dataService.isMobile(true);
                    }, 500);
                });
            }
        } catch (e) {
        }
    }

    appLoadEmitterEvent() {
        this.appLoadEmitter = this._dataService.appLoadEmitter.subscribe((data) => {
            if (data['appLoad'] === true) {
                console.log('appLoad>>>>');
                let headerAssets = this._dataService.getAssets('headerPage');
                let isChangeLangSucess = this._languageService.getChangeLangSuceessOnPageReload();
                if (isChangeLangSucess) {
                    this._userService.getCheckSiteAccessibility(headerAssets);
                    this._languageService.setChangeLangSuceessOnPageReload(false);
                }

                this.loading = true;
                let skinName = this._dataService.getSkinName();
                this._localStorageService.set('skinName', skinName);
                this.homePageAssets = this._dataService.getAssets('homePage');
                this.footerPageAssets = this._dataService.getAssets('footerPage');
                if (this.pageLoadCount <= 0) {
                    this._userService.checkForceLogout24Hrs();
                }
                this.pageLoadCount++;
            }
        });
    }

    navBottomEmitterEvent() {
        this.navBottomMenu = this._dataService.navBottomMenuEmitter.subscribe((data) => {
            this.showNavBottom = data;
        });
    }

    loginEmitterCalled() {
        this.loginEmitter = this._configService.loginEmitter.subscribe((data) => {
            if (data.type === 'login') {
                this._googleAnalitycsService.trackingUserAction('event', 'loginSuccessful');
            } else {
                this._googleAnalitycsService.trackingUserAction('event', 'logoutSuccessful');
            }
            this.checkUserLogin();
        });
    }

    iFrameCommunication() {
        if (isPlatformBrowser(this.platformId)) {
            let eventMethod = window.addEventListener ? 'addEventListener' : 'attachEvent';
            let eventer = window[eventMethod];
            let messageEvent = eventMethod === 'attachEvent' ? 'onmessage' : 'message';
            // Listen to message from child window

            let me = this;
            eventer(messageEvent, (e: any) => {
                if (e.data === 'openDeposit') {
                    this._routingService.altRouterLink('user/deposit')
                }

                let gameEvent = e.data || {};
                if (gameEvent.name === 'gameQuit' || gameEvent.name === 'notifyCloseContainer') {
                    this._routingService.altRouterLink('home');
                }
            }, false);
        }
    }

    routerEventSubscribe() {
        this.routerSubscribe = this.router.events.subscribe(event => {
            if (event instanceof NavigationEnd) {
                this._dataService.setPreviousRoute(this.routeUrl);
                this.routeUrl = event.url;
                const currentState = event['url'];
                this._googleAnalitycsService.trackingUserAction();
                if (isPlatformBrowser(this.platformId)) {
                    window.scrollTo(0, 0);

                    if (currentState.includes(this._routingService.altRouterLinkActive('privacy-notice')) || currentState.includes(this._routingService.altRouterLinkActive('imprint'))) {
                        UC_UI_SUPPRESS_CMP_DISPLAY = true;
                    } else {
                        UC_UI_SUPPRESS_CMP_DISPLAY = false;
                    }
                }

                let isSiteBlocked = this._userService.getIsSiteBlocked();
                if (isSiteBlocked) {
                    this._generalService.infomodel(this.homePageAssets.text.homePage_info_modal_site_restriction_title, this.homePageAssets.text.homePage_info_modal_site_restriction_msg_text, this.homePageAssets.text.homePage_ok_btn_label);
                }
            }
        });
    }

    checkUserLogin() {
        if (this._userService.checkLoginUser() === true) {
            this._dataService.setLoginState(true);
            this.isUserLogged = true;
        } else {
            this._dataService.setLoginState(false);
            this._userService.clearLocalStorage();
            this.isUserLogged = false;
        }
    }

    ngOnDestroy() {
        this.appLoadEmitter ?.unsubscribe();
        this.routerSubscribe ?.unsubscribe();
        this.navBottomMenu ?.unsubscribe();
    }
}
