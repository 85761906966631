import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

// Services
import { ApiService } from './api.service';

@Injectable()

export class DocumentService {
    constructor(
        private _apiService: ApiService
    ) { }

    // GET Doc Ass Detail Per Country API
    getDocAssDetailsPerCountry(countryCode: any): Observable<any> | any {
        let apiUrl = 'register/getDocAssDetailsPerCountry?countryCode=' + countryCode;
        return this._apiService.request('get', apiUrl, '', '', false);
    }

    // GET Player Upoload Doc List API
    getPlayerUploadedDocumentList(data: any): Observable<any> | any {
        let apiUrl = 'playerProfile/getPlayerUploadedDocumentListV2';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    // POST Save Player Upoload Doc API
    saveUploadPlayerDocument(data: any): Observable<any> | any {
        let apiUrl = 'playerProfile/docs/compliance/uploadPlayerDocument';
        return this._apiService.request('post', apiUrl, data, {}, true);
    }

    getDocTypeNameAssArray(data: any[]) {
        let ret = {};
        for (let i = 0; i < data.length; i++) {
            if(!ret[data[i]['docMasterCode']]) {
                ret[data[i]['docMasterCode']] = {};
            }
            ret[data[i]['docMasterCode']] = {name: data[i]['docMasterName'], translatedDocName: data[i]['translatedDocName']};
        }
        return ret;
    }
}
