<div class="modal info-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-scrollable modal-dialog-centered" role="document">
        <div class="modal-content">
            <div class="modal-header">
                <h2 [hidden]="!registerPageAssets?.text" class="modal-title headline m-0" id="myModalLabel" [innerHTML]="registerPageAssets?.text.registerPageAssets_limit_bank_data_heading_text"></h2>
                <h2 [hidden]="registerPageAssets?.text">Fast geschafft! Legen Sie Ihr monatliches Einzahllimit fest</h2>
                <button type="button" class="close" (click)="dialogRef.close()" aria-label="Close">
                    <span aria-hidden="true">
                        <span class="icon modal-close-bt" [inlineSVG]="'assets/images/icons/close-icon.svg'" [removeSVGAttributes]="['xmlns']"></span>
                    </span>
                </button>
            </div>
            <div class="modal-body pb-4">
                <div class="col-lg-12 text-center" [hidden]="registerPageAssets?.text">
                    <p> Wird geladen...</p>
                </div>
                <div class="col-lg-12" [hidden]="!registerPageAssets?.text">
                    <form class="regForm" [formGroup]="limitForm" (ngSubmit)="onSubmitLimitForm()" id="regForm">
                        <div class="limits-main">
                            <div class="row body-inner row-custom">
                                <div class="col-lg-12 mb-3">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="isConfrimLugasLimitTrue" formControlName="isConfrimLugasLimit" [value]="true"
                                            (change)="changeLuggasLimitConfrimRadio($event)">
                                        <label class="custom-control-label" for="isConfrimLugasLimitTrue" [innerHTML]="registerPageAssets?.text.registerPageAssets_lugas_confrim_checkbox_text"></label>
                                    </div>
                                </div>
                                <div class="col-lg-12 mb-3">
                                    <div class="custom-control custom-radio custom-control-inline">
                                        <input type="radio" class="custom-control-input" id="isConfrimLugasLimitFalse" formControlName="isConfrimLugasLimit" [value]="false"
                                            (change)="changeLuggasLimitConfrimRadio($event)">
                                        <label class="custom-control-label" for="isConfrimLugasLimitFalse" [innerHTML]="registerPageAssets?.text.registerPageAssets_lugas_reEnter_limit_checkbox_text"></label>
                                    </div>
                                </div>
                                <div class="col-lg-12" *ngIf="!f.isConfrimLugasLimit.value">
                                    <div class="euro-icon-main">
                                        <div class="form-group custom" [ngClass]="{'input-error-border': (f.limit.errors && (f.limit.dirty || f.limit.touched))}">
                                            <label class="form-fake-label" [innerHTML]="registerPageAssets?.text.registerPageAssets_set_limit_label"></label>
                                            <input type="text" [placeholder]="registerPageAssets?.text.registerPageAssets_lugas_limit_placeholder | safeHTML" value="1.000,00"
                                                class="form-control placeholder hide-on-focus euro-input" id="limit" formControlName="limit"
                                                [ngClass]="{'is-valid': !f.limit.errors && f.limit.valid, 'is-invalid': f.limit.errors && (f.limit.dirty || f.limit.touched)}">
                                            <span class="icon-euro" [inlineSVG]="headerPageAssets.digital.headerPage_common_euroSign" [removeSVGAttributes]="['xmlns']"></span>
                                            <div *ngIf="f.limit.touched && f.limit.errors" class="text-danger">
                                            </div>
                                        </div>
                                        <div *ngIf="(submitted || f.limit.dirty || f.limit.touched) && f.limit.errors" class="text-danger">
                                            <div *ngIf="f.limit.errors.required" [innerHTML]="registerPageAssets?.errormsg.registerPageAssets_error_limit_required"></div>
                                            <div *ngIf="f.limit.errors.e9479 || f.limit.errors.pattern || f.limit.errors.max || f.limit.errors.min" [innerHTML]="registerPageAssets.errormsg.registerPageAssets_error_limit_min_max_range">
                                            </div>
                                        </div>
                                    </div>
                                    <p [innerHTML]="registerPageAssets?.text.registerPageAssets_max_range_1000_info_text"></p>
                                </div>

                                <div class="col-md-12">
                                    <div class="flex-fill right-text mt-4 mb-4">
                                        <button type="submit" class="btn btn-secondary btn-md reg-btn" [disabled]="limitForm.invalid || isRegApiProcess">
                                            <span [innerHTML]="registerPageAssets?.text.registerPageAssets_save_continue_btn_label"></span>
                                            <span class="icon-right-arrow" [inlineSVG]="'assets/images/arrow-right.svg'"
                                                [removeSVGAttributes]="['xmlns']"></span>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>