import { Injectable, EventEmitter } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/catch';

import { CookieService } from 'ngx-cookie-service';
import { LocalStorageService } from './local-storage.service';

// Services
import { ConfigService } from './config.service';
import { RoutingService } from 'src/app/services/routing.service';

declare let navigator: any;
declare let window: any;

@Injectable()

// Api service will call all APIs of the apps. All the API request should call this API.
export class ApiService {
    public nativeEl: any;
    constructor(
        private http: HttpClient,
        private _configService: ConfigService,
        private _cookieService: CookieService,
        public _localStorageService: LocalStorageService,
        public router: Router,
        public _routingService: RoutingService) {
    }

    // Request method has following arguments
    // method => 'POST', 'GET', 'PUT', etc
    // url => The url of the module eg. For login it should 'api/login'. For system it should be 'api/system'.
    // params => The GET parameters in request.
    // data => The POST parameters in request.

    request(method: string, url: any, data: any, param: any, loderVal: any = '', isToken: boolean = true, sbookUrl = false) {

        if (loderVal === true) {
            this._configService.subscriberLoader(true);
        } else if (loderVal === false) {
            this._configService.subscriberLoader(false);
        }

        let browserTabId = window.tabId || '';
        let endPoint = this._configService.getApiEndPoint();

        let apiURL: string;
        apiURL = endPoint + url;

        let headerConfig = { 'Content-Type': 'application/json' };
        headerConfig['browserTab'] = `${browserTabId}`;
        const authToken = this._cookieService.get('authToken');
        if (authToken && isToken) {
            headerConfig['Authorization'] = `Bearer ${authToken}`;
        }

        const headers = new HttpHeaders(headerConfig);

        const body = JSON.stringify(data);
        const params = this.objToSearchParams(param);
        // const options = { params, withCredentials: false };
        const options = { params, withCredentials: false, headers };

        let request;
        if (method === 'post') {
            request = this.http.post(apiURL, body, options).map((res) => {
                return this.extractData(res);
            }).pipe(catchError((error: any) => {
                return throwError(this.handleError(error));
            }));
        } else if (method === 'get') {
            request = this.http.get(apiURL, options).map((res) => {
                return this.extractData(res);
            })
                .pipe(catchError((error: any) => {
                    return throwError(this.handleError(error));
                }));
        } else if (method === 'put') {
            request = this.http.put(apiURL, body, options).map((res) => {
                return this.extractData(res);
            }).pipe(catchError((error: any) => {
                return throwError(this.handleError(error));
            }));
        }
        return request;
    }

    germanAutocompleteApiRequest(method: string, url: any, data: any, param: any, loderVal: any = '', isToken: boolean = true, sbookUrl = false) {

        if (loderVal === true) {
            this._configService.subscriberLoader(true);
        } else if (loderVal === false) {
            this._configService.subscriberLoader(false);
        }

        let browserTabId = window.tabId || '';
        let endPoint = this._configService.getGermanAutocompleteApiEndPoint();

        let apiURL: string;
        apiURL = endPoint + url;

        // let headerConfig = { 'Content-Type': 'application/json' };
        // headerConfig['browserTab'] = `${browserTabId}`;
        let headerConfig = {};

        const authToken = this._cookieService.get('autoCompleteAuthToken');
        if (authToken) {
            headerConfig['Authorization'] = `Bearer ${authToken}`;
        }

        const headers = new HttpHeaders(headerConfig);
        const body = JSON.stringify(data);
        const params = this.objToSearchParams(param);
        const options = { params, withCredentials: false, headers };

        let request;
        if (method === 'post') {
            request = this.http.post(apiURL, body, options).map((res) => {
                return this.extractData(res);
            }).pipe(catchError((error: any) => {
                return throwError(this.handleError(error));
            }));
        } else if (method === 'get') {
            request = this.http.get(apiURL, options).map((res) => {
                return this.extractData(res);
            })
                .pipe(catchError((error: any) => {
                    return throwError(this.handleError(error));
                }));
        } else if (method === 'put') {
            request = this.http.put(apiURL, body, options).map((res) => {
                return this.extractData(res);
            }).pipe(catchError((error: any) => {
                return throwError(this.handleError(error));
            }));
        }
        return request;
    }

    // search params data
    private objToSearchParams(obj: any) {
        let params = new HttpParams();
        for (const key in obj) {
            if (obj.hasOwnProperty(key)) {
                const val = obj[key];
                if (val !== null && val !== undefined) {
                    params = params.append(key, val.toString());
                }
            }
        }
        return params;
    }

    preRenderIORequest(method: string, url: string, data: any, param: any, loderVal: any = '', isToken: boolean = true) {
        if (loderVal === true) {
            this._configService.subscriberLoader(true);
        } else if (loderVal === false) {
            this._configService.subscriberLoader(false);
        }

        // let browserTabId = window.tabId || '';
        const endPoint = this._configService.getPreranderApiEndPoint();
        let apiURL: string;
        apiURL = endPoint + url;

        let headerConfig = { 'Content-Type': 'application/json' };
        // headerConfig['browserTab'] = `${browserTabId}`;

        const authToken = this._cookieService.get('authToken');
        if (authToken && isToken) {
            headerConfig['Authorization'] = `Bearer ${authToken}`;
        }

        const headers = new HttpHeaders(headerConfig);
        const body = JSON.stringify(data);
        const params = this.objToSearchParams(param);
        const options = { params, withCredentials: false, headers };

        let request;
        if (method === 'post') {
            request = this.http.post(apiURL, body, options).map((res) => {
                return this.extractData(res);
            }).pipe(catchError((error: any) => {
                return throwError(this.handleError(error));
            }));

        } else if (method === 'get') {
            request = this.http.get(apiURL, options).map((res) => {
                return this.extractData(res);
            })
                .pipe(catchError((error: any) => {
                    return throwError(this.handleError(error));
                }));
        } else if (method === 'put') {
            request = this.http.put(apiURL, body, options).map((res) => {
                return this.extractData(res);
            }).pipe(catchError((error: any) => {
                return throwError(this.handleError(error));
            }));

        }
        return request;
    }

    // get response API
    private extractData(res: any) {
        if (!res) {
            return res || {};
        }
        this._configService.subscriberLoader(false);
        // invalid response
        if (res.status < 200 || res.status >= 300) {
            throw new Error('Bad response status: ' + res.status);
        }
        return res || {};
    }

    // if api fails then handel all server side errors
    private handleError(error: any) {
        // console.log(error);
        this._configService.subscriberLoader(false);
        let errMsg = error.message || 'Server error';
        if (error.status == 401) {
            if (this._localStorageService.get('player')) {
                this._configService.notifyUserLogout();
                this._routingService.altRouterLink('home');
            }
        }

        if (error.status === 404 || error.status === 401 || error.status === 400 || error.status === 403 || error.status === 409) {
            errMsg = error.error;
        }
        return errMsg;
    }

}
